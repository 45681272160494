import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Alert,
} from "@material-tailwind/react";
import {
  IdentificationIcon,
  HomeModernIcon,
  MagnifyingGlassIcon,
  DocumentTextIcon,
  HandThumbUpIcon,
  CurrencyDollarIcon,
  TableCellsIcon,
  QuestionMarkCircleIcon,
  WalletIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from 'react-router-dom';
import { ArrowDownIcon, ChevronRightIcon, PowerIcon } from "@heroicons/react/24/solid";
import { Modal, useModal } from '../Helpers/importacionesComunes'
import Ejemplo from './Ejemplo';
import Asambleas from './Asambleas';
import { CambioPassword } from './CambioPassword';
import { Evento } from '../Vistas/eventos/vistas/Evento';
import { Tramites } from '../Vistas/tramites/vistas/Tramites';
import { Cupones } from '../Vistas/cupones/vistas/Cupones';
import Axios from 'axios';
import { useContextoGlobal } from "../Context/contextoGlobal";

export function MenuSidebar({ usuario, logout, displaySidebar = false }) {
  const [open, setOpen] = React.useState(0);
  const [hoveredIcon, setHoveredIcon] = useState('');
  const [hoveredSubMenu, setHoveredSubMenu] = useState('');
  const [componenteModal, setComponenteModal] = useState(null);
  const [titulo, setTitulo] = useState('');
  const [abrirMenuVertical, setAbrirMenuVertical] = useState(false)
  const { toggle, isShowing } = useModal();
  const { ocultarMenu :ocultarMenuGlobal,activeMenu, setCurrentMenu } = useContextoGlobal();

  const handleMouseEnter = (id) => {
    setHoveredIcon(id);
  };

  const ocultarMenu = (tieneSubMenu)=>{
    if(!tieneSubMenu){
        ocultarMenuGlobal();
    }
  }

  const handleHoveredSubMenu = (id) => {
    setHoveredSubMenu(id);
  };

  const handleMouseLeave = () => {
    setHoveredIcon("");
  };

  const handleClick = (id)=>{
    if(id===activeMenu){
        setCurrentMenu("")
    }else{
        setCurrentMenu(id)
    }
  }

  const handleClickSubMenu = (event,id)=>{
    handleHoveredSubMenu(id); 
    event.stopPropagation();
    ocultarMenu()
  }

  if(usuario.rol_sede == 2) // usuarios colaboradores
    return (
      <Card className={`min-h-screen w-full max-w-[16rem] p-4 shadow-xl shadow-blue-gray-900/5 !rounded-none
        ${displaySidebar ? 'flex' : 'hidden 4xl:flex'}`}>
        <div className="flex flex-col lg:hidden mb-[-15px] p-4 !text-[#2196f3]">
          <Typography variant="h5" className="w-full">
            Bienvenido
          </Typography>
          <Typography variant="h5" className="w-full !font-bold">
            {`${usuario.nombre}`}
          </Typography>
        </div>
        <List className="overflow-y-auto">
          <hr className="my-2 border-blue-gray-50 flex lg:hidden" />
          <ListItem
            className={`block flex-col justify-start cursor-pointer ${activeMenu === 'type1' ? '!bg-blue-gray-50' : ''}`}
            //onMouseEnter={() => { handleMouseEnter("type1"); }}
            //onMouseLeave={handleMouseLeave}
            //onClick={() => { setCurrentMenu('type1'); ocultarMenu() }}
            onClick={() => { handleClick('type1'); ocultarMenu() }}
          >
            <NavLink to="/asambleas">
              <div className="flex flex-row">
                <ListItemPrefix>
                  <IdentificationIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
                </ListItemPrefix>
                <p className="font-semibold">Asambleas</p>
              </div>
              <div className={`${hoveredIcon === 'type1' || activeMenu === 'type1' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
                  Gestione asambleas
              </div>
            </NavLink>
  
          </ListItem>
          <ListItem
            className={`block flex-col justify-start cursor-pointer ${activeMenu === 'type12' && 'bg-blue-gray-50'}`}
            //onMouseEnter={() => { handleMouseEnter("type12"); }}
            //onMouseLeave={handleMouseLeave}
            //onClick={() => { setCurrentMenu('type12'); }}
            onClick={() => { handleClick('type12'); ocultarMenu() }}
          >
            <button className="p-0" title="Salir" onClick={logout}>
              <div className="flex flex-row space-x-6 p-0">
                <PowerIcon className="h-5 w-5" />
                <p className="font-semibold">Salir</p>
              </div>
            </button>
          </ListItem>
        </List>
        {isShowing &&
          <Modal
            hide={toggle} isShowing={isShowing} titulo={titulo} estiloWrapper={{ background: '#000000bf' }}>
            <SeleccionarComponenteModal componente={componenteModal}
              usuario={usuario}
            />
          </Modal>}
      </Card>
    );

  return (
    <Card className={`min-h-screen w-full max-w-[16rem] p-4 shadow-xl shadow-blue-gray-900/5 !rounded-none
      ${displaySidebar ? 'flex' : 'hidden 4xl:flex'}`}>
      <div className="flex flex-col lg:hidden mb-[-15px] p-4 !text-[#2196f3]">
        <Typography variant="h5" className="w-full">
          Bienvenido
        </Typography>
        <Typography variant="h5" className="w-full !font-bold">
          {`${usuario.nombre}`}
        </Typography>
      </div>
      <List className="overflow-y-auto">
        <hr className="my-2 border-blue-gray-50 flex lg:hidden" />
        <ListItem
          className={`block flex-col justify-start cursor-pointer ${activeMenu === 'type1' ? '!bg-blue-gray-50' : ''}`}
          //onMouseEnter={() => { handleMouseEnter("type1"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type1'); ocultarMenu() }}
          onClick={() => { handleClick('type1'); ocultarMenu() }}
        >
          <NavLink to="/ministros">
            <div className="flex flex-row">
              <ListItemPrefix>
                <IdentificationIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Ministros</p>
            </div>
            <div className={`${hoveredIcon === 'type1' || activeMenu === 'type1' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Acceda a la Lista de Ministros y actualice la información
            </div>
          </NavLink>

        </ListItem>
        <ListItem
          className={`block flex-col justify-start cursor-pointer ${activeMenu === 'type2' && 'bg-blue-gray-50'}`}
          //onMouseEnter={() => { handleMouseEnter("type2"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type2');ocultarMenu() }}
          onClick={() => { handleClick('type2'); ocultarMenu() }}
        >
          <NavLink to="/iglesias">
            <div className="flex flex-row">
              <ListItemPrefix>
                <HomeModernIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Iglesias</p>
            </div>
            <div className={`${hoveredIcon === 'type2' || activeMenu === 'type2' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Acceda a la Lista de Iglesias y actualice la información
            </div>
          </NavLink>
        </ListItem>

        <ListItem
          selected={open === 1}
          className={`block flex-col justify-start ${activeMenu === 'type3' && 'bg-blue-gray-50 border-0'}`}
          //onMouseEnter={() => { handleMouseEnter("type3"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type3');ocultarMenu() }}
          onClick={() => { handleClick('type3'); ocultarMenu('sub-menu-gestiones-regionales') }}
        >
          <div className="flex">
            <ListItemPrefix>
              <ArchiveBoxIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
            </ListItemPrefix>
            <p className="font-semibold">Gestiones <br></br> regionales</p>
          </div>
          <div className={`${hoveredIcon === 'type3' || activeMenu === 'type3' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
            <List className="p-0 max-w-[16rem] min-w-[130px]">
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub1' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub1"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub1")}}
                >
                <NavLink to="/ingresos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Ingresos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub2' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub2"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub2")}}
                >
                <NavLink to="/depositos-sin-identificar">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Depósitos sin identificar
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub3' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub3"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub3")}}
                >
                <NavLink to="/reporte-ha">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Historial de Aportes
                  </div>
                </NavLink>
              </ListItem>  
              {usuario.perfil==='region' && <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub4' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub4"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub4")}}
                >
                <NavLink to="/documentos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Documentos
                  </div>
                </NavLink>
              </ListItem>}     
            </List>
          </div>
        </ListItem>
        {usuario?.perfil==='sede' && <ListItem
          selected={open === 1}
          className={`block flex-col justify-start ${activeMenu === 'type4' && 'bg-blue-gray-50 border-0'}`}
          //onMouseEnter={() => { handleMouseEnter("type4"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type4');ocultarMenu() }}
          onClick={() => { handleClick('type4'); ocultarMenu('sub-menu-tesoreria') }}
        >
          <div className="flex">
            <ListItemPrefix>
              <ArchiveBoxIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
            </ListItemPrefix>
            <p className="font-semibold">Tesorería</p>
          </div>
          <div className={`${hoveredIcon === 'type4' || activeMenu === 'type4' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
            <List className="p-0 max-w-[16rem] min-w-[130px]">
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub5' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub5"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub5")}}
                >
                <NavLink to="/bancos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Bancos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub6' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub6"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub6")}}
                >
                <NavLink to="/cajas-rubros">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Cajas de Rubros
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub7' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub7"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub7")}}
                >
                <NavLink to="/proveedores">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Proveedores
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub8' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub8"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub8")}}
                >
                <NavLink to="/interdepositos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Interdepósitos
                  </div>
                </NavLink>
              </ListItem>

              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub9' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub9"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub9")}}
                >
                <NavLink to="/rubro-egresos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Rubro Egresos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub10' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub10"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub10")}}
                >
                <NavLink to="/rubro-ingresos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Rubro Ingresos
                  </div>
                </NavLink>
              </ListItem>

              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub11' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub11"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub11")}}
                >
                <NavLink to="/fondos-tesoreria">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Fondos de Tesorería
                  </div>
                </NavLink>
              </ListItem>

              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub12' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub12"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub12")}}
                >
                <NavLink to="/destino-ingresos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Destino de Ingresos
                  </div>
                </NavLink>
              </ListItem>
            </List>
          </div>
        </ListItem>}
        
        {usuario?.perfil==='sede' && <ListItem
          selected={open === 1}
          className={`block flex-col justify-start ${activeMenu === 'type5' && 'bg-blue-gray-50 border-0'}`}
          //onMouseEnter={() => { handleMouseEnter("type5"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type5'); }}
          onClick={() => { handleClick('type5'); ocultarMenu('sub-menu-reportes') }}
        >
          <div className="flex">
            <ListItemPrefix>
              <ArchiveBoxIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
            </ListItemPrefix>
            <p className="font-semibold">Reportes</p>
          </div>
          <div className={`${hoveredIcon === 'type5' || activeMenu === 'type5' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
            <List className="p-0 max-w-[16rem] min-w-[130px]">
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub13' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub13"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub13")}}
                >
                <NavLink to="/reportes-ei">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Egresos Integrales
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub14' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub14"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub14")}}
                >
                <NavLink to="/reportes-ii">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Ingresos Integrales
                  </div>
                </NavLink>
              </ListItem>
            </List>
          </div>
        </ListItem>}

        {usuario?.perfil==='sede' && <ListItem
          selected={open === 1}
          className={`block flex-col justify-start ${activeMenu === 'type8' && 'bg-blue-gray-50 border-0'}`}
          //onMouseEnter={() => { handleMouseEnter("type8"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type8'); }}
          onClick={() => { handleClick('type8'); ocultarMenu('sub-menu-secretaria') }}
        >
          <div className="flex">
            <ListItemPrefix>
              <WalletIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
            </ListItemPrefix>
            <p className="font-semibold">Secretaría</p>
          </div>
          <div className={`${hoveredIcon === 'type8' || activeMenu === 'type8' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
            <List className="p-0 max-w-[16rem] min-w-[130px]">
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub16' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub16"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub16")}}
                >
                <NavLink to="/alertas">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Alertas
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`hidden flex-col justify-start ${hoveredSubMenu === 'sub17' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub17"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub17")}}
                >
                <NavLink to="/ejemplo">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Archivo
                  </div>
                </NavLink>
              </ListItem>
              {/* <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub18' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub18"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub18")}}
                >
                <NavLink to="/eventos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Listado y gestión
                  </div>
                </NavLink>
              </ListItem> */}
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub19' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub19"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub19")}}
                >
                <NavLink to="/envio-emails">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Envio Emails
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub20' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub20"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub20")}}
                >
                <NavLink to="/reset-passwords">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Reiniciar contraseñas
                  </div>
                </NavLink>
              </ListItem>
            </List>
          </div>
        </ListItem>}
        {usuario?.perfil==='sede' && <ListItem
          selected={open === 1}
          className={`block flex-col justify-start ${activeMenu === 'type9' && 'bg-blue-gray-50 border-0'}`}
          //onMouseEnter={() => { handleMouseEnter("type9"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type9'); }}
          onClick={() => { handleClick('type9'); ocultarMenu('sub-menu-eventos') }}
        >
          <div className="flex">
            <ListItemPrefix>
              <WalletIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
            </ListItemPrefix>
            <p className="font-semibold">Eventos</p>
          </div>
          <div className={`${hoveredIcon === 'type9' || activeMenu === 'type9' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
            <List className="p-0 max-w-[16rem] min-w-[130px]">
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub21' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub21"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub21")}}
                >
                <NavLink to="/eventos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                      Listado y gestión
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub022' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub21"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub022")}}
                >
                <NavLink to="/pfrecuentes">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                      Preguntas frecuentes
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub22' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub22"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub22")}}
                >
                <NavLink to="/reset-passwords">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Reiniciar contraseñas
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub23' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub23"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub23")}}
                >
                <NavLink to="/asambleas">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Asambleas
                  </div>
                </NavLink>
              </ListItem>       
            </List>
          </div>
        </ListItem>}
        {usuario?.perfil==='sede' && <ListItem
          selected={open === 1}
          className={`block flex-col justify-start ${activeMenu === 'type10' && 'bg-blue-gray-50 border-0'}`}
          //onMouseEnter={() => { handleMouseEnter("type10"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type10'); }}
          onClick={() => { handleClick('type10'); ocultarMenu('sub-menu-configuracion') }}
        >
          <div className="flex">
            <ListItemPrefix>
              <ArchiveBoxIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
            </ListItemPrefix>
            <p className="font-semibold">Configuración</p>
          </div>
          <div className={`${hoveredIcon === 'type10' || activeMenu === 'type10' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
            <List className="p-0 max-w-[16rem] min-w-[130px]">
            <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub24' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub24"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub24")}}
                >
                <NavLink to="/documentos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Documentos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub25' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub25"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub25")}}
                >
                <NavLink to="/nacionalidades">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Nacionalidades
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub26' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub26"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub26")}}
                >
                <NavLink to="/partidos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Partidos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub27' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub27"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub27")}}
                >
                <NavLink to="/provincias">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Provincias
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub28' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub28"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub28")}}
                >
                <NavLink to="/rangos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Rangos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub29' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub29")}}
                >
                <NavLink to="/regiones">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Regiones
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub299' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub299")}}
                >
                <NavLink to="/distritos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Distritos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub300' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub300")}}
                >
                <NavLink to="/estados-civiles">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Estados Civiles
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub301' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub301")}}
                >
                <NavLink to="/tipo-iglesias">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Tipo Iglesias
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub302' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub302")}}
                >
                <NavLink to="/tipo-documentos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Tipo Documentos
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub303' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub303")}}
                >
                <NavLink to="/tipo-cuentas-banco">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Tipo de Cuentas de Banco
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub304' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub304")}}
                >
                <NavLink to="/cajas-uad">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Cajas UAD
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub305' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub305")}}
                >
                <NavLink to="/cuentas-bancarias-uad">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Cuentas Bancarias UAD
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub306' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub29"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub306")}}
                >
                <NavLink to="/parametros-sistema">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Parámetros del Sistema
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub30' && 'bg-blue-gray-100'}`}
                ///onClick={() => { handleHoveredSubMenu("sub30"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub30")}}
                >
                <NavLink to="/tandas">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Tandas
                  </div>
                </NavLink>
              </ListItem>
              <ListItem className={`block flex-col justify-start ${hoveredSubMenu === 'sub31' && 'bg-blue-gray-100'}`}
                //onClick={() => { handleHoveredSubMenu("sub31"); ocultarMenu() }}
                onClick={(event) => { handleClickSubMenu(event,"sub31")}}
                >
                <NavLink to="/tipo-comprobante-egresos">
                  <div className="flex flex-row">
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Tipo de Comprobante de Egresos
                  </div>
                </NavLink>
              </ListItem>
            </List>
          </div>
        </ListItem>}

        <ListItem
          className={`block flex-col justify-start cursor-pointer ${activeMenu === 'type11' && 'bg-blue-gray-50'}`}
          //onMouseEnter={() => { handleMouseEnter("type11"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type11'); ocultarMenu()}}
          onClick={() => { handleClick('type11'); ocultarMenu() }}
        >
          <NavLink to="/tramites">
            <div className="flex flex-row">
              <ListItemPrefix>
                <HandThumbUpIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Trámites</p>
            </div>
            <div className={`${hoveredIcon === 'type11' || activeMenu === 'type11' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Realice gestiones administrativas dentro de la asociación
            </div>
          </NavLink>
        </ListItem>

        {usuario.id_usuario == 28 && <li title="Logs" className="Nav__link-push">
          <div className="text-center">
            <NavLink title='Estadísticas' activeClassName="op-active" className="Nav__link" to="/logs">
              <p className="text-large color-63 text-center">Logs</p>
            </NavLink>
          </div>
        </li>}
        <ListItem
          className={`block flex-col justify-start cursor-pointer ${activeMenu === 'type12' && 'bg-blue-gray-50'}`}
          //onMouseEnter={() => { handleMouseEnter("type12"); }}
          //onMouseLeave={handleMouseLeave}
          //onClick={() => { setCurrentMenu('type12'); }}
          onClick={() => { handleClick('type12'); ocultarMenu() }}
        >
          <button className="p-0" title="Salir" onClick={logout}>
            <div className="flex flex-row space-x-6 p-0">
              <PowerIcon className="h-5 w-5" />
              <p className="font-semibold">Salir</p>
            </div>
          </button>
        </ListItem>
      </List>
      {isShowing &&
        <Modal
          hide={toggle} isShowing={isShowing} titulo={titulo} estiloWrapper={{ background: '#000000bf' }}>
          <SeleccionarComponenteModal componente={componenteModal}
            usuario={usuario}
          />
        </Modal>}
    </Card>
  );
}

function SeleccionarComponenteModal({ componente, usuario }) {

  switch (componente) {
    case 'asambleas': return <Asambleas />
      break;
    case 'eventos': return <Evento />
      break;
    case 'ejemplo': return <Ejemplo />
      break;
    case 'cupones': return <Cupones />
      break;
    case 'refrescar': return <Ejemplo />
      break;
    case 'tramites': return <Tramites />
      break;
    case 'userpass': return <CambioPassword usuario={usuario} />
      break;
    default: return null
  }
}
