import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {_imagenLogo} from '../../imagenesBase64/logo'
import Swal from 'sweetalert2';
import {transformarIso8601,fechaActual} from '../../Helpers/fechas';
import {formatearMontoMoneda} from '../../Helpers/utilidades-globales'

const bkmonto = '';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const allMonths = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

export function imprimir(datos, nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion,parametros){
    try{

        Swal.fire({
            html: 'Buscando...',
            timer: 600,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        }).then(data=>{
            generarDocumento(datos, nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion ? orientacion : 'portrait',parametros)
        })
    }catch(err){

            const mensaje_html = `<p>Hubo un error al generar el documento</p><p>${err}</p>`

            Swal.fire({
                html:mensaje_html,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })
        }
}

function generarDocumento(datos, nombrePDF, tituloPDF, callbackMap, arrayCampos, orientacion = 'portrait', parametros) {
    const allMonths = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  
    // Agrupar por fp 
    const agrupado = {}
  
    datos.forEach(item => {
      const fp = item.fp || 'Sin fp'
  
      if (!agrupado[fp]) agrupado[fp] = []
  
      agrupado[fp].push({
        ...item,
        monto_fp: parseFloat(item.monto_fp || item.monto || 0)
      })
    })
  
    const cuerpo_del_reporte = []
    let totalGeneral = 0
  
    for (const fp in agrupado) {
 
      let totalFP = 0
  
      const registros = agrupado[fp]
  
      registros.forEach(item => {
        totalFP += item.monto_fp
        totalGeneral += item.monto_fp
      })
  
      // Total por fp
      cuerpo_del_reporte.push({
        columns: [
          { width: '*', text: `${fp.toUpperCase()}`, bold: true, fontSize: 11 },
          {
            width: 120,
            table: {
              body: [[{ text: formatearMontoMoneda(totalFP), alignment: 'right', bold: true }]]
            },
            layout: 'noBorders'
          }
        ],
        margin: [0, 0, 0, 0]
      })
    }
  
    // Total general
    cuerpo_del_reporte.push({
      columns: [
        { width: '*', text: '', bold: true, fontSize: 12 },
        {
          width: 120,
          table: {
            body: [[{
              canvas: [{
                type: 'line',
                x1: 0, y1: 5, x2: 80, y2: 5,
                lineWidth: 0.5,
                dash: { length: 1, space: 2 },
                lineColor: 'black'
              }]
            }],[
                { text: formatearMontoMoneda(totalGeneral), alignment: 'right', bold: true }]]
          },
          layout: 'noBorders'
        }
      ],
      margin: [0, 0, 0, 0]
    })
  
    const docDefinition = {
      pageOrientation: orientacion,
      pageMargins: [25, 110, 25, 50],
      header: function(currentPage, pageCount, pageSize) {
        if (currentPage > 1) {
          return [
            { text: tituloPDF, alignment: 'left', margin: [20, 20, 0, 50] },
            {
              width: pageSize.width,
              canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1, lineColor: 'black' }],
            }
          ]
        } else {
          return [
            {
              margin: [10, 30],
              columns: [
                {
                  image: _imagenLogo,
                  width: 50,
                  alignment: 'left',
                },
                { text: 'Unión de las Asambleas de Dios - Ingresos integrales', margin: [20, 0] }
              ]
            },
            { text: tituloPDF, alignment: 'left', margin: [10, 0, 0, 0] },
            {
              width: pageSize.width,
              canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1, lineColor: 'black' }],
            }
          ]
        }
      },
      footer: function(currentPage, pageCount) {
        return {
          margin: [0, 30, 30, 0],
          table: {
            widths: ['*', '*'],
            body: [
              [{ text: fechaActual('DD/MM/YYYY'), alignment: 'right', fontSize: 8 }, { text: 'Página ' + currentPage + ' de ' + pageCount, alignment: 'right', fontSize: 8 }]
            ]
          },
          layout: 'noBorders'
        }
      },
      content: cuerpo_del_reporte,
      styles: {
        estilo_tabla: {
          fontSize: 8,
          characterSpacing: -0.3,
          margin: [0, 2, 0, 0]
        }
      }
    }
  
    if (nombrePDF !== "") {
      pdfMake.createPdf(docDefinition).download(nombrePDF);
    } else {
      pdfMake.createPdf(docDefinition).open();
    }
  }
  
