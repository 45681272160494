import React, {useState, useEffect} from 'react';
import {Main,Axios,Swal,} from '../../../Helpers/importacionesComunes'
import {fechaHoy,transformarFechaTramiteSimple,esMayor} from '../../../Helpers/fechas';
import { Button, Card, Checkbox, Input, Option, Select, Spinner, Typography } from '@material-tailwind/react';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Textarea } from '@material-tailwind/react';
import {Cerrar} from './Cerrar';
import { v4 as uuidv4 } from 'uuid';

export default function EventoForm({id_evento,finalizarAltaOcopia}){

    const [huboError,setHuboError]=useState(false)
    const [grabandoDatos,setGrabandoDatos]=useState(false)
    const [cargando,setCargando]=useState(false)
    const {esCelular} = useContextoGlobal();
    const [preguntas,setPreguntas] = useState([]);
    const [evento,setEvento] = useState({
             titulo : '',
			 detalles: '',
			 flyer_web: '',
             foto_mini: '',
			 fecha: '',
			 activo: false,
			 descripcion: '',
			 subtitulo: '',
		  	 texto1: '',
			 texto2: '',
			 texto3: '',
			 texto4: '',
             id_preguntas_frecuentes:null,
//			 habilitar_inscripciones:false,
    });
    const [newKey,setNewKey]=useState(0); // Hay algún problema con material tailwind y requiere un re renderizado cada vez que cambia el valor del select

    useEffect(()=>{

        const buscarEvento = async ()=>{
            setCargando(true)
            setHuboError(false)
            try{
                const {data} = await Axios.get(`api/tablasgenerales/evento/${id_evento}`) 
                setEvento({...data,id_preguntas_frecuentes:data.id_preguntas_frecuentes || 0,fecha:transformarFechaTramiteSimple(data.fecha,'DD/MM/YYYY','YYYY-MM-DD')})
                setCargando(false)
            }catch(err){
               setHuboError(true)
               console.log('buscarEvento',err)
               setCargando(false)
            }            
        }

        const buscarPreguntas = async ()=>{
            setCargando(true)
            setHuboError(false)
            try{
                const {data} = await Axios.get(`api/tablasgenerales/eventos/preguntasfrecuenteslist`) 
                setPreguntas([{id_pregunta_frecuente:0,nombre:'Sin preguntas frecuentes'},...data])
                setCargando(false)
            }catch(err){
               setHuboError(true)
                console.log('buscarPreguntas',err)
                setCargando(false)
            }            
        }

        buscarPreguntas().then(()=>{
            if(id_evento) buscarEvento()
        })

        },[id_evento])


    const iniciarGrabar = (values)=>{
        let texto;
        let textoConfirmacion;
    
        if(!evento?.titulo){
            alert('Falta ingresar el título del evento')
            return
        }

        if(!evento?.detalles){
            alert('Falta ingresar una descripción en el campo detalles (Por ej. Mar del Plata | 10 de mayo')
            return
        }

        if(!evento?.fecha){
            alert('Falta ingresar la fecha del evento')
            return
        }

        if(esMayor(fechaHoy('YYYY-MM-DD'),evento.fecha)){
            alert('La fecha del evento debe ser igual o posterior al día de la fecha')
            return
        }

        if (id_evento){
            texto = `¿Confirma la modificación del evento ${evento.titulo}(${id_evento})?`
            textoConfirmacion = 'Si, modificar el evento'
        }else{
            texto = `¿Confirma la creación del nuevo evento?`
            textoConfirmacion = 'Si, crear el evento'
        }
    
        Swal.fire({
            text:texto,
            showCancelButton:true,
            confirButtonText:textoConfirmacion,
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    grabarDatos(values)
                    .catch(err=>{
                        alert(err)
                    })
                }else{
                    console.log("Se canceló la modificación o creación de la asamblea")
                }
            }
        )
    }

    const grabarDatos = async (values)=>{
        let resultado;

        const objetoAgrabar = {...evento,id_preguntas_frecuentes:Number(evento.id_preguntas_frecuentes) ? Number(evento.id_preguntas_frecuentes) : null,fecha: transformarFechaTramiteSimple(evento.fecha,'YYYY-MM-DD','DD/MM/YYYY'),};
    
        setGrabandoDatos(true)
    
        try{
            if (id_evento){
                resultado= await Axios.put(`/api/tablasgenerales/evento/${id_evento}`,objetoAgrabar)
                finalizarAltaOcopia(id_evento)
            }else{
                resultado= await Axios.post(`/api/tablasgenerales/evento`,objetoAgrabar)
                finalizarAltaOcopia(resultado.data.id_evento)
            }
        }catch(err){    
    
            let mensaje_html_error;
    
            console.log('err.response.status',err.response.status)
    
            if(err.response.data.message){
                mensaje_html_error = `<p>Se produjo un error al grabar los datos de la asamblea</p><p>${err.response.data.message}</p>`
            }else{
                mensaje_html_error = `<p>Se produjo un error al grabar los datos de la asamblea </p><p>${err.response.data.message}</p>`
            }
    
            Swal.fire({
                html:mensaje_html_error,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })   
        
            setGrabandoDatos(false)
        }
    }

    if (huboError){
        return <Main center>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <span>Se produjo un error al cargar los datos para esta vista</span>
                    </div>
            </Main>
    }

    if(cargando){
        return <Main> 
                    <div className={`flex flex-col justify-center items-center h-[calc(100vh-10rem)] ${esCelular ? 'ml-40' : ''}`}>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando...</span>
                    </div>
                </Main>
     }

     return <>
     {/* <Cerrar cerrar={()=>finalizarAltaOcopia(null)}/> */}

     {/* <Typography
     variant="h4"
     color="blue-gray"
     className="mb-1 font-medium "
     >
         {id_evento ? `Modifique un evento (Id:${id_evento})` : `Cree un nuevo evento`}
     </Typography> */}
     <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Título:<span className='c-obl'></span>
         </Typography>
         <Input
             onChange={(e)=>{
                 setEvento({...evento,titulo:e.target.value})
             }}
             value={evento.titulo || ''} 
             type='text' 
             color='black'
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
             autoFocus={id_evento ? false : true}
             labelProps={{
                 className: "before:content-none after:content-none",
             }}
         />
     </div>
     <div className="flex flex-col w-full mt-4">
                 <Typography
                     variant="small"
                     color="blue-gray"
                     className="mb-1 font-medium "
                 >
                     Fecha:<span className='c-obl'></span>
                 </Typography>
                 <Input
                     value={evento.fecha || ''}
                     onChange={(e)=>setEvento({...evento,fecha:e.target.value})}
                     type='date' 
                     color='black'
                     className="!h-10 !border-t-blue-gray-200 focus:!border-t-gray-900"
                     labelProps={{
                         className: "before:content-none after:content-none",
                     }}
                 />
     </div> 
     <div className='flex'>   
     <Checkbox
         label="Activo"
         ripple={false}
         title='Controla si aparece en la plataforma de eventos'
         checked={evento.activo == 1} 
         onClick={(e)=>setEvento({...evento,activo:evento.activo==1 ? 0 : 1})}
         className='w-5 h-5'
     />
     </div>
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Detalles (Por ejemplo: Fecha | Lugar):<span className='c-obl'></span>
         </Typography>
         <Input
             onChange={(e)=>{
                 setEvento({...evento,detalles:e.target.value})
             }}
             value={evento.detalles || ''} 
             type='text' 
             color='black'
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
             labelProps={{
                 className: "before:content-none after:content-none",
             }}
         />
     </div>
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Subtítulo:
         </Typography>
         <Input
             onChange={(e)=>{
                 setEvento({...evento,subtitulo:e.target.value})
             }}
             value={evento.subtitulo || ''} 
             type='text' 
             color='black'
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
             labelProps={{
                 className: "before:content-none after:content-none",
             }}
         />
     </div>
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Flyer (940x470):
         </Typography>
         <Input
             onChange={(e)=>{
                 setEvento({...evento,flyer_web:e.target.value})
             }}
             value={evento.flyer_web || ''} 
             type='text' 
             color='black'
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
             labelProps={{
                 className: "before:content-none after:content-none",
             }}
         />
     </div>
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Foto miniatura (350x175):
         </Typography>
         <Input
             onChange={(e)=>{
                 setEvento({...evento,foto_mini:e.target.value})
             }}
             value={evento.foto_mini || ''} 
             type='text' 
             color='black'
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
             labelProps={{
                 className: "before:content-none after:content-none",
             }}
         />
     </div>
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Descripción:
         </Typography>
         <Input
             onChange={(e)=>{
                 setEvento({...evento,descripcion:e.target.value})
             }}
             value={evento.descripcion || ''} 
             type='text' 
             color='black'
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
             labelProps={{
                 className: "before:content-none after:content-none",
             }}
         />
     </div>       
     <div className="flex flex-col w-full mt-4 select-ui-data">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Tipo de preguntas frecuentes: 
         </Typography>
         <Select 
             label="Seleccione un tipo" 
             value={evento.id_preguntas_frecuentes}	
             key={newKey} // Hay algún problema con material tailwind y requiere un re renderizado cada vez que cambia el valor del select
             onChange={(e)=>{
                 setEvento({...evento,id_preguntas_frecuentes:e})
                 setNewKey(uuidv4())
             }}
             className=""
         >
             {preguntas.map((item, index) => (
                 <Option key={uuidv4()} value={item.id_pregunta_frecuente}>
                         {item.nombre}
                 </Option>
             ))}
         </Select>
         {/* <select onChange={(e)=>setEvento({...evento,id_preguntas_frecuentes:e.target.value})} value={evento.id_preguntas_frecuentes}>
         {preguntas.map((item, index) => (
                 <option key={item.id_pregunta_frecuente} value={item.id_pregunta_frecuente}>
                         {item.nombre}
                 </option>
             ))}
         </select> */}
     </div> 
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Texto1:
         </Typography>
         <Textarea 
             value={evento.texto1 || ''} 
             onChange={(e)=>{
                 setEvento({...evento,texto1:e.target.value})
             }} 
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
         />
     </div>  
     
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Texto2:
         </Typography>
                 <Textarea 
                         value={evento.texto2 || ''} 
                         onChange={(e)=>{
                             setEvento({...evento,texto2:e.target.value})
                         }} 
                         className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                     />
     </div>  
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Texto3:
         </Typography>
         <Textarea 
             value={evento.texto3 || ''} 
             onChange={(e)=>{
                 setEvento({...evento,texto3:e.target.value})
             }} 
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
         />                    
     </div>  
     <div className="flex flex-col w-full mt-4">
         <Typography
             variant="small"
             color="blue-gray"
             className="mb-1 font-medium "
         >
             Texto4:
         </Typography>
         <Textarea 
             value={evento.texto4 || ''} 
             onChange={(e)=>{
                 setEvento({...evento,texto4:e.target.value})
             }} 
             className="!border-t-blue-gray-200 focus:!border-t-gray-900"
         />                    
     </div> 
     <div className='items-center flex justify-center space-x-2 mt-4'>
         <Button onClick={iniciarGrabar} type="submit">
             Grabar
         </Button>
         <Button color='red' onClick={()=>finalizarAltaOcopia(null)}>
             cancelar
         </Button>
     </div>
</>

    return <Card className='p-2 lg:p-2 xl:p-2 flex flex-col !justify-center items-center'>
                <Cerrar cerrar={()=>finalizarAltaOcopia(null)}/>

                <Typography
                variant="h4"
                color="blue-gray"
                className="mb-1 font-medium "
                >
                    {id_evento ? `Modifique un evento (Id:${id_evento})` : `Cree un nuevo evento`}
                </Typography>
                <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Título:<span className='c-obl'></span>
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEvento({...evento,titulo:e.target.value})
                        }}
                        value={evento.titulo || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        autoFocus={id_evento ? false : true}
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Fecha:<span className='c-obl'></span>
                            </Typography>
                            <Input
                                value={evento.fecha || ''}
                                onChange={(e)=>setEvento({...evento,fecha:e.target.value})}
                                type='date' 
                                color='black'
                                className="!h-10 !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                </div> 
                <div className='flex'>   
                <Checkbox
                    label="Activo"
                    ripple={false}
                    title='Controla si aparece en la plataforma de eventos'
                    checked={evento.activo == 1} 
                    onClick={(e)=>setEvento({...evento,activo:evento.activo==1 ? 0 : 1})}
                    className='w-5 h-5'
                />
                {/* <Checkbox
                    label="Habilitar inscripciones"
                    ripple={false}
                    title='Controla si se pueden inscribir o no al evento independientemente si se encuentra activo'
                    checked={evento.habilitar_inscripciones == 1} 
                    onClick={(e)=>setEvento({...evento,habilitar_inscripciones:evento.habilitar_inscripciones==1 ? 0 : 1})}
                    className='w-5 h-5'
                /> */}
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Detalles:<span className='c-obl'></span>
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEvento({...evento,detalles:e.target.value})
                        }}
                        value={evento.detalles || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Flyer (940x470):
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEvento({...evento,flyer_web:e.target.value})
                        }}
                        value={evento.flyer_web || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Foto miniatura (350x175):
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEvento({...evento,foto_mini:e.target.value})
                        }}
                        value={evento.foto_mini || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Descripción:
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEvento({...evento,descripcion:e.target.value})
                        }}
                        value={evento.descripcion || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>       
                <div className="flex flex-col w-full mt-4 select-ui-data">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Tipo de preguntas frecuentes: 
                    </Typography>
                    <Select 
                        label="Seleccione un tipo" 
                        value={evento.id_preguntas_frecuentes}	
                        key={newKey} // Hay algún problema con material tailwind y requiere un re renderizado cada vez que cambia el valor del select
                        onChange={(e)=>{
                            setEvento({...evento,id_preguntas_frecuentes:e})
                            setNewKey(uuidv4())
                        }}
                        className=""
                    >
                        {preguntas.map((item, index) => (
                            <Option key={uuidv4()} value={item.id_pregunta_frecuente}>
                                    {item.nombre}
                            </Option>
                        ))}
                    </Select>
                    {/* <select onChange={(e)=>setEvento({...evento,id_preguntas_frecuentes:e.target.value})} value={evento.id_preguntas_frecuentes}>
                    {preguntas.map((item, index) => (
                            <option key={item.id_pregunta_frecuente} value={item.id_pregunta_frecuente}>
                                    {item.nombre}
                            </option>
                        ))}
                    </select> */}
                </div> 
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto1:
                    </Typography>
                    <Textarea 
                        value={evento.texto1 || ''} 
                        onChange={(e)=>{
                            setEvento({...evento,texto1:e.target.value})
                        }} 
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    />
                </div>  
                
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto2:
                    </Typography>
                            <Textarea 
                                    value={evento.texto2 || ''} 
                                    onChange={(e)=>{
                                        setEvento({...evento,texto2:e.target.value})
                                    }} 
                                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                />
                </div>  
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto3:
                    </Typography>
                    <Textarea 
                        value={evento.texto3 || ''} 
                        onChange={(e)=>{
                            setEvento({...evento,texto3:e.target.value})
                        }} 
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    />                    
                </div>  
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto4:
                    </Typography>
                    <Textarea 
                        value={evento.texto4 || ''} 
                        onChange={(e)=>{
                            setEvento({...evento,texto4:e.target.value})
                        }} 
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    />                    
                </div> 
                <div className='items-center flex justify-center space-x-2 mt-4'>
                    <Button onClick={iniciarGrabar} type="submit">
                        Grabar
                    </Button>
                    <Button color='red' onClick={()=>finalizarAltaOcopia(null)}>
                        cancelar
                    </Button>
                </div>
    </Card>

}
