import React, { useState, useEffect } from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import { Grid } from "@material-ui/core";
import { Spinner, Card, Typography, Input, IconButton, Tooltip, Dialog, DialogHeader, DialogBody, DialogFooter, Button, Select, Option, Tabs, TabsHeader, TabsBody, Tab, TabPanel, Textarea } from '@material-tailwind/react';
import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import ExportToExcelGeneric from '../componentes/exportExcelGeneric';
import { PiePagina, Paginacion, usePaginacion } from '../Helpers/importacionesComunes'

const ITipoDocumentoModel = {
    id_tipo_doc: null,
    nombre: ''
}


export default function TipoDocumentos({ usuario }) {

    const [tipoDocumentosList, setTipoDocumentosList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [tipoDocumentoId, setTipoDocumentoId] = useState(0)
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [tipoDocumentosData, setTipoDocumentosData] = useState(ITipoDocumentoModel);
    const { configuracion, paginar, inicializar: inicializarPaginacion } = usePaginacion()

    useEffect(() => {
        obtenerArchivos()
    }, [])


    useEffect(() => {
        if (nameSearch.trim() === '') {
            setFilteredList(tipoDocumentosList);
        } else {
            const listaFiltrada = tipoDocumentosList.filter(item =>
                item.nombre.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setFilteredList(listaFiltrada.length > 0 ? listaFiltrada : tipoDocumentosList);
        }
    }, [nameSearch, tipoDocumentosList]);


    const obtenerArchivos = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/tiposdocumento`)])
            setTipoDocumentosList(resultList[0].data)
            setFilteredList(resultList[0].data)
            setBuscandoDatos(false);
            inicializarPaginacion(null, resultList[0].data)
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const handleOpen = () => { 
        setOpen(!open);
    }

    const verItem = async (e) => {
        try {
            setTipoDocumentoId(e.id_tipo_doc);

            let data = {
                id_tipo_doc: e.id_tipo_doc,
                nombre: e.nombre
            }
            setTipoDocumentosData(data)

            handleOpen();
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const verCrearItem = async (e) => {
        try {
            setTipoDocumentoId(0);
            handleOpen();
            setTipoDocumentosData(ITipoDocumentoModel);
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const crearAction = async () => {
        try {
            await Promise.all([Axios.post(`/api/tablasgenerales/tiposdocumento/`, tipoDocumentosData)])
            Swal.fire({
                icon: 'alert',
                title: `<p>El estado civil ha sido creada con éxito</p>`,
            })
            handleOpen();
            obtenerArchivos();
        } catch (error) {
            Swal.fire({
                icon: 'alert',
                title: `<p>Error al crear los Datos. Verifique los datos del formulario</p>`,
                showCancelButton: true,
            })
            console.log(error)
            setOpen(true);
        }
    }

    const editarAction = async () => {
        try {
            await Promise.all([Axios.post(`/api/tablasgenerales/tiposdocumento`, tipoDocumentosData)])
            Swal.fire({
                icon: 'alert',
                title: `<p>Los datos han sido actualizados con éxito</p>`,
            })
            handleOpen();
            obtenerArchivos();
            setTipoDocumentosData(ITipoDocumentoModel);
            setTipoDocumentoId(0);
        } catch (error) {
            Swal.fire({
                icon: 'alert',
                title: `<p>Error al actualizar los Datos</p>`,
                showCancelButton: true,
            })
            console.log(error)
            setOpen(true);
        }
    }

    const borrarItem = async (e) => {
        Swal.fire({
            title: "Esta seguro que desea Eliminarlo?",
            text: "Este cambio es irreversible!",
            type: "warning",
            confirmButtonText: 'Si, eliminar!',
            cancelButtonText: "No, cancelar!",
            showCancelButton: true,
            // allowOutsideClick: false
        }).then(
            async resultado => {
                if (resultado.value) {
                    try {
                        await Promise.all([Axios.delete(`/api/tablasgenerales/tiposdocumento/${e.id_tipo_doc}`)])
                        obtenerArchivos();
                        setNameSearch('');
                    } catch (error) {
                        console.log(error)
                        setBuscandoDatos(false);
                    }
                }
            }
        )
    }

    const limpiarBusqueda = async () => {
        obtenerArchivos();
        setNameSearch('')
    }

    const prepareExcelData = () => {
        return tipoDocumentosList.map(item => ({ Nombre: item.nombre }))
    }

    if (buscandoDatos) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando archivos...</span>
            </div>
        </Main>
    }

    if (tipoDocumentosList.length == 0) {
        return <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px', textAlign: 'center' }} >
            <Grid item>
                <Typography variant="h5">
                    No se encontraron archivos
                </Typography>
            </Grid>
        </Grid>
    }

    return <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
        <Typography variant="h4" className="flex ">
            Tipo de Documentos
        </Typography>
        <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
            <div className='w-full lg:w-96 xl:w-96'>
                <Input
                    type="text"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    label="Buscar nombre de Tipo de Documento"
                    value={nameSearch}
                    onChange={(e) => { setNameSearch(e.target.value) }}
                    color='black'
                />
            </div>
            {tipoDocumentosList.length > 0 && <div className='mt-8 w-full'>
                <div className="flex xl:!justify-end">
                    <Button className="flex ml-2 items-center gap-3 bg-green-500" onClick={verCrearItem}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                            />
                        </svg>
                        Crear
                    </Button>
                {nameSearch && <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200 ml-2' onClick={limpiarBusqueda}>Limpiar Busqueda</Button>}
                    <ExportToExcelGeneric data={prepareExcelData()} fileName="Listado de Tipo de Documentos" isProvider={true}/>
                </div>
                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none"
                                >
                                    Se han encontrado {tipoDocumentosList.length} Tipo de Documentos
                                </Typography>
                {tipoDocumentosList.length > 0 &&
                    <PiePagina>
                        <div className="">
                            <Paginacion configuracion={configuracion} paginar={paginar} />
                        </div>
                    </PiePagina>}
                <table className="text-left w-full mt-2">
                    <thead>
                        <tr>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Nombre
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Acciones
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredList.filter((item, index) => {
                                return index >= configuracion.iIni && index <= configuracion.iFin
                            }).map((item, index) => {
                            const isLast = index === filteredList.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                                <tr key={index}>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <div className="flex items-center gap-2">
                                            <Tooltip content="Ver Detalles">
                                                <IconButton variant="text" size="sm" onClick={() => verItem(item)}>
                                                    <PencilIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip content="Eliminar">
                                                <IconButton variant="text" size="sm" onClick={() => borrarItem(item)}>
                                                    <TrashIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {filteredList.length > 0 &&
                    <PiePagina>
                        <div className="">
                            <Paginacion configuracion={configuracion} paginar={paginar} />
                        </div>
                    </PiePagina>}
            </div>}
        </Card>
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader>{tipoDocumentoId !== 0 ? 'Editar' : 'Crear'} Tipo de Documento</DialogHeader>
            <DialogBody className="xl:p-10">

                <Tabs value="Contacto" className="max-w-full mt-2">
                    <TabsBody>

                        <div className="flex flex-col w-full mt-4">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Nombre
                            </Typography>
                            <Input
                                onChange={(e) => {
                                    setTipoDocumentosData({...tipoDocumentosData, nombre: e.target.value})
                                }}
                                value={tipoDocumentosData.nombre}
                                type='text'
                                id='name'
                                name='name'
                                color='black'
                                className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                        </div>
                    </TabsBody>
                </Tabs>
            </DialogBody>
            <DialogFooter className="space-x-2">
                <Button 
                    variant="gradient" 
                    color="blue" 
                    onClick={() => tipoDocumentoId !== 0 ? editarAction() : crearAction()}
                >
                    Guardar
                </Button>
                <Button variant="text" color="blue-gray" onClick={handleOpen}>
                    Cancelar
                </Button>
            </DialogFooter>
        </Dialog>
    </div>
}

