import React, { useState, useEffect, useCallback } from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import { Grid } from "@material-ui/core";
import { MultiSelect } from 'primereact/multiselect';
import { 
    Spinner, 
    Card, 
    Typography, 
    Input, 
    IconButton, 
    Tooltip, 
    Dialog, 
    DialogHeader, 
    DialogBody, 
    DialogFooter, 
    Button, 
    Textarea,
    Checkbox
} from '@material-tailwind/react';
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { useContextoGlobal } from '../Context/contextoGlobal';
import ExportToExcelGeneric from '../componentes/exportExcelGeneric';
import { PiePagina, Paginacion, usePaginacion } from '../Helpers/importacionesComunes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-quill/dist/quill.snow.css';
import 'quill-paste-smart';
import ChipInput from '../componentes/MUI/ChipInputs';
import DragComponent from '../componentes/DragDrop/DragComponent';
import "primereact/resources/themes/saga-blue/theme.css";

const IProvinciaModel = {
    nombre: '',
}

export default function EnvioEmails({ usuario }) {
    const [provinciasList, setProvinciasList] = useState([])
    const [asuntoEmail, setAsuntoEmail] = useState('')
    const [provinciaId, setProvinciaId,] = useState(0)
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [editorValue, setEditorValue] = useState('');
    const [open, setOpen] = React.useState(false);
    const [openEmailSearch, setOpenEmailSearch] = React.useState(false);
    const [provinciaData, setProvinciaData] = useState(IProvinciaModel);
    const [recipients, setRecipients] = useState([]);
    const [emailFiles, setEmailFiles] = useState([]);
    const [fetchedEmails, setFetchedEmails] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [distritos, setDistritos] = useState([]);
    const [rangos,setRangos] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([])
    const [isFetchingEmails, setIsFetchingEmails] = useState(false)
    const { configuracion, paginar, inicializar: inicializarPaginacion } = usePaginacion()
    const [parametros, setParametros] = useState({
        id_region: null,
        id_distrito: null,
        id_rango: null,
        sexo: null
    })

    useEffect(() => {
        buscarTablasGenerales()
    }, [])

    useEffect(() => {
        setProvinciasList([...provinciasList])
        inicializarPaginacion(null, provinciasList)
    }, [provinciasList])


    const buscarTablasGenerales = async () => {
        try {
            setBuscandoDatos(true)
            const vectorResultados = await Promise.all([Axios.get('/api/tablasgenerales/rangos'),
            Axios.get('/api/tablasgenerales/regiones/*'),
            Axios.get(usuario.sede_admin ? '/api/tablasgenerales/distritos/*' : `/api/tablasgenerales/distritos/${usuario.id_region}`)
            ])
            setRangos(vectorResultados[0].data)
            setRegiones(vectorResultados[1].data)
            setDistritos(vectorResultados[2].data.filter(item => item.id_distrito > 0))
            setBuscandoDatos(false)
        } catch (err) {
            setBuscandoDatos(false)
            console.log('Error al buscar los datos generales')
        }
    }

    const handleOpen = () => { 
        setOpen(!open);
    }

    const handleEmailOpenDialog = () => { 
        setOpenEmailSearch(true);
    }

    const cleanAllEmails = () => { 
        setRecipients([]);
    }


    const closeEmailOpenDialog = () => { 
        setOpenEmailSearch(false);
    }

    const sendEmail = async () => {
        if (!asuntoEmail) { 
            Swal.fire({
                icon: 'error',
                title: `<p>Por favor, complete el campo del asunto del correo electrónico.</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        if (recipients.length === 0) { 
            Swal.fire({
                icon: 'error',
                title: `<p>No ha seleccionado ningún destinatario</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        if (!editorValue) { 
            Swal.fire({
                icon: 'error',
                title: `<p>Por favor, introduzca el cuerpo del correo electrónico.</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('asunto', asuntoEmail);
        formData.append('mensaje', editorValue);
        formData.append('destinatarios', JSON.stringify(recipients));

        if (emailFiles && emailFiles.length > 0) {
            emailFiles.forEach((file, index) => {
                formData.append(`files`, file);
            });
        }

        try {
            await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/enviomultiplemails`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
                icon: 'success',
                title: `<p>El email ha sido enviado con éxito</p>`,
            });
            handleOpen();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `<p>Error al enviar el email</p>`,
                showCancelButton: true,
            });
            console.log(error);
            setOpen(true);
        }
    };

    const handleEditorChange = (e) => {
        setEditorValue(e.target.value);
    };

    const openPreview = () => {
        const newWindow = window.open();
        newWindow.document.write(editorValue);
        newWindow.document.close();
    };

    const getSearchEmails = async () => { 
        try {
            setIsFetchingEmails(true);
            const resp = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/enviomails/listadoemails`, parametros);
            if (resp.status === 200) { 
                const emails = resp.data
                    .filter(item => item.email && item.email.trim() !== '')
                    .map(item => ({ label: item.email, value: item.email.toLowerCase() }));
                setFetchedEmails(emails);
            }
            setIsFetchingEmails(false);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `<p>No se han encontrado emails asociado a la búsqueda</p>`,
                showCancelButton: true,
            });
            console.log("error", error);
            setOpen(true);
            setIsFetchingEmails(false);
        }
    }

    const handleEmailsSelection = async () => { 
        setRecipients([...recipients, ...fetchedEmails.map(item => item.value)]);
        closeEmailOpenDialog();
    }

    return <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
        <Typography variant="h4" className="flex ">
            Enviar Email
        </Typography>
        <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
          <div className='flex w-full lg:w-96 xl:w-[42rem] mb-2 gap-3 relative flex-col'>
            <Input
              type="text"
              label="Asunto del Email"
              value={asuntoEmail}
              onChange={(e) => { setAsuntoEmail(e.target.value) }}
              color='black'
            />

            <div className='relative w-full'>
                <ChipInput 
                    chips={recipients}
                    setChips={setRecipients}
                />
                <div className='flex flex-col absolute top-1 right-[-30px]'>
                    <MagnifyingGlassIcon width={20} height={20} className='cursor-pointer hover:opacity-85' onClick={handleEmailOpenDialog}/>
                    <TrashIcon width={20} height={20} className='cursor-pointer hover:opacity-85 mt-2' onClick={cleanAllEmails}/>
                </div>
            
            </div>
            
          </div>
          <div className='flex flex-col w-full lg:w-96 xl:w-[42rem]'>
            <Textarea
                value={editorValue}
                label='Contenido del Email'
                onChange={handleEditorChange}
                className='w-full h-[200px] m-h-[200px] overflow-y-auto p-[10px] border-2 border-gray-300 rounded-md text-[14px]'
            />
            <Button onClick={openPreview} className='mt-3'>
                Ver Previsualización
            </Button>
        </div>

          <DragComponent 
            ownerLicense={emailFiles}
            setOwnerLicense={setEmailFiles}
          />
          <Button onClick={handleOpen} className='mt-5 w-[300px]'>Enviar</Button>
        </Card>
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader>Enviar Emails</DialogHeader>
            <DialogBody className="xl:p-10">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-medium "
                >
                    ¿Está seguro de realizar esta operación?
                </Typography>
            </DialogBody>
            <DialogFooter className="space-x-2">
                <Button 
                    variant="gradient" 
                    color="blue" 
                    onClick={sendEmail}
                >
                    Aceptar
                </Button>
                <Button variant="text" color="blue-gray" onClick={closeEmailOpenDialog}>
                    Cancelar
                </Button>
            </DialogFooter>
        </Dialog>

        <Dialog open={openEmailSearch} handler={handleEmailOpenDialog}>
            <DialogHeader>Buscar Emails</DialogHeader>
            <DialogBody className="xl:p-10">
                <Parametros 
                    parametros={parametros}
                    setParametros={setParametros}
                    regiones={regiones}
                    distritos={distritos}
                    rangos={rangos}
                    usuario={usuario}
                    fetchedEmails={fetchedEmails}
                    selectedEmails={selectedEmails}
                    setSelectedEmails={setSelectedEmails}
                    getSearchEmails={getSearchEmails}
                    isFetchingEmails={isFetchingEmails}
                />
            </DialogBody>
            <DialogFooter className="space-x-2">
                <Button 
                    variant="gradient" 
                    color="blue" 
                    onClick={handleEmailsSelection}
                >
                    Agregar
                </Button>
                <Button variant="text" color="blue-gray" onClick={() => setOpenEmailSearch(false)}>
                    Cancelar
                </Button>
            </DialogFooter>
        </Dialog>
    </div>
}

function Parametros({ 
    parametros, 
    setParametros, 
    rangos, 
    regiones, 
    usuario, 
    distritos, 
    fetchedEmails,
    selectedEmails,
    setSelectedEmails,
    getSearchEmails,
    isFetchingEmails
}) {

    const regionesSegunUsuario = regiones.filter(item => item.id_region == usuario.id_region || usuario.sede_admin)
    const [distritosRegion, setDistritosRegion] = useState([])
    const [buscarEmails, setBuscarEmails] = useState(false)
    const { esCelular } = useContextoGlobal();

    useEffect(() => {
        setParametros({ ...parametros, id_distrito: null })
        setDistritosRegion(distritos.filter(item => item.id_region == parametros.id_region))
    }, [parametros.id_region])

    const handleCheckSexo = (type, value) => {
        setParametros({ ...parametros, [type]: value })
    }

    const cleanSelectedEmails = () => {
        setSelectedEmails([]);
    }

    return <div className="w-full mb-2">
        <div className="mt-[-30px] flex f-col">
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Región
                </Typography>
                <div className="col-span-2 mt-1">
                    <Seleccionador id='select-region' nombre="Todos" vector={regionesSegunUsuario} valor={parametros.id_region} onchange={(e) => setParametros({ ...parametros, id_region: e.target.value })} claves={{ id: 'id_region', nombre: 'nombre' }} />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Distrito
                </Typography>
                <div className="col-span-2 mt-1">
                    <Seleccionador id='select-distrito' nombre="Todos" vector={distritosRegion} valor={parametros.id_distrito} onchange={(e) => setParametros({ ...parametros, id_distrito: e.target.value })} claves={{ id: 'id_distrito', nombre: 'nombre' }} />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Rango
                </Typography>
                <div className="col-span-2 mt-1">
                    <SeleccionadorRangos vector={rangos} valor={parametros.id_rango} nombre="Todos" onchange={(e) => setParametros({ ...parametros, id_rango: e.target.value })} limpiar={() => setParametros({ ...parametros, id_rango: null })} />
                </div>
            </div>

            <div className="grid grid-cols-3 justify-left mt-2">
                <Typography variant="h5" color="blue-gray" className="mt-2">
                    Sexo
                </Typography>
                <div className="col-span-2 flex space-x-2 w-full mt-1">
                    <Checkbox
                        label="Masculino"
                        ripple={false}
                        checked={parametros.sexo == 'M'} 
                        onClick={() => handleCheckSexo('sexo', 'M')}
                        className='w-5 h-5'
                    />
                    <Checkbox
                        label="Femenino"
                        ripple={false}
                        checked={parametros.sexo == 'F'} 
                        onClick={() => handleCheckSexo('sexo', 'F')}
                        className='w-5 h-5'
                    />

                    <Checkbox
                        label="Todos"
                        ripple={false}
                        checked={parametros.sexo == null} 
                        onClick={() => handleCheckSexo('sexo', null)}
                        className='w-5 h-5'
                    />
                </div>
            </div>
            
            <div className='flex gap-2 justify-center'>
                <Button 
                    variant="gradient" 
                    onClick={() => { getSearchEmails(); setBuscarEmails(true); }}
                    className='w-[200px] mt-4 mb-3'
                    disabled={isFetchingEmails}
                >
                    {isFetchingEmails ? <Spinner className='w-4 h-4 m-auto' /> : 'Buscar Emails'}
                </Button>

                {selectedEmails.length > 0 &&
                    <Button 
                        variant="text" 
                        onClick={cleanSelectedEmails}
                        className='w-[200px] mt-4 mb-3 text-red-400 bg-red-100 hover:bg-red-200'
                    >
                        Limpiar Emails
                    </Button> 
                }
            </div>

            {buscarEmails && !isFetchingEmails &&
                <div className="flex m-auto">
                    <Typography variant="h7" color="blue-gray" className="mt-2 text-green-500">
                        {fetchedEmails.length} Emails Encontrados
                    </Typography>
                </div>
            }

            <div className="hidden grid-cols-3 justify-left mt-2 relative z-[1000px]">
                <Typography variant="h5" color="blue-gray" className="mt-2">
                    Emails 
                </Typography>
                <div className="col-span-2 mt-1 border-[2px] border-[#ccc] rounded-md p-2">
                    <MultiSelect
                        value={selectedEmails}
                        options={fetchedEmails}
                        onChange={(e) => setSelectedEmails(e.value)} 
                        placeholder="Seleccionar Emails" 
                        display="chip"
                        className="w-full md:w-20rem" 
                    />
                </div>
            </div>
        </div>
    </div>
}

export function Seleccionador({ vector, onchange, valor, nombre, noDefault, name, classancho, limpiar, claves, id }) {
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";

    if (classancho) {
        clasesSelect = `block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }
    return (
        <div className="flex flex-col sel select-ui-data w-full">
            <select value={valor} name={name ? name : ''} className='h-9' onChange={onchange} id={id ? id : null}>
                {noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item => <option value={item[claves.id]} key={item[claves.id]}>{item[claves.nombre]}</option>)}
            </select>
            {valor != "-1" && limpiar &&
                <button>
                    <FontAwesomeIcon className="ic-abm"
                        icon={faWindowClose}
                        onClick={limpiar} />
                </button>}
        </div>
    )
}

function SeleccionadorRangos({ vector, onchange, valor, nombre, noDefault, name, classancho, limpiar }) {
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";

    if (classancho) {
        clasesSelect = `block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    return (
        <div className="flex flex-col sel select-ui-data w-full">
            <select value={valor} name={name ? name : ''} className='h-9' onChange={onchange}>
                {noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item => <option value={item.id_rango} key={item.id_rango}>{item.nombre}</option>)}
            </select>
        </div>
    )

}
