import React, {createContext} from 'react'
import {useEventos} from '../hooks/useEventos'

export const eventoContext = createContext()

const Provider = eventoContext.Provider

export const EventoContextProvider = ({children,id})=>{ // Exporto el Provider

    const {inscriptos,
            cargando,
            refrescar,
            filtros,
            codInscripto,
            handleChangeCodInscripto,
            cargarInscriptos,
            ingresarInscripto,
            inscriptoSeleccionado,
            buscarInscripto,cancelarIngreso,handleInputCodInscripto,
            handleChangeIngresarInscripto,mensaje} = useEventos(id)

    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{inscriptos,
                        cargando,
                        refrescar,
                        filtros,
                        codInscripto,
                        handleChangeCodInscripto,
                        cargarInscriptos,
                        ingresarInscripto,
                        inscriptoSeleccionado,
                        buscarInscripto,
                        cancelarIngreso,handleInputCodInscripto,
                        handleChangeIngresarInscripto,mensaje,id}}> 
        <div>
            {children}
        </div>
    </Provider>
}
