import { Button } from '@material-tailwind/react';
import * as XLSX from 'xlsx';

const ExportToExcelBancos = ({ data, fileName, isProvider = false }) => {

  const exportToExcel = () => {
    let dataExcel = [];

    if(isProvider){
      data.map(item => {
        let newData = {
          Nombre:  item.nombre,
          Direccion: item.direccion,
          Telefono: item.telefono,
          Email: item.email,
          Cuit: item.cuit,
          Pagina_Web: item.pagina_web,
          Banco: item.banco,
          Cbu: item.cbu,
          Observaciones: item.observaciones,
          Contacto: item.contacto
        }
        dataExcel.push(newData);
      })
    }else{
      data.map(item => {
        let newData = {
          Nombre:  item.nombre,
          Abreviatura: item.abreviatura,
          Movimiento: item.tipo_movimiento
        }
        dataExcel.push(newData);
      })
    }
 
    // Crear la hoja de cálculo desde un arreglo de datos
    const worksheet = XLSX.utils.json_to_sheet(dataExcel, { origin: 'A1' }); 
    
    // Ancho de columnas
    worksheet['!cols'] = [
      { wch: 30 },  
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      // Añade más columnas si es necesario
    ];

    // Crear un nuevo libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Añadir la hoja al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Descargar el archivo
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <Button variant="gradient" className="flex lg:ml-2 items-center gap-3 mb-3 lg:mb-0" onClick={exportToExcel}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-4 w-4/"
            >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
        </svg>
      Exportar Excel
    </Button>
  );
};

export default ExportToExcelBancos;
