import React, { useState, useEffect,useRef, useLayoutEffect } from 'react';
import {Main,Axios,Loading,uuidv4,usePaginacion,Paginacion} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf,faWindowClose,faFileExcel,faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import {useContextoGlobal} from '../Context/contextoGlobal';
import DatePicker from "react-datepicker";
import BusquedaSinForm from '../componentes/BusquedaSinForm';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import {formatearComparacion,formatoMoneda,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import {transformarIso8601,obtenerFechaDiamenosN,obtenerFechaUltimoDiaMes} from '../Helpers/fechas';
import ReportesEgresosIntegrados from '../reportes/ReportesEgresosIntegrados';
import {imprimir as imprimirEcronologicos} from '../impresiones/ecronologicos';
import {listaTiposIngresosIntegrales as tiposReporte} from '../Listas/tiposReportes';
import ImpresionPDF from '../componentes/ImpresionPDF';
import { Button, Card, Checkbox, Input, Option, Select, Spinner, Typography } from '@material-tailwind/react';
import { imprimir as imprimirResumen } from '../impresiones/resumeningresos';
import { imprimir as imprimirReciboGeneral } from '../impresiones/ingresosIntegrales/reciboGeneral';
import { imprimir as imprimirRubros } from '../impresiones/ingresosIntegrales/rubros';
import { imprimir as imprimirRubrosyFP } from '../impresiones/ingresosIntegrales/rubrosyFP';
import { imprimir as imprimirFp } from '../impresiones/ingresosIntegrales/fp';
import { imprimir as imprimirFpyRubros } from '../impresiones/ingresosIntegrales/fpyRubros';
import { imprimir as imprimirReporteCDN } from '../impresiones/ingresosIntegrales/reporteCDN';
import { imprimir as imprimirRubroyAportantes } from '../impresiones/ingresosIntegrales/rubroyAportantes';
import { imprimir as imprimirAportantesyRubros } from '../impresiones/ingresosIntegrales/aportantesyRubros';
import { imprimir as imprimirResumenRubros } from '../impresiones/ingresosIntegrales/resumenRubros';
import { imprimir as imprimirResumenRubrosDist } from '../impresiones/ingresosIntegrales/resumenRubrosDist';
import { imprimir as imprimirResumenFondosDist } from '../impresiones/ingresosIntegrales/resumenFondosDist';
import { imprimir as imprimirResumenFormasPago } from '../impresiones/ingresosIntegrales/resumenFormasPago';
import { imprimir as imprimirResumenGeograficoPartido } from '../impresiones/ingresosIntegrales/resumenGeograficoPartido';
import { imprimir as imprimirResumenGeograficoPlano } from '../impresiones/ingresosIntegrales/resumenGeograficoPlano';

import { imprimir as imprimirReciboGeneral2 } from '../impresiones/ingresosIntegrales2/reciboGeneral';
import { imprimir as imprimirRubros2 } from '../impresiones/ingresosIntegrales2/rubros';
import { imprimir as imprimirRubrosyFP2 } from '../impresiones/ingresosIntegrales2/rubrosyFP';
import { imprimir as imprimirFp2 } from '../impresiones/ingresosIntegrales2/fp';
import { imprimir as imprimirFpyRubros2 } from '../impresiones/ingresosIntegrales2/fpyRubros';
import { imprimir as imprimirReporteCDN2 } from '../impresiones/ingresosIntegrales2/reporteCDN';
import { imprimir as imprimirRubroyAportantes2 } from '../impresiones/ingresosIntegrales2/rubroyAportantes';
import { imprimir as imprimirAportantesyRubros2 } from '../impresiones/ingresosIntegrales2/aportantesyRubros';
import { imprimir as imprimirResumenRubros2 } from '../impresiones/ingresosIntegrales2/resumenRubros';
import { imprimir as imprimirResumenRubrosDist2 } from '../impresiones/ingresosIntegrales2/resumenRubrosDist';
import { imprimir as imprimirResumenFondosDist2 } from '../impresiones/ingresosIntegrales2/resumenFondosDist';
import { imprimir as imprimirResumenFormasPago2 } from '../impresiones/ingresosIntegrales2/resumenFormasPago';
import { imprimir as imprimirResumenGeograficoPartido2 } from '../impresiones/ingresosIntegrales2/resumenGeograficoPartido';
import { imprimir as imprimirResumenGeograficoPlano2 } from '../impresiones/ingresosIntegrales2/resumenGeograficoPlano';
import { TrashIcon } from '@heroicons/react/24/outline';

import Swal from 'sweetalert2';

const anchoPaginacion = 50;
const distr = ['tipo-r-rd', 'tipo-r-fd', 'tipo-r-geoplano', 'tipo-r-geopa']
const res = ['tipo-r-geo']

export default function ReportesIngresosIntegrales(){
    const [cargandoRegistros,setCargandoRegistros] = useState(false);
    const [huboError,setHuboError] = useState(false);
    const [datos,setDatos] = useState([]);
    const [registrosAmostrar,setRegistrosAmostrar] = useState([]);
    const [textoBusqueda,setTextoBusqueda] = useState('');
    const {usuario} = useContextoGlobal();
    const [contadorConsulta,setContadorConsulta]=useState(0);
    const [mostrarDist, setMostrarDist] = useState(false);
    /*const [parametros,setParametros]=useState({id_region:usuario.id_region,
        id_rango:'-1',
        cant_meses_desde:-1,
        cant_meses_hasta:-1,
        promedio_desde:-1,
        promedio_hasta:-1,
        total_desde:-1,
        total_hasta:-1,
        mesesSi:false,
        promedioSi:false,
        aportesSi:false,
        fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
        fechaHasta:new Date()})*/    
    const [parametros,setParametros]=useState({id_tanda:103,
                                                tipo: tiposReporte[0].id,
                                                id_año_fiscal:null,
                                                mesAño:null,
                                                año:null,
                                                id_obrero: null,
                                                tipo:'Recibo general',
                                                criterio:'añofiscal',
                                                destino: 'pastor',
                                                nombre_destino:'',
                                                id_obrero:null,
                                                id_iglesia:null,
                                                id_proveedor:null,    
                                                id_provincia: null,  
                                                id_region: null,
                                                id_fondo_tesoreria: null,
                                                usuario_alta: null,   
                                                tipo_recibo: null,                                       
                                                formapago:null,
                                                rubro:null,
                                                fondo:null,
                                                moneda:1,
                                                caja:null,
                                                fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
                                                fechaHasta:new Date(),
                                                fecha:new Date(),
                                                descripcionCriterio:'',
                                                total_caja:false,
                                                total_diario:false,
                                                total_mensual:false,
                                                fdo_regiones: false,
                                                total_año:false,
                                                nro_desde: null,
                                                nro_hasta: null,
                                                orden: null,
                                                no_agrupar:false})
    const [periodosFiscales,setPeriodosFiscales] = useState([]);
    const [tandas,setTandas] = useState([]);
    const [rubros,setRubros] = useState([]);
    const [monedas,setMonedas] = useState([]);
    const [cajas,setCajas] = useState([]);
    const [fondos,setFondos] = useState([]);
    const [provincias,setProvincias] = useState([]);
    const [regiones,setRegiones] = useState([]);
    const [obreros,setObreros] = useState([]);
    const [terminalesUsers,setTerminalesUsers] = useState([]);
    const [tipoRecibos,setTipoRecibos] = useState([]);
    const [formasPago,setFormasPago] = useState([]);
    const [reporteEnEjecucion,setReporteEnEjecucion] = useState(false)
    const meses = [0,1,2,3,4,5,6,7,8,9,10,11,12];
    const {configuracion,paginar,inicializar} = usePaginacion();
    registerLocale('es', es)


    const subtitulo = useRef('');

    useEffect(()=>{
        buscarTablasGenerales()
        .then(()=>{
         console.log(tandas)
            // setParametros({...parametros,id_tanda:tandas[0].id_tanda})
        })
    },[])

    useEffect(()=>{
        setReporteEnEjecucion(false)
        if (distr.includes(parametros?.tipo)) setMostrarDist(true); 
        else setMostrarDist(false);
    },[parametros])

    useEffect(()=>{
        if(tandas.length>0){
            setParametros({...parametros,id_tanda:tandas[0].id_tanda})
        }
    },[tandas])
    
    useEffect(()=>{
        if(!parametros.destino){
            setParametros({...parametros,nombre_destino:'',id_obrero:null,id_iglesia:null,id_proveedor:null})
        }else if(parametros.destino=='pastor'){
            setParametros({...parametros,nombre_destino:'',id_iglesia:null,id_proveedor:null})
            hacerfocoEnPrimerInput('destino-n')
        }else if(parametros.destino=='proveedor'){
            setParametros({...parametros,nombre_destino:'',id_iglesia:null,id_obrero:null})
            hacerfocoEnPrimerInput('destino-n')
        }else{
            setParametros({...parametros,nombre_destino:'',id_obrero:null,id_proveedor:null})
            hacerfocoEnPrimerInput('destino-n')
        }
    },[parametros.destino])

    useEffect(()=>{
        subtitulo.current = crearSubtitulosSegunParametros()
    },[parametros,cargandoRegistros]) // agrego a las dependencias la variable cargandoRegistros para que cree el subtitulo inmediatamente aunque no hayan cambiado los parametros porque podría ejecutar el reporte sin modificar la selección o sea con los valores iniciales

      const buscarTablasGenerales = async ()=>{
        try{
            setCargandoRegistros(true)
            const vectorResultados = await Promise.all([Axios.get('/api/tablasgenerales/reportes/tandas'),
            Axios.get('/api/tablasgenerales/reportes/perfiscales'),
            Axios.get('/api/tablasgenerales/rubrosingresos'),
            Axios.get('/api/reportes/tablas/monedas'),
            Axios.get('/api/reportes/tablas/cajas'),
            Axios.get('/api/reportes/tablas/fondos'),
            Axios.get('/api/reportes/tablas/formaspago'),
            Axios.get('/api/tablasgenerales/provincias'),
            Axios.get('/api/tablasgenerales/terminales-usuarios'),
            Axios.get('/api/tablasgenerales/tipo-recibos'),
            Axios.get('/api/tablasgenerales/regiones'),
            Axios.get('/api/tablasgenerales/obreros')])
            setTandas(vectorResultados[0].data.reverse())
            setPeriodosFiscales(vectorResultados[1].data.reverse())
            setRubros(vectorResultados[2].data)
            setMonedas(vectorResultados[3].data)
            setCajas(vectorResultados[4].data)
            setFondos(vectorResultados[5].data)
            setFormasPago(vectorResultados[6].data)
            setProvincias(vectorResultados[7].data)
            setTerminalesUsers(vectorResultados[8].data)
            setTipoRecibos(vectorResultados[9].data)
            setRegiones(vectorResultados[10].data)
            setObreros(vectorResultados[11].data)
            setCargandoRegistros(false)
        }catch(err){
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al buscar los datos generales')  
        }
    }

    const triggerReport = async ()=>{
        try{
            if (!parametros.tipo) {
                Swal.fire({
                    icon: 'error',
                    title: `<p>Por favor seleccione un tipo de reporte</p>`,
                    showCancelButton: true,
                });
                return;
            }

            const url = distr.includes(parametros.tipo) ? '/api/reportes/v2/reporteingresos/distr' : res.includes(parametros.tipo) ? '/api/reportes/v2/reporteingresos/res' : '/api/reportes/v2/reporteingresos';
            console.log(parametros.criterio);
            let dataSubmit = {
                mes: parametros.criterio === 'mesaño' ? parametros.mesAño.getMonth() + 1 : null,
                año: parametros.criterio === 'año' ? parametros.año.getFullYear() : parametros.criterio === 'mesaño' ? parametros.mesAño.getFullYear() : null,
                id_año_fiscal: parametros.id_año_fiscal,
                f_desde: parametros.criterio === 'periodo' ? convertirMesAñoAfechaDesde(parametros.fechaDesde) : parametros.criterio === 'fecha' ? convertirMesAñoAfechaCompleta(parametros.fechaDesde) :  null,
                f_hasta: parametros.criterio === 'periodo' ? convertirMesAñoAfechaHasta(parametros.fechaHasta) : parametros.criterio === 'fecha' ? convertirMesAñoAfechaCompleta(parametros.fechaHasta) : null,
                id_rubro: parametros.rubro ? Number(parametros.rubro) : null,
                id_tipo_moneda: parametros.id_tipo_moneda,
                id_forma_pago: parametros.formapago ? Number(parametros.formapago) : null,
                nro_desde: parametros.nro_desde,
                nro_hasta: parametros.nro_hasta,
                id_obrero: parametros.id_obrero ? Number(parametros.id_obrero) : null,
                id_iglesia: parametros.id_iglesia ? Number(parametros.id_iglesia) : null,
                fdo_regiones: parametros.fdo_regiones,
                id_provinciaP: parametros.id_provincia,
                id_region: parametros.id_region,
                usuario: parametros.usuario_alta,
                tipo_compr: parametros.tipo_recibo === "*" ? null : parametros.tipo_recibo,
                id_fondo_tesoreria: parametros.id_fondo_tesoreria,
                fdo_regiones: parametros.fdo_regiones === false ? null : true
            };

            setReporteEnEjecucion(true)
            const dataReporte = await Axios.post(url, dataSubmit)
            if (dataReporte.data.length > 0) {
                const nombrePDF = tiposReporte.find(item => item.id === parametros.tipo)?.nombre.split(' ').join('-').toLowerCase() ?? 'reporte-ingresos';
                const tituloPDF = tiposReporte.find(item => item.id === parametros.tipo)?.nombre ?? 'Reporte de Ingresos';
                const callbackPDF = objetosSegunParametros(parametros,'pdf');
                const arrayPDF = objetosSegunParametros(parametros,'arraypdf');
                switch(parametros.tipo){
                    case 'tipo-rg' :
                        imprimirReciboGeneral(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-rubro' :
                        imprimirRubros(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-rufp' :
                        imprimirRubrosyFP (dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-fp' :
                        imprimirFp(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-fpru' :
                        imprimirFpyRubros(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-cdn' :
                        imprimirReporteCDN(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-ruap' :
                        imprimirRubroyAportantes(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-apru' :
                        imprimirAportantesyRubros(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-r-ru' :
                        imprimirResumenRubros(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-rd' :
                        imprimirResumenRubrosDist(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-fd' :
                        imprimirResumenFondosDist(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-r-fp' :
                        imprimirResumenFormasPago(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-geopa' :
                        imprimirResumenGeograficoPartido(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-geoplano' :
                        imprimirResumenGeograficoPlano(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break    
                    // case 'tipo-r-geo' :
                    // break                            
                    default: alert('Falta implementar la impresión de tipo de reporte')                                                        
                } 
                //imprimirResumen(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: `<p>No se encontraron registros</p>`,
                    showCancelButton: true,
                });
            }

            setReporteEnEjecucion(false)
        } catch(err){
            console.log(err);
            console.log('Error al buscar los datos generales')  
            Swal.fire({
                icon: 'error',
                title: `<p>Ha ocurrido un error durante la petición</p>`,
                showCancelButton: true,
            });
            setReporteEnEjecucion(false)
        }
    }

    const triggerReport2 = async ()=>{
        try{
            if (!parametros.tipo) {
                Swal.fire({
                    icon: 'error',
                    title: `<p>Por favor seleccione un tipo de reporte</p>`,
                    showCancelButton: true,
                });
                return;
            }

            const url = distr.includes(parametros.tipo) ? '/api/reportes/v2/reporteingresos/distr' : res.includes(parametros.tipo) ? '/api/reportes/v2/reporteingresos/res' : '/api/reportes/v2/reporteingresos';
            console.log(parametros.criterio);
            let dataSubmit = {
                mes: parametros.criterio === 'mesaño' ? parametros.mesAño.getMonth() + 1 : null,
                año: parametros.criterio === 'año' ? parametros.año.getFullYear() : parametros.criterio === 'mesaño' ? parametros.mesAño.getFullYear() : null,
                id_año_fiscal: parametros.id_año_fiscal,
                f_desde: parametros.criterio === 'periodo' ? convertirMesAñoAfechaDesde(parametros.fechaDesde) : parametros.criterio === 'fecha' ? convertirMesAñoAfechaCompleta(parametros.fechaDesde) :  null,
                f_hasta: parametros.criterio === 'periodo' ? convertirMesAñoAfechaHasta(parametros.fechaHasta) : parametros.criterio === 'fecha' ? convertirMesAñoAfechaCompleta(parametros.fechaHasta) : null,
                id_rubro: parametros.rubro ? Number(parametros.rubro) : null,
                id_tipo_moneda: parametros.id_tipo_moneda,
                id_forma_pago: parametros.formapago ? Number(parametros.formapago) : null,
                nro_desde: parametros.nro_desde,
                nro_hasta: parametros.nro_hasta,
                id_obrero: parametros.id_obrero ? Number(parametros.id_obrero) : null,
                id_iglesia: parametros.id_iglesia ? Number(parametros.id_iglesia) : null,
                id_provinciaP: parametros.id_provincia,
                id_region: parametros.id_region,
                usuario: parametros.usuario_alta,
                tipo_compr: parametros.tipo_recibo === "*" ? null : parametros.tipo_recibo,
                id_fondo_tesoreria: parametros.id_fondo_tesoreria,
                fdo_regiones: parametros.fdo_regiones === false ? null : true
            };

            setReporteEnEjecucion(true)
            const dataReporte = await Axios.post(url, dataSubmit)
            if (dataReporte.data.length > 0) {
                const nombrePDF = tiposReporte.find(item => item.id === parametros.tipo)?.nombre.split(' ').join('-').toLowerCase() ?? 'reporte-ingresos';
                const tituloPDF = tiposReporte.find(item => item.id === parametros.tipo)?.nombre ?? 'Reporte de Ingresos';
                const callbackPDF = objetosSegunParametros(parametros,'pdf');
                const arrayPDF = objetosSegunParametros(parametros,'arraypdf');
                switch(parametros.tipo){
                    case 'tipo-rg' :
                        imprimirReciboGeneral2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-rubro' :
                        imprimirRubros2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-rufp' :
                        imprimirRubrosyFP2 (dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-fp' :
                        imprimirFp2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-fpru' :
                        imprimirFpyRubros2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-cdn' :
                        imprimirReporteCDN2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-ruap' :
                        imprimirRubroyAportantes2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-apru' :
                        imprimirAportantesyRubros2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-r-ru' :
                        imprimirResumenRubros2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-rd' :
                        imprimirResumenRubrosDist2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-fd' :
                        imprimirResumenFondosDist2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break                        
                    case 'tipo-r-fp' :
                        imprimirResumenFormasPago2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-geopa' :
                        imprimirResumenGeograficoPartido2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break
                    case 'tipo-r-geoplano' :
                        imprimirResumenGeograficoPlano2(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
                        break    
                    // case 'tipo-r-geo' :
                    // break                            
                    default: alert('Falta implementar la impresión de tipo de reporte')                                                        
                } 
                //imprimirResumen(dataReporte.data, nombrePDF, tituloPDF,callbackPDF,arrayPDF,'portrait',parametros);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: `<p>No se encontraron registros</p>`,
                    showCancelButton: true,
                });
            }

            setReporteEnEjecucion(false)
        } catch(err){
            console.log(err);
            console.log('Error al buscar los datos generales')  
            Swal.fire({
                icon: 'error',
                title: `<p>Ha ocurrido un error durante la petición</p>`,
                showCancelButton: true,
            });
            setReporteEnEjecucion(false)
        }
    }

    const ejecutarReporte = ()=>{
        //setReporteEnEjecucion(true)
    }

    const finalizarReporte = ()=>{
        setReporteEnEjecucion(false)
    }

    const crearSubtitulosSegunParametros = ()=>{
        let descripcion = '';

        switch (parametros.criterio) {
            // case 'tanda':
            //     descripcion = `${descripcion} Tanda: ${tandas.filter(item => item.id_tanda == parametros.id_tanda).map(item => item.nombre)}`;
            //     break;
            case 'añofiscal':
                descripcion = `${descripcion} Año fiscal: ${periodosFiscales.filter(item => item.id_año_fiscal == parametros.id_año_fiscal).map(item => item.nombre)}`;
                break;
            case 'mesaño':
                descripcion = `${descripcion} Mes y año: ${document.getElementById('select-mesaño')?.value || 'N/A'}`;
                break;
            case 'año':
                descripcion = `${descripcion} Año: ${document.getElementById('select-año')?.value || 'N/A'}`;
                break;
            case 'periodo':
                const desde = document.getElementById('select-periodo-fdesde')?.value || 'N/A';
                const hasta = document.getElementById('select-periodo-fhasta')?.value || 'N/A';
                descripcion = `${descripcion} Período: ${desde} --> ${hasta}`;
                break;
            case 'fecha':
                descripcion = `${descripcion} Fecha: ${document.getElementById('select-fecha')?.value || 'N/A'}`;
                break;
        }
        return descripcion
    }

    const cb_filtro = item=>
    formatearComparacion(item.codigo_rubro).includes(formatearComparacion(textoBusqueda)) ||
    formatearComparacion(item.rubro).includes(formatearComparacion(textoBusqueda))


    if (huboError){
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <p>Se produjo un error al cargar los datos para esta vista</p>
            </div>
        </Main>
    }

    if (cargandoRegistros){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
                    </div>
                </Main>
    };

    return (  
    <Main center>  
        {<div className={'mr-6 ml-6'}>
            <div className="flex f-col text-large justify-center items-center mb-4">
                <Typography variant="h4">
                    Reporte de Ingresos
                </Typography>
            </div>
            <Parametros parametros = {parametros} 
                        setParametros={setParametros} 
                        tandas={tandas}
                        provincias={provincias}
                        regiones={regiones}
                        terminalesUsers={terminalesUsers}
                        tipoRecibos={tipoRecibos}
                        tiposReporte={tiposReporte}
                        periodosFiscales ={periodosFiscales} 
                        centrado={contadorConsulta==0 ? true : false}
                        cajas = {cajas}
                        rubros = {rubros}
                        monedas = {monedas}
                        fondos = {fondos}
                        formasPago = {formasPago}
                        obreros = {obreros}
                        mostrarDist = {mostrarDist}
                        reporteEnEjecucion = {reporteEnEjecucion}
                        ejecutarReporte2={triggerReport2}
                        ejecutarReporte={triggerReport}/>
        </div>}
        {/*El reporte se renderiza en una tabla usando los objetos titulos y columnas 
           Pero si hiciera falta hacemos el body de la tabla aquí y lo pasamos en bodyTablaCustomizado
           Esta funcionalidad se implementa de esta manera porque puede haber N reportes
           con diferentes necesidades respecto de como debe renderizarse.
           Depende del caso, a veces con definir titulos y columnas es suficiente
        */}
    </Main>
    )
}
function Parametros({parametros,
                    setParametros,
                    cajas,
                    tandas,
                    provincias,
                    regiones,
                    terminalesUsers,
                    tipoRecibos,
                    periodosFiscales,
                    tiposReporte,
                    centrado,
                    rubros,
                    fondos,
                    obreros,
                    mostrarDist,
                    formasPago,
                    reporteEnEjecucion,
                    ejecutarReporte,
                    ejecutarReporte2,
                    setNombreCriterio}){

    const inputBusquedaRef = useRef()
    const nombreCriterio = useRef('Seleccione el año fiscal:')
    const r2 = useRef('null')

    const [buscarDestino,setBuscarDestino] = useState(false)
    const [iglesias,setIglesias] = useState([])
    const { esCelular} = useContextoGlobal();
    const [versionVieja,setVersionVieja] = useState(false)

    const handleCheckCriterio = (e)=>{
        const nuevoCriterio = e.target.value
        console.log("parametros", parametros);
        reinicializarCriterios(setParametros,parametros,nuevoCriterio,tandas,periodosFiscales,setNombreCriterio)
        cargarNombreCriterio(nuevoCriterio,nombreCriterio)
        hacerfocoEnPrimerInput(`select-${e.target.value}`)
    }

    const handleCheckTipo = (e)=>{
        setParametros({...parametros,tipo : e.target.value,criterio:'tanda'})
    }

    const handleCheckDestino = (e)=>{
        setParametros({...parametros,destino : e.target.name=="checkdestino" ? parametros.destino ? null : 'proveedor' : e.target.value})
    }

    const handleCheckCaja = (e)=>{
        setParametros({...parametros,caja : parametros.caja ? null : e.target.name=="checkcaja" ? 1 : e.target.value})
    }

    const handleCheckNoAgrupar = (e)=>{
        setParametros({...parametros,no_agrupar : parametros.no_agrupar ? null : e.target.name=="checknoagrupar" ? 1 : e.target.value})
    }

    const handleCheckOrden = (e)=>{
        setParametros({...parametros,orden : parametros.orden ? null : e.target.name=="checkorden" ? 1 : e.target.value})
    }


    const handleCheckFormaPago = (e)=>{
        setParametros({...parametros,formapago : parametros.formapago ? null : e.target.name=="checkformapago" ? 1 : e.target.value})
    }

    const handleCheckRubro = (e)=>{
        setParametros({...parametros,rubro : parametros.rubro ? null : e.target.name=="checkrubro" ? 1 : e.target.value})
    }

    const handleCheckTotalCaja = (e)=>{
        setParametros({...parametros,total_caja : parametros.total_caja ? false : true})
    }

    const handleCheckTotalDiario = (e)=>{
        setParametros({...parametros,total_diario : parametros.total_diario ? false : true})
    }

    const handleCheckTotalMensual = (e)=>{
        setParametros({...parametros,total_mensual : parametros.total_mensual ? false : true})
    }

    const handleCheckTotalAño = (e)=>{
        setParametros({...parametros,total_año : parametros.total_año ? false : true})
    }

    const handleCheckFondoRegional = (e)=>{
        setParametros({...parametros,fdo_regiones : parametros.fdo_regiones ? false : true})
    }

    const handleCheckFondo = (e)=>{
        setParametros({...parametros,fondo : parametros.fondo ? null : e.target.name=="checkfondo" ? 1 : e.target.value})
    }

    const handleChangeNombre = (e)=>{
        setParametros({...parametros,nombre_destino : e.target.value})
    }

    const finalizarSeleccionObrero = (item)=>{
        setParametros({...parametros,id_obrero : item.id_obrero,nombre_destino:item.nom_simple,id_proveedor:null,id_iglesia:null})
        setBuscarDestino(false)
    }

    const finalizarSeleccionProveedor = (item)=>{
        setParametros({...parametros,id_proveedor : item.id_proveedor,nombre_destino:item.nombre,id_obrero:null,id_iglesia:null})
        setBuscarDestino(false)
    }

    const finalizarSeleccionIglesia = (item)=>{
        setParametros({...parametros,id_iglesia : item.id_iglesia,nombre_destino:item.nombre,id_proveedor:null,id_obrero:null})
        setBuscarDestino(false)
    }

    const validarParametros = ()=>{

        if (parametros.criterio=="tanda" && !parametros.id_tanda){
            alert('Falta seleccionar una tanda')
            return
        }

        if (parametros.criterio=="añofiscal" && !parametros.id_año_fiscal){
            alert('Falta seleccionar un año fiscal')
            return
        }
        if (parametros.criterio=="año" && !parametros.año){
            alert('Falta seleccionar un año')
            return
        }
        if (parametros.criterio=="mesaño" && !parametros.mesAño){
            alert('Falta seleccionar un mes y un año')
            return
        }
        if (parametros.criterio=="periodo" && (!parametros.fechaHasta || !parametros.fechaDesde)){
            alert('Falta seleccionar un mes y un año')
            return
        }
        if (parametros.criterio=="fecha" && !parametros.fecha){
            alert('Falta seleccionar un mes y un año')
            return
        }
        if(versionVieja){
            ejecutarReporte()
        }else{
            ejecutarReporte2()
        }
    }

    const validarParametros2 = ()=>{

        if (parametros.criterio=="tanda" && !parametros.id_tanda){
            alert('Falta seleccionar una tanda')
            return
        }

        if (parametros.criterio=="añofiscal" && !parametros.id_año_fiscal){
            alert('Falta seleccionar un año fiscal')
            return
        }
        if (parametros.criterio=="año" && !parametros.año){
            alert('Falta seleccionar un año')
            return
        }
        if (parametros.criterio=="mesaño" && !parametros.mesAño){
            alert('Falta seleccionar un mes y un año')
            return
        }
        if (parametros.criterio=="periodo" && (!parametros.fechaHasta || !parametros.fechaDesde)){
            alert('Falta seleccionar un mes y un año')
            return
        }
        if (parametros.criterio=="fecha" && !parametros.fecha){
            alert('Falta seleccionar un mes y un año')
            return
        }
        ejecutarReporte2()
    }


    const buscarIglesiasPorObrero = async (e)=>{
        const id_obrero = e.target.value;
        setParametros({...parametros, id_obrero, id_iglesia: null});
        try{
            if (!id_obrero) {
                setIglesias([]);
                return;
            }
            const iglesias = await Axios.get('/api/tablasgenerales/iglesiasobrero/'+id_obrero)
            setIglesias(iglesias.data)
        }catch(err){
            console.log('Error al buscar los datos generales')  
        }
    }
    
    return <Card className="mt-4 w-full p-10 mb-10">
                <div className="flex f-col">
                    {/*<Prueba ref2={r2}/>
                    <button onClick ={()=>alert(r2.current.options[r2.current.selectedIndex].text)}>macho</button>*/}
                
                    {/*<div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                            <div>
                                <Typography variant="h5" color="blue-gray" className="mt-1">
                                    Destino:
                                </Typography>
                            </div>
                            <div className="col-span-2 ... select-ui-data ">
                                <Select
                                    label="Seleccionar Destino"
                                    value={parametros.destino}
                                    className='select-bg-ui lg:!w-96'
                                    onChange={(val) => setParametros({...parametros, destino : val})}
                                >
                                    <Option value="proveedor">Proveedor</Option>
                                    <Option value="iglesia">Iglesia</Option>
                                    <Option value="pastor">Pastor</Option>
                                </Select>
                            </div>
                        </div> */}
                    <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                            <div>
                                <Typography variant="h5" color="blue-gray" className="mt-1">
                                    Tipo de Reporte:
                                </Typography>
                            </div>
                            <div className="col-span-2 ... select-ui-data lg:!w-96">
                                <Seleccionador  
                                    id='select-rubro' 
                                    vector={tiposReporte} 
                                    valor={parametros.tipo} 
                                    nombre="Todos"
                                    onchange={(e)=>setParametros({...parametros,tipo:e.target.value})} claves={{id:'id',nombre:'nombre'}}
                                />
                            </div>
                    </div>

                    <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                            <div>
                                <Typography variant="h5" color="blue-gray" className="mt-1">
                                    Intervalo de Tiempo:
                                </Typography>
                            </div>
                            <div className="col-span-2 ... select-ui-data lg:!w-96">
                                <select
                                    label="Seleccionar Intervalo"
                                    value={parametros.criterio}
                                    className='select-bg-ui w-full'
                                    onChange={(val) =>  handleCheckCriterio(val)}
                                >
                                    {/* <option value="tanda">Tanda</option> */}
                                    <option value="añofiscal">Año fiscal</option>
                                    <option value="mesaño">Mes/año</option>
                                    <option value="año">Año</option>
                                    <option value="periodo">Periodo</option>
                                    <option value="fecha">Fecha</option>
                                </select>

                                <div className='flex space-x-4 mt-4'>
                                    <div>
                                        <Typography variant="h5" color="blue-gray" className="mt-1">
                                            {nombreCriterio.current}
                                        </Typography>
                                    </div>
                                    <div>
                                        {parametros.criterio=="tanda" && <div className="flex f-row">
                                            <Seleccionador id="select-tanda" vector={tandas} valor={parametros.id_tanda} nombre="Todas" onchange={(e)=>setParametros({...parametros,id_tanda:e.target.value, id_año_fiscal: null})} claves={{id:'id_tanda',nombre:'nombre'}}/>
                                        </div>}

                                        {parametros.criterio=="añofiscal" && <div className="flex f-row">
                                            <Seleccionador id="select-añofiscal" vector={periodosFiscales} valor={parametros.id_año_fiscal} nombre="Todos" onchange={(e)=>setParametros({...parametros,id_año_fiscal:e.target.value})} claves={{id:'id_año_fiscal',nombre:'nombre'}}/>
                                        </div>}     

                                        {parametros.criterio=="mesaño" && <div className="flex f-row">
                                                    {<DatePicker selected={parametros.mesAño} 
                                                        onChange={date => setParametros({...parametros,mesAño:date, id_año_fiscal: null, fechaDesde:null, fechaHasta:null})} 
                                                        locale="es"
                                                        dateFormat="MM/yyyy"
                                                        id="select-mesaño"
                                                        className="select-bg-ui !h-9 w-full"
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                        showFourColumnMonthYearPicker
                                                    />}
                                        </div>}

                                        {parametros.criterio=="año" && <div className="flex f-row">
                                                    {<DatePicker selected={parametros.año} 
                                                        onChange={date => setParametros({...parametros,año:date, id_año_fiscal: null, fechaDesde:null, fechaHasta:null})} 
                                                        locale="es"
                                                        id="select-año"
                                                        className="select-bg-ui !h-9 w-full"
                                                        showYearPicker
                                                        dateFormat="yyyy"
                                                    />}
                                        </div>}

                                        {parametros.criterio=="periodo" && <div className="flex f-row">
                                                <div className="flex f-row">
                                                            <div className="flex f-col">
                                                                <span className="text-xsmall">Desde</span>
                                                                <DatePicker selected={parametros.fechaDesde} 
                                                                    onChange={date => setParametros({...parametros,fechaDesde:date, fecha: null, id_año_fiscal: null})} 
                                                                    locale="es"
                                                                    dateFormat="MM/yyyy"
                                                                    id="select-periodo-fdesde"
                                                                    showMonthYearPicker
                                                                    className="select-bg-ui !h-9 w-full"
                                                                    showFullMonthYearPicker
                                                                    showFourColumnMonthYearPicker/>
                                                            </div>
                                                            <div className="flex f-col ml-2">
                                                                <span className="text-xsmall">Hasta</span>
                                                                <DatePicker selected={parametros.fechaHasta} 
                                                                    onChange={date => setParametros({...parametros,fechaHasta:date, fecha: null, id_año_fiscal: null})} 
                                                                    locale="es"
                                                                    dateFormat="MM/yyyy"
                                                                    id="select-periodo-fhasta"
                                                                    className="select-bg-ui !h-9 w-full"
                                                                    showMonthYearPicker
                                                                    showFullMonthYearPicker
                                                                    showFourColumnMonthYearPicker/>
                                                            </div>
                                                </div>
                                        </div>}

                                        {parametros.criterio=="fecha" && <div className="flex f-row">
                                                <div className="flex f-row">
                                                        <DatePicker selected={parametros.fecha} 
                                                            id="select-fecha"
                                                            className="select-bg-ui !h-9"
                                                            onChange={date => setParametros({...parametros, fecha:date, fechaDesde:date, fechaHasta:date, id_año_fiscal: null})} 
                                                            locale="es"/>
                                                </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-2 mt-4 w-full'} justify-left`}>
                        <div>
                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Rubro:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador  
                                            id='select-rubro' 
                                            vector={rubros} 
                                            valor={parametros.rubro} 
                                            nombre="Todos"
                                            disabled={parametros.fdo_regiones} 
                                            onchange={(e)=>setParametros({...parametros,rubro:e.target.value}) } claves={{id:'id_rubro_ingreso',nombre:'nombre'}}
                                        />
                                    </div>   

                                </div>
                            </div>
                            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left`}>
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Solo fondos Regionales:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <Checkbox
                                        label=""
                                        ripple={false}
                                        name="fondosregionales" 
                                        checked={parametros.fdo_regiones}
                                        onClick={handleCheckFondoRegional}
                                        className='w-5 h-5'
                                    />  
                                </div>
                            </div>
                            <div 
                                data-group="gen" 
                                className={`hidden ${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Caja:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador id='select-caja' vector={cajas} valor={parametros.caja} nombre="Todos" onchange={(e)=>setParametros({...parametros,caja:e.target.value})} claves={{id:'id_caja',nombre:'nombre'}}/>
                                    </div>   

                                </div>
                            </div>
                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div className='mr-2'>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Pastores:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%] relative">
                                        <select
                                            label="Seleccionar Pastor"
                                            value={parametros.id_obrero}
                                            className='select-bg-ui w-full'
                                            onChange={(val) => buscarIglesiasPorObrero(val) }
                                        >
                                            <option value="">Seleccionar</option>
                                            {obreros.map(item => 
                                                <option value={item.id_obrero} key={item.id_obrero}>{item.nom_simple}</option>)
                                            }
                                        </select>
                                        <TrashIcon 
                                            width={20} 
                                            height={20} 
                                            className='cursor-pointer hover:opacity-85 mt-2 absolute top-0 right-[-25px] z-10' 
                                            onClick={() => setParametros({...parametros, id_iglesia: "", id_obrero: ""})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Forma de pago:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador  id='select-fpago' vector={formasPago} valor={parametros.formapago} nombre="Todos" onchange={(e)=>setParametros({...parametros,formapago:e.target.value})} claves={{id:'id_forma_pago',nombre:'nombre'}}/>
                                    </div>   

                                </div>
                            </div>
                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Tipo Recibo:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador  id='select-fpago' vector={tipoRecibos} valor={parametros.tipo_recibo} nombre="Todos" onchange={(e)=>setParametros({...parametros,tipo_recibo:e.target.value})} claves={{id:'tipo_recibo',nombre:'tipo_recibo'}}/>
                                    </div>   

                                </div>
                            </div>

                            
                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        N° de Comprobante:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <div className='flex flex-col w-full'>
                                            <Input
                                                name="comprobante-desde"
                                                id="comprobante-desde" 
                                                type="number" 
                                                value={parametros.nro_desde} 
                                                onChange={(e) => setParametros({ ...parametros, nro_desde: e.target.value })} 
                                                ref={inputBusquedaRef} 
                                                placeholder={'0'}
                                                className="input-ui-data w-full"
                                                labelProps={{
                                                    className: "before:content-none after:content-none",
                                                }}
                                            />
                                            <Input
                                                name="comprobante-hasta"
                                                id="comprobante-hasta" 
                                                type="number" 
                                                value={parametros.nro_hasta} 
                                                onChange={(e) => setParametros({ ...parametros, nro_hasta: e.target.value })} 
                                                ref={inputBusquedaRef} 
                                                placeholder={'0'}
                                                className="input-ui-data w-full"
                                                labelProps={{
                                                    className: "before:content-none after:content-none",
                                                }}
                                            />
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </div>

                        <div className='mt-0 lg:mt-[-20px]'>
                            <div className={`${esCelular ? 'mt-2 hidden' : 'grid grid-cols-3 mt-4 gap-4'} justify-left`}>
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48">
                                    </div>   
                                </div>
                            </div>

                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Provincia:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador  id='select-provincia' vector={provincias} valor={parametros.id_provincia} nombre="Todos" onchange={(e)=>setParametros({...parametros,id_provincia:e.target.value})} claves={{id:'id_provincia',nombre:'nombre'}}/>
                                    </div>   

                                </div>
                            </div>
                            {/* <div data-group="gen" className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left`}>
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Regiones:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador  id='select-provincia' vector={regiones} valor={parametros.id_region} nombre="Todos" onchange={(e)=>setParametros({...parametros,id_region:e.target.value})} claves={{id:'id_region',nombre:'nombre'}}/>
                                    </div>   

                                </div>
                            </div> */}
                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Terminales Usuarios:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador  id='select-terminales-users' vector={terminalesUsers} valor={parametros.usuario_alta} nombre="Todos" onchange={(e)=>setParametros({...parametros,usuario_alta:e.target.value})} claves={{id:'usuario_alta',nombre:'usuario_alta'}}/>
                                    </div>   

                                </div>
                            </div>

                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Iglesias:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <select
                                            label="Selecciona una Iglesia"
                                            value={parametros.id_iglesia}
                                            className='select-bg-ui w-full'
                                            onChange={(val) =>{ setParametros({...parametros, id_iglesia: val.target.value}) }}
                                        >
                                            <option value="">Seleccionar</option>
                                            {iglesias.map(item => 
                                                <option value={item.id_iglesia} key={item.id_iglesia}>{item.nombre}</option>)
                                            }
                                        </select>
                                    </div>   
                                </div>
                            </div>

                            <div 
                                data-group="dist" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4 gap-4'} justify-left ${!mostrarDist ? 'hidden' : ''}`}
                            >
                                <div>
                                    <Typography variant="h5" color="blue-gray" className="mt-1">
                                        Fondo de tesorería:
                                    </Typography>
                                </div>
                                <div className="col-span-2 ...">
                                    <div className="ml-2 mt-0 lg:ml-0 xl:ml-0 lg:mt-2 xl:mt-2 w-48 lg:w-[80%]">
                                        <Seleccionador id='select-fondo' vector={fondos} valor={parametros.id_fondo_tesoreria} nombre="Todos" onchange={(e)=>setParametros({...parametros,id_fondo_tesoreria:e.target.value})} claves={{id:'id_fondo_tesoreria',nombre:'nombre'}}/>
                                    </div>   

                                </div>
                            </div>
                            <div 
                                data-group="gen" 
                                className={`${esCelular ? 'mt-2' : 'grid grid-cols-3  mt-4 gap-4'} justify-left ${mostrarDist ? 'hidden' : ''}`}
                            >
                                    <div>
                                        <Typography variant="h5" color="blue-gray" className="mt-1">
                                            Orden:
                                        </Typography>
                                    </div>
                                    <div className="col-span-2 ...">
                                        <Checkbox
                                        label="Por obrero"
                                        ripple={false}
                                        name="checkorden" 
                                        checked={parametros.orden}
                                        onClick={handleCheckOrden}
                                        className='w-5 h-5'
                                        />
                                    </div>
                            </div>
                        </div>
                    </div>

                    {parametros.tipo=='resumen' && <div className={`${esCelular ? '' : 'grid grid-cols-3 justify-left mt-4'}`}>
                            <Typography variant="h5" color="blue-gray" className="mt-1">
                                Totales:
                            </Typography>
                            <div className="col-span-2 ... space-x-2">
                            <Checkbox
                                label="Reporte sin agrupar en meses"
                                ripple={false}
                                name="checknoagrupar" 
                                checked={parametros.no_agrupar}
                                onClick={handleCheckNoAgrupar}
                                className='w-5 h-5'
                                />
                            </div>           
                        </div>
                    }

                    {parametros.tipo=='cronologico' && <div className={`hidden ${esCelular ? '' : 'grid grid-cols-3 justify-left mt-4'}`}>
                            <Typography variant="h5" color="blue-gray" className="mt-1">
                                Totales:
                            </Typography>
                            <div className="col-span-2 ... space-x-2">
                            <Checkbox
                                label="Totales por caja"
                                ripple={false}
                                name="checkcaja" 
                                checked={parametros.total_caja}
                                onClick={handleCheckTotalCaja}
                                className='w-5 h-5'
                            />
                            <Checkbox
                                label="Totales diarios"
                                ripple={false}
                                name="checkcaja" 
                                checked={parametros.total_diario}
                                onClick={handleCheckTotalDiario}
                                className='w-5 h-5'
                            />
                            <Checkbox
                                label="Totales mensuales"
                                ripple={false}
                                name="checkcaja" 
                                checked={parametros.total_mensual}
                                onClick={handleCheckTotalMensual}
                                className='w-5 h-5'
                            />
                            <Checkbox
                                label="Totales por año"
                                ripple={false}
                                name="checkcaja" 
                                checked={parametros.total_año}
                                onClick={handleCheckTotalAño}
                                className='w-5 h-5'
                            />
                            </div>           
                        </div>
                       }


                    <div className={`hidden ${esCelular ? '' : 'grid grid-cols-3 justify-left'}`}>
                        <Typography variant="h5" color="blue-gray" className="mt-1">
                            Reporte:
                        </Typography>
                        <div className="col-span-2 ... space-x-2">
                            <Checkbox
                                label="Resumen"
                                ripple={false}
                                checked={parametros.tipo=='resumen'} 
                                id="tipo-resumen" 
                                name = "tipo" 
                                value="resumen" 
                                onClick={handleCheckTipo}
                                className='w-5 h-5'
                            />
                            <Checkbox
                                label="Cronológico"
                                ripple={false}
                                checked={parametros.tipo=='cronologico'} 
                                id = "tipo-crono" 
                                name="tipo" 
                                value="cronologico" 
                                onClick={handleCheckTipo}
                                className='w-5 h-5'
                            />
                        </div>           
                    </div>

                    <div className='flex justify-center'>
                        <Button variant="gradient" className="mt-4 w-48 flex justify-center" onClick={validarParametros} disabled={reporteEnEjecucion}>
                         {reporteEnEjecucion ? 
                             <Spinner className="h-4 w-4 mr-2" /> : 'Ejecutar'}   
                        </Button>
                    </div>
                    <Checkbox
                                ripple={false}
                                label='Ejecutar otra version de PDF (Provisorio en test)'
                                title='Ejecutar la version vieja'
                                checked={versionVieja} 
                                onClick={()=>setVersionVieja(!versionVieja)}
                                className='w-5 h-5'
                        />
            </div>
        </Card>
}

function Seleccionador({
    valor, 
    name, 
    onchange, 
    id, 
    noDefault, 
    nombre, 
    vector, 
    claves, 
    limpiar,
    disabled,
    classancho
  }){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    //recordar que un objeto puedo leerlo de 2 formas como vector o como objeto
    // es lo mismo usuario['nombre'] que usuario.nombre
    // aprovecho esta característica para hacer un seleccionador genérico y le paso
    // el nombre de la clave y el texto como un string para referenciarlo en notación vector
    return (            
        <div className="flex flex-col sel select-ui-data w-full">
            <select disabled={disabled} value={valor} name={name? name : ''} className='h-9' onChange ={onchange} id={id? id : null}>
                { noDefault ? null : <option value="*" key="1">{nombre}</option>}
                {vector.map(item=>
                        <option value={item[claves.id]} key={item[claves.id]}>
                            {item[claves.nombre]}
                        </option> 
                    )}
            </select>
            { valor!="*" && limpiar && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>}  
        </div>
        )
        
}   

function determinarClases(reporteEnEjecucion){
    return reporteEnEjecucion ? "items-center justify-center ml-auto mr-auto" : "flex f-col items-center justify-center ml-auto mr-auto"
}

function objetosSegunParametros(parametros,objeto){

    if (parametros.tipo=='cronologico'){
        switch(objeto){
            case 'api': return `api/reportes/reporteegresos/${masParametros(parametros)}`
            case 'titulo': return 'Egresos cronológicos'
            case 'titulos': return {col1:'Fecha',col2:'Proveedor',col3:'Comprobante',col4:'Descripcion',col5:'Monto'}
            case 'columnas' : return {col1:'Manejo las columnas en la funcion bodyTablaEgresosCronologicos'}
            case 'filtro' : return {campo1:'proveedor',campo2:'descripcion', campo3:'rubro', campo4:'caja',campo5:'fondo',campo6:'datos_bcrios',campo7:'monto_numerico'}
            case 'csv' : return item=>{return {fecha:transformarIso8601(item.fecha),proveedor:item.proveedor,tipo_comp:item.tipo_compr,nro_comprobante:item.nro_compr,descripcion:item.descripcion,monto:item.monto,caja:item.caja,rubro:item.rubro,fondo:item.fondo,datos_bancarios:item.datos_bcrios,tanda:item.tanda_desc}}
            case 'pdf' : return item => { return { 
                fecha: transformarIso8601(item.fecha), 
                proveedor: item.proveedor, 
                comprobante: `${item.tipo_compr}/${item.nro_compr}`, 
                descripcion: item.descripcion, 
                monto: item.monto, 
                caja: item.caja, 
                rubro: item.rubro,
                fondo:item.fondo,
                tanda:item.tanda_desc,
                datos_bancarios: item.datos_bcrios}
            }
            case 'excel' : return [{label:'Proveedor',value:'proveedor'},
                        {label:'Rubro',value:'rubro'},
                        {label:'Caja',value:'caja'},
                        {label:'Descripción',value:'descripcion'},
                        {label:'Datos bancarios',value:'datos_bcrios'},
                        {label:'Fondo',value:'fondo'},
                        {label:'TC',value:'tipo_compr'},
                        {label:'Comprobante',value:'nro_compr'},
                        {label:'Monto',value:'monto'},{label:'Monto plano',value:'monto_numerico'}]
            case 'arraypdf' : return [{ text: 'Fecha', style: 'tableHeader' }, 
            { text: 'Proveedor', style: 'tableHeader' }, 
            { text: 'Comprobante', style: 'tableHeader' }, 
            { text: 'Descripción', style: 'tableHeader' }, 
            { text: 'Monto', style: 'tableHeader' }, 
            { text: 'Caja', style: 'tableHeader' }, 
            { text: 'Rubro', style: 'tableHeader' },
            { text: 'Fondo', style: 'tableHeader' },
            { text: 'Tanda', style: 'tableHeader' },
            { text: 'Info Banco', style: 'tableHeader' }]
            case 'orientacion' : return 'landscape'
            case 'subtitulo' : return 'landscape'
            case 'clasesbody' : return {col1:'filas-lista-ec ti-reporte-xl',col2:'filas-lista-ec ti-reporte-xxl',col3:'filas-lista-ec ti-reporte-xl',col4:'filas-lista-ec ti-reporte-xxl',col5:'filas-lista-ec ti-reporte-xl text-right'}
            case 'claseshead' : return {col1:'filas-lista-em ti-reporte-xl',col2:'filas-lista-em ti-reporte-xxl',col3:'filas-lista-em ti-reporte-xl',col4:'filas-lista-em ti-reporte-xxl text-center',col5:'filas-lista-em ti-reporte-xl text-right'}
        }
    }else{
        switch(objeto){
            case 'api': return `api/reportes/resumenegresos/${masParametros(parametros)}`
            case 'titulo': return 'Ingresos integrales'
            case 'titulos': return {col1:'Rubro',col2:'Código',col3:'Mes',col4:'Año',col5:'Monto'}
            case 'columnas' : return {col1:'rubro',col2:'codigo_rubro',col3:'mes_largo',col4:'año',col5:'egreso'}
            case 'filtro' : return {campo1:'rubro',campo2:'codigo_rubro',campo3:'egreso_numerico'}
            case 'csv' : return item=>{return {rubro:item.rubro,codigo_rubro:item.codigo_rubro,egreso:item.egreso,periodo:`${item.mes_largo} ${item.año}`}}
            case 'pdf' : return item => { return { 
                rubro: item.rubro, 
                codigo_rubro: item.codigo_rubro, 
                año: item.año,
                mes:item.mes,
                periodo: `${item.mes_largo} ${item.año}`, 
                egreso: item.egreso}
            }
            case 'excel' : return [{label:'Rubro',value:'rubro'},{label:'Código',value:'codigo_rubro'},{label:'Egreso',value:'egreso'},{label:'Mes',value:'mes_largo'},{label:'Año',value:'año'}]
            case 'arraypdf' : return [{ text: 'Rubro', style: 'tableHeader' }, 
            { text: 'Cod. Rubro', style: 'tableHeader' }, 
            { text: 'Período', style: 'tableHeader' }, 
            { text: 'Egreso', style: 'tableHeader' }]
            case 'orientacion' : return 'portrait'
            case 'clasesbody' : return {col1:'',col2:'',col3:'',col4:'',col5:'text-right'}
            case 'claseshead' : return {col1:'',col2:'',col3:'',col4:'',col5:'text-right'}
        }
    }
}


function bodyTablaEgresosCronologicos (clasesbody){
    return item => {
        return (
                <tr  className="border-bottom-solid" key={uuidv4()}>
                    <td>
                                {transformarIso8601(item.fecha)} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                                <p title='Caja' className='text-small text-white bg-tomato'>{item.caja}</p>
                                <p title='Rubro' className='text-small'>{item.rubro}</p>
                                <p title='Fondo' className='text-small text-grey-dark'>{item.fondo}</p>
                    </td>
                    <td>
                        {item.proveedor} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                    </td>
                    <td>
                        {item.tipo_compr}-{item.nro_compr} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                    </td>
                    <td>
                         {item.descripcion} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                    </td>
                    <td className='text-right'>
                         {item.monto} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                    </td>                    
                </tr>
            )
        }
}

function bodyTablaEgresosCronologicosold (clasesbody){
    return item => {
        return (
            <tbody key={uuidv4()}>
                <tr  className="border-bottom-solid">
                    <td>
                        <span className={clasesbody.col1}>
                                {transformarIso8601(item.fecha)} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                        </span>         
                        <span className={clasesbody.col2}>{item.proveedor}</span>
                        <span className={clasesbody.col3}>{item.tipo_compr}-{item.nro_compr}</span>
                        <span className={clasesbody.col4}>{item.descripcion}</span>
                        <span className={clasesbody.col5}>{item.monto}</span>
                    </td>
                </tr>
            </tbody>
            )
        }
}

function masParametros(parametros){

    if (parametros.tipo=='cronologico'){
        switch(parametros.criterio){
            case 'tanda': return `*/*/*/*/${parametros.id_tanda}/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case 'añofiscal': return `*/*/*/${parametros.id_año_fiscal}/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case 'año': return `*/*/${parametros.año.getFullYear()}/*/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case 'mesaño': return `*/${parametros.mesAño.getMonth()+1}/${parametros.mesAño.getFullYear()}/*/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case 'periodo': return `*/*/*/*/*/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case 'fecha': return `${parametros.mesAño.getDate()}/${parametros.mesAño.getMonth()+1}/${parametros.mesAño.getFullYear()}/*/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
        }
    }else{
        switch(parametros.criterio){
            case 'tanda': return `*/*/*/${parametros.id_tanda}`
            case 'añofiscal': return `*/*/${parametros.id_año_fiscal}/*`
            case 'año': return `*/${parametros.año.getFullYear()}/*/*`
            case 'mesaño': return `${parametros.mesAño.getMonth()+1}/${parametros.mesAño.getFullYear()}/*/*`
        }
    }
}

function noNull(valor){
    if (valor){
        return valor
    }else{
        return '*'
    }
}

function convertirMesAñoAfechaDesde(fecha){
    
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha desde es el primer día del mes y año seleccionados
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

   // const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    

  // return `${mes < 10 ? `0${mes}` : mes}-01-${anio}`
   return `${anio}-${mes < 10 ? `0${mes}` : mes}-01`

}

function convertirMesAñoAfechaCompleta(fecha) {
    // El propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha se envía en el formato YYYY-MM-DD
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha);

    const dia = fecha_new.getDate();
    const mes = fecha_new.getMonth() + 1;
    const anio = fecha_new.getFullYear();

    return `${anio}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia}`;
}

function convertirMesAñoAfechaHasta(fecha){
        
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha hasta es el último día del mes y año seleccionados
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

    const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    
   const nueva_fecha =  `${anio}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia}`

    return obtenerFechaUltimoDiaMes(nueva_fecha,'YYYY-MM-DD','YYYY-MM-DD')
}

function Prueba({ref2}){
    const reff = useRef('')

    return <><select title={`dd ${reff.current.value}`} ref={ref2} name="" id="">
        <option value="55">asdfasdf</option>
        <option value="78">gggg</option>
    </select>
    </>
}

function Impresiones({datos,callbackCSV,titulo,callbackPDF,arrayPDF,orientacionPDF,descripcion,parametros}){

    const [iniciarImprimirPDF,setIniciarImprimirPDF] = useState(false)

    const datosCSV = datos.map(callbackCSV)
    
    const cerrarImpresionPDF = ()=>{
        setIniciarImprimirPDF(false)
    }

    const iniciarImpresionPDF = ()=>{
        if (iniciarImprimirPDF){
            setIniciarImprimirPDF(false)
        }else{
            setIniciarImprimirPDF(true)
        }
    }

    return <div className="flex f-col">
            {datos.length > 0 && <div className="centro-w100pc">
                <span onClick={iniciarImpresionPDF} className="cursor-pointer botonNc ml-6" >
                        <FontAwesomeIcon onClick={iniciarImpresionPDF} className="color-tomato" icon={faFilePdf}/> Archivo PDF
                </span> 
                <div className="relative w-100 pdfcontainer inline">
                    {iniciarImprimirPDF && <ImpresionPDF registrosAmostrar ={datos} cerrar={cerrarImpresionPDF} impresion={imprimirEcronologicos} callbackPDF={callbackPDF} arrayPDF={arrayPDF} orientacion={orientacionPDF} tituloDefault={descripcion} parametros={parametros}/>}
                </div>
                {/*<CSVLink
                        data={datosCSV}
                        filename={`${titulo}.csv`}
                        target="_blank"
                        className="tdec-none"
                        >
                         <span className="cursor-pointer botonNc ml-6 text-black tdec-none">
                            <FontAwesomeIcon className="color-tomato" icon={faFileDownload}/> Archivo CSV 
                        </span>
                </CSVLink>*/}
                <Excel datos={datos} descripcion={descripcion}/>
            </div>}
    </div>
}

function Excel({datos,descripcion}){

    const copia = [agregarFila(datos,descripcion),...datos]
    
   return '' /*<ExcelFile filename={`Egresos cronológicos`} element={
        <span className="cursor-pointer botonNc ml-6" >
            <FontAwesomeIcon className="color-tomato" icon={faFileExcel}/> Exportar a Excel
        </span> 
    }>
        <ExcelSheet data={copia} name="Egresos">
            <ExcelColumn label="Proveedor" value="proveedor"/>
            <ExcelColumn label="Monto" value="monto_numerico"/>
            <ExcelColumn label="Rubro" value="rubro"/>
            <ExcelColumn label="Caja" value="caja"/>
            <ExcelColumn label="Rubro" value="rubro"/>
            <ExcelColumn label="Descripción" value="descripcion"/>
            <ExcelColumn label="Datos bancarios" value="datos_bcrios"/>
            <ExcelColumn label="Fondo" value="fondo"/>
            <ExcelColumn label="TC" value="tipo_compr"/>
            <ExcelColumn label="Comprobante" value="nro_compr"/>
        </ExcelSheet>
</ExcelFile>*/
}

function agregarFila(datos,descripcion){
    return {...datos[0],
            proveedor:descripcion,
            monto_numerico:null,
            rubro:null,
            caja:null,
            rubro:null,
            descripcion:null,
            datos_bcrios:null,
            fondo:null,
            tipo_compr:null,
            nro_compr:null}
}

const reinicializarCriterios = (setParametros,parametros,nuevoCriterio,tandas,periodosFiscales)=>{


    setParametros({...parametros,
                      criterio : nuevoCriterio,
                      id_tanda:tandas[0].id_tanda,
                      id_año_fiscal:null,
                      fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
                      fechaHasta:new Date(),
                      fecha:new Date(),
                      mesAño:new Date(),
                      año:new Date(),
                    })
}

const cargarNombreCriterio = (nuevoCriterio,nombreCriterio)=>{

    switch(nuevoCriterio){
        case 'tanda' : nombreCriterio.current = 'Seleccione la tanda:';return
        case 'añofiscal' : nombreCriterio.current = 'Seleccione el año fiscal:';return
        case 'mesaño' : nombreCriterio.current = 'Seleccione el mes y año:';return
        case 'periodo' : nombreCriterio.current = 'Seleccione el período:' ;return
        case 'año' : nombreCriterio.current = 'Seleccione el año:';return
        case 'fecha' : nombreCriterio.current = 'Seleccione la fecha:';return
    }
}