import React, {useContext,useEffect,useState} from 'react'
import Eventos from '../componentes/Eventos'
import EventosList from '../componentes/EventosList'

export const Evento = ({usuario})=>{

    // return <Eventos usuario={usuario}/>
    return <EventosList usuario={usuario}/>

}

