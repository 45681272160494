import React, {useState, useEffect} from 'react';
import {Main,Axios,Swal,Loading,Modal,useModal,uuidv4} from '../../../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare, faUsers, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, IconButton, List, ListItem, Spinner, Tooltip, Typography } from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { DocumentIcon, PencilIcon, UserIcon } from '@heroicons/react/24/solid';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Divider } from '@material-ui/core';
import moment from 'moment';
import EventoForm from './EventoForm';
import EventoValorForm from './EventoValorForm';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import {fechaHoy,transformarFechaTramiteSimple,esMayor} from '../../../Helpers/fechas';
import {Cerrar} from './Cerrar';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import {formatearMontoMoneda} from '../../../Helpers/utilidades-globales';
import { DialogBody, DialogHeader, Dialog } from '@material-tailwind/react';
import { Grid } from "@material-ui/core";

export default function EventosValor({id_evento,cerrar}){

    const [datos,setDatos]=useState([]);
    const [datosEventos,setDatosEventos]=useState([]);
    const [buscando,setBuscando]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [idSeleccionado, setIdSeleccionado]=useState(null)
    const [contadorOperaciones,setContadorOperaciones]=useState(0);
    const [ejecutarAlta, setEjecutarAlta]=useState(false)
    const {toggle, isShowing } = useModal();
    const { esCelular } = useContextoGlobal();
    const [idEventoSeleccionado, setIdEventoSeleccionado]=useState(null)
    const [evento,setEvento] = useState(null);

    useEffect(()=>{
       
        setBuscando(true)

        const buscarAsambleas = async ()=>{

           try{
                const eventos = await Axios.get(`/api/tablasgenerales/eventos/eventosvalor/${id_evento}`)
                setDatosEventos(eventos.data)
                const {data} = await Axios.get(`api/tablasgenerales/evento/${id_evento}`) 
                setEvento({...data,fecha:transformarFechaTramiteSimple(data.fecha,'DD/MM/YYYY','YYYY-MM-DD')})

                setBuscando(false)
            }catch(err){
                setBuscando(false)
                setHuboError(true)
            }
        }
        
        buscarAsambleas()
    },[contadorOperaciones])

    useEffect(()=>{
        if(!isShowing){
           // si hubiera un modal
        }
    },[isShowing])

    const finalizarAltaOcopia= (valor)=>{

        setIdEventoSeleccionado(null)
        setEjecutarAlta(false)

       if (valor){
            setContadorOperaciones(contadorOperaciones+1)
        }
    }

    const handleOpen = () => {setIdEventoSeleccionado(null);setEjecutarAlta(false)}

    const seleccionar=(e,item)=>{

        if(!item){
            setIdEventoSeleccionado(null)
            return
        }

        setIdEventoSeleccionado(item.id_evento_valor)
    }

    if (huboError){
        return <Main center>
                        <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                            <span>Se produjo un error al cargar los datos para esta vista</span>
                        </div>
                </Main>
    }

    if (buscando){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
                    </div>
                </Main>
    };

    return(
        <div className='flex flex-col m-5 lg:m-auto xl-m-auto'>
            {datosEventos?.length==0 && 
                <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px', textAlign: 'center' }} >
                    <Grid item>
                        <Typography variant="h5">
                            No se encontraron archivos
                        </Typography>
                        <div className="flex xl:!justify-center">
                                <Crear setEjecutarAlta={setEjecutarAlta} cerrar={cerrar}/> 
                        </div>
                    </Grid>
                </Grid>}
                
            {datosEventos?.length>0 && <div className="h-full justify-center mt-4">
                <Typography variant="h4" className="flex">
                    Items del evento: {evento?.titulo}
                </Typography>
                <div className={!datosEventos?.length ? "flex xl:!justify-center mb-2" : "flex xl:!justify-end mb-2"}>
                    <Crear setEjecutarAlta={setEjecutarAlta} cerrar={cerrar}/>
                </div>

                { esCelular ? <TablaMobile datos={datosEventos} 
                        seleccionar={seleccionar}
                        textoBusqueda={''} 
                        setIdSeleccionado={setIdSeleccionado}
                        idSeleccionado={idSeleccionado}
                        finalizarAltaOcopia={finalizarAltaOcopia}
                        toggle={toggle}   /> 
                        : 
                        <ListaEventosValor tabla={datosEventos} 
                            seleccionar={seleccionar} 
                            textoBusqueda={''} 
                            idSeleccionado={idEventoSeleccionado}
                            finalizarAltaOcopia={finalizarAltaOcopia}
                            toggle={toggle}
                            /> 
                }  
            </div>} 

            {ejecutarAlta && <DialogCustom
                        handleOpen={handleOpen}
                        evento={evento?.titulo}
                        titulo="Cree un nuevo item"
                    >
                    <EventoValorForm id_evento={id_evento} detalle={evento?.titulo} finalizarAltaOcopia={finalizarAltaOcopia}/>
            </DialogCustom>}
            {idEventoSeleccionado && <DialogCustom
                        handleOpen={handleOpen}
                        evento={evento?.titulo}
                        titulo={`Modifique el item ${idEventoSeleccionado}`}
                    >
                    <EventoValorForm id_evento_valor={idEventoSeleccionado} detalle={evento?.titulo} id_evento={id_evento} finalizarAltaOcopia={finalizarAltaOcopia}/>
            </DialogCustom>}
        </div>
    )
}


function TablaMobile({datos,seleccionar,
                    textoBusqueda, 
                    idSeleccionado}){

if(!idSeleccionado){
    return <Card>
                <List> 
                    {datos.filter(
                        item=>item.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase())).map((item, index)=>
                    <>
                    <ListItem key={index} className="cursor-pointer">
                        <div className='f-col space-y-2'>
                            <p><span className='font-bold'>Nombre: </span>{item.titulo}</p>
                            <p><span className='font-bold'>Descripción: </span>{item.detalles}</p>
                            <p><span className='font-bold'>Fecha: </span>{item.fecha}</p>
                            <div className='flex space-x-2'>
                                <Tooltip content="Editar">
                                    <IconButton variant="text" onClick={(e)=>{seleccionar(e,item)}}>
                                        <PencilIcon className="h-4 w-4" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </ListItem>
                    <Divider />
                    </>
                    )}
                </List>
            </Card>
    }
}

function ListaEventosValor({tabla,
                    seleccionar,
                    textoBusqueda, 
                    idSeleccionado
                    }){

    if(!idSeleccionado){

    const TABLE_HEAD = ["Id","Detalle",  "Valor", "Activo", "Acciones"];

    return (
    <div>
        <Card className="h-full w-full overflow-scroll-none">
            <table className="w-full table-auto text-left">
                <thead>
                    <tr>
                        {TABLE_HEAD.map((head) => (
                        <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                        >
                            <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                            >
                            {head}
                            </Typography>
                        </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {tabla
                    .filter(
                        item=>item.detalle.toUpperCase().includes(textoBusqueda.toUpperCase()))
                    .map((item, index) => {
                    const isLast = index === tabla.length - 1;
                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
        
                    return (
                    <tr key={uuidv4()}>
                        <td className={classes}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {item.id_evento_valor}
                        </Typography>
                        </td>
                        <td className={classes}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {item.detalle}
                        </Typography>
                        </td>
                        {/* <td className={classes}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {item.detalles}
                        </Typography>
                        </td> */}
                        <td className={`${classes} text-right w-7`}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {formatearMontoMoneda(item.valor)}
                        </Typography>
                        </td>
                        <td className={`${classes} felx space-x-2 w-7`}>
                            {item.activo==1 && <CheckCircleIcon title='Activo' width={24} className="text-blue-200 ml-auto mr-auto"/>}
                            {item.activo==0 && <XCircleIcon  title='Inactivo' width={24} className="text-red-500 ml-auto mr-auto"/>}
                        </td>
                        <td className={`${classes} felx space-x-2`}>
                            <Tooltip content="Editar">
                                <IconButton variant="text" onClick={(e)=>{seleccionar(e,item)}}>
                                    <PencilIcon className="h-4 w-4" />
                                </IconButton>
                            </Tooltip>
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </Card>
    </div>
    )}
    // else{
    //    return <>
    //         <EventoForm id_evento={idSeleccionado} finalizarAltaOcopia={()=>seleccionar(null)}/>
    //    </>

    // }
}

const DialogCustom = ({handleOpen,titulo,evento,children})=>{
return <Dialog open={true} className="max-h-[90vh] overflow-y-scroll" handler={handleOpen}>
    <DialogHeader>
        <div className='flex flex-col'>
            <p>{evento}</p><p>{titulo}</p>
        </div>
    </DialogHeader>
    <DialogBody className="xl:p-10" >
        {children}
    </DialogBody>
</Dialog>
}

const Crear = ({setEjecutarAlta,cerrar})=>{
    return <div className="flex xl:!justify-end gap-3">
    <Button className="flex ml-2 items-center gap-3 bg-green-500" onClick={()=>setEjecutarAlta(true)}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-4 w-4"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
            />
        </svg>
        Crear
    </Button>
    <Button variant='text' onClick={cerrar}>
        Volver a eventos
    </Button>
</div>
}