import React, {useState, useEffect} from 'react';
import {Main,Axios,Swal,} from '../Helpers/importacionesComunes'
import { useForm,Controller  } from "react-hook-form";
import Calendario from './Calendario';
import InputFormulario from './InputFormulario';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimesCircle, faCircle, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DatePicker from "react-multi-date-picker";
import {hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import {Fecha} from '../componentes/MUI/Fecha';
import {fechaHoy,transformarFechaTramiteSimple,esMayor} from '../Helpers/fechas';
import {FormGroup,FormControlLabel,TextField,Box,CircularProgress} from "@material-ui/core";
import { MuiSelect } from './MUI/MuiSelect';
import { Button, Card, Checkbox, Input, Option, Select, Spinner, Typography } from '@material-tailwind/react';
import { useContextoGlobal } from '../Context/contextoGlobal';

export default function AsambleaForm({id_asamblea,finalizarAltaOcopia,usuario}){

    const [huboError,setHuboError]=useState(false)
    const [grabandoDatos,setGrabandoDatos]=useState(false)
    const [rangos,setRangos] = useState([])
    const [tipos,setTipos] = useState([])
    const [cargando,setCargando]=useState(false)
    const [asambleaRangosInicial,setAsambleaRangosInicial]=useState([])
    const [huboCambios,setHuboCambios] = useState(false)
    const [fechaAsamblea,setFechaAsamblea] = useState(fechaHoy('YYYY-MM-DD')) // el componente Fecha requiere el formato YYYY-MM-DD
    const [nombre,setNombre] = useState('')
    const [descripcion,setDescripcion] = useState('')
    const [idTipo,setIdTipo] = useState(null)
    const [rangosPermitidos,setRangosPermitidos] = useState(new Set());
    const {esCelular} = useContextoGlobal();

    useEffect(()=>{
        const buscarRangos = async ()=>{
            setCargando(true)
            try{
                const drangos = await Axios.get('api/tablasgenerales/rangos')
                const dtipos = await Axios.get('api/tablasgenerales/tiposasamblea')
                setRangos(drangos.data.sort((a,b)=>a.nombre.localeCompare(b.nombre)).filter(item=>item.id_rango>0 && item.id_rango<7))
                setTipos(dtipos.data.map(item=>{return {...item,id:item.id_tipo_asamblea}}))
                setCargando(false)
            }catch(err){
                setCargando(false)
                alert(err)
            }
        }
        buscarRangos()
    },[])

    useEffect(()=>{

        const buscarAsamblea = async ()=>{
            setCargando(true)
            try{
                const {data} = await Axios.get(`api/tablasgenerales/asamblea/${id_asamblea}`) 
                setNombre(data.nombre)
                setDescripcion(data.descripcion)
                setIdTipo(data.id_tipo_asamblea)
                setFechaAsamblea(transformarFechaTramiteSimple(data.fecha,'DD/MM/YYYY','YYYY-MM-DD'))
                inicializarRangosPermitidos(data.rangos)
                setCargando(false)
            }catch(err){
                setHuboError(true)
                setCargando(false)
            }            
        }

        if(rangos.length>0 && tipos.length>0 && id_asamblea){
            buscarAsamblea()
        }
    },[rangos,tipos])



    const handleCheck = (e)=>{

        const setAux = new Set(rangosPermitidos);

        if(e.target.checked){
            setAux.add(e.target.name)
        }else{
            setAux.delete(e.target.name)
        }

        setRangosPermitidos(setAux)
    }

    const inicializarRangosPermitidos = (rangosInicial)=>{
        const array2 = rangos.filter(item=>rangosInicial.some(i=>item.id_rango==i)).map(item=>item.iniciales)
        const setAux = new Set(array2);
        setRangosPermitidos(setAux)
    }

    const iniciarGrabar = (values)=>{
        let texto;
        let textoConfirmacion;
    
        if(!nombre){
            alert('Falta ingresar el nombre de la asamblea')
            return
        }

        if(!descripcion){
            alert('Falta ingresar la descripción de la asamblea')
            return
        }

        if(!fechaAsamblea){
            alert('Falta ingresar el la fecha de la asamblea')
            return
        }

        if(esMayor(fechaHoy('YYYY-MM-DD'),fechaAsamblea)){
            alert('La fecha de la asamblea debe ser igual o posterior al día de la fecha')
            return
        }

        if(!idTipo){
            alert('Falta identificar el tipo de asamblea')
            return
        }

        if (rangosPermitidos.size==0){
            alert('Falta asociar al menos un rango a esta asamblea')
            return
        }

        if (id_asamblea){
            texto = `¿Confirma la modificación de la asamblea ${id_asamblea}?`
            textoConfirmacion = 'Si, modificar la asamblea'
        }else{
            texto = `¿Confirma la creación de la nueva asamblea?`
            textoConfirmacion = 'Si, crear la asamblea'
        }
    
        Swal.fire({
            text:texto,
            showCancelButton:true,
            confirButtonText:textoConfirmacion,
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    grabarDatos(values)
                    .then((id_interno)=>{
                        grabarRangos(id_interno)
                    })
                    .catch(err=>{
                        alert(err)
                    })
                }else{
                    console.log("Se canceló la modificación o creación de la asamblea")
                }
            }
        )
    }

    const grabarDatos = async (values)=>{
        let resultado;

        const objetoAgrabar = { 
                    nombre: nombre,
                    descripcion: descripcion,
                    fecha: transformarFechaTramiteSimple(fechaAsamblea),
                    tipo:idTipo
                }
    
        setGrabandoDatos(true)
    
        try{
            if (id_asamblea){
                resultado= await Axios.put(`/api/tablasgenerales/asamblea/${id_asamblea}`,objetoAgrabar)
                return id_asamblea
            }else{
                resultado= await Axios.post(`/api/tablasgenerales/asamblea`,objetoAgrabar)
                return resultado.data.id_asamblea
            }
        }catch(err){    
    
            let mensaje_html_error;
    
            console.log('err.response.status',err.response.status)
    
            if(err.response.data.message){
                mensaje_html_error = `<p>Se produjo un error al grabar los datos de la asamblea</p><p>${err.response.data.message}</p>`
            }else{
                mensaje_html_error = `<p>Se produjo un error al grabar los datos de la asamblea </p><p>${err.response.data.message}</p>`
            }
    
            Swal.fire({
                html:mensaje_html_error,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })   
        
            setGrabandoDatos(false)
        }
    }

    const grabarRangos = async (id_asamblea_int)=>{

        let mensaje_html = `<p>Los datos se grabaron exitosamente</p>`
        
        const rangosaux = rangos.filter(rango=>Array.from(rangosPermitidos).some(rangopermitido=>rangopermitido==rango.iniciales)).map(item=>item.id_rango)
        const rangosno = rangos.filter(rango=>!(Array.from(rangosPermitidos).some(rangopermitido=>rangopermitido==rango.iniciales))).map(item=>item.id_rango)

        const objetoAgrabar = {rangos:rangosaux,rangosno}

        try{

            const resultado = await Axios.post(`/api/tablasgenerales/asamblea/rangos/${id_asamblea_int}`,objetoAgrabar)
            setGrabandoDatos(false)

            Swal.fire({
                html:mensaje_html,
                timer:600,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })   

            finalizarAltaOcopia(true)

        }catch(err){
            let mensaje_html_error;
    
                mensaje_html_error = `<p>Se produjo un error al grabar los rangos de la asamblea</p><p>${err.response.data.message}</p>`
    
                Swal.fire({
                    html:mensaje_html_error,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                })   
            
                setGrabandoDatos(false)
        }
    }

    if (huboError){
        return <Main center>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <span>Se produjo un error al cargar los datos para esta vista</span>
                    </div>
            </Main>
    }

    if(cargando){
        return <Main> 
                    <div className={`flex flex-col justify-center items-center h-[calc(100vh-10rem)] ${esCelular ? 'ml-40' : ''}`}>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando...</span>
                    </div>
                </Main>
     }

    return <Card className='p-2 lg:p-10 xl:p-10 flex flex-col !justify-center items-center mt-4'>
                <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                    {id_asamblea ? `Modifique una asamblea` : `Cree una nueva asamblea`}
                </Typography>
                <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Nombre:<span className='c-obl'></span>
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setNombre(e.target.value.toUpperCase())
                        }}
                        value={nombre || ''} 
                        type='text' 
                        id='nombre'
                        name='nombre'
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        autoFocus={id_asamblea ? false : true}
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>

                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Descripción:<span className='c-obl'></span>
                    </Typography>
                    <Input
                        value={descripcion || ''} 
                        onChange={(e)=>{
                            setDescripcion(e.target.value.toUpperCase())
                         }}
                        type='text' 
                        id='descripcion'
                        name='descripcion'
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div> 

                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Fecha:<span className='c-obl'></span>
                    </Typography>
                    <Input
                        value={fechaAsamblea || ''} 
                        onChange={(e)=>setFechaAsamblea(e.target.value)}
                        type='date' 
                        id='descripcion'
                        name='descripcion'
                        color='black'
                        className="!h-10 !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div> 

                <div className="flex flex-col w-full mt-4 select-ui-data">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Tipo de asamblea:<span className='c-obl'></span>
                    </Typography>
                    <Select 
                        label="Seleccione un tipo de asamblea" 
                        value={idTipo}	
                        onChange={(e)=>setIdTipo(e)}
                        className=""
                    >
                        {tipos.map((item, index) => (
                            <Option key={item.id} value={item.id}>
                                    {item.nombre}
                            </Option>
                        ))}
                    </Select>
                </div> 

                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Rangos habilitados:<span className='c-obl'></span>
                    </Typography>

                    <FormGroup style={{display:'flex',flexDirection:'row'}}>
                        {rangos.map(item=> <FormControlLabel control= {
                                    <Checkbox 
                                        checked={Array.from(rangosPermitidos).some(rangop=>rangop==item.iniciales)} 
                                        title={item.nombre} 
                                        onChange={handleCheck} name={item.iniciales}/>} 
                                        label={item.iniciales}
                                    />)}
                    </FormGroup> 
                </div>

                <div className='items-center flex justify-center space-x-2 mt-4'>
                    <Button onClick={iniciarGrabar} type="submit">
                        Grabar
                    </Button>
                    <Button color='red' onClick={()=>finalizarAltaOcopia(null)}>
                        cancelar
                    </Button>
                </div>
    </Card>  
}
