import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box,IconButton,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {useEventos} from '../hooks/useEventos'
import Axios from 'axios'
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@material-tailwind/react';
import Main from '../../../componentes/Main';

export const NuevoLinkTest = ({id_evento})=>{
    const [nuevoToken,setNuevoToken] = useState(null)
    const [eventoActivo,setEventoActivo] = useState(null)
    const [mensaje,setMensaje] = useState(null)

    useEffect(()=>{
        obtenerEstadoActual()
    },[])

    const generarLink = async ()=>{
        try{
            const {data} = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/tokentest/${id_evento}`)
            setNuevoToken(data)   
        }catch(err){
            setMensaje(err)
        }

    }

    const obtenerEstadoActual = async ()=>{
        try{
            const {data} = await Axios.get(`api/tablasgenerales/evento/${id_evento}`) 
            setEventoActivo(data.activo)
        }catch(err){
            setMensaje(err)
        }

    }

    //sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}

    if (mensaje){
        return <Main center>
                <span>{mensaje}</span>
            </Main>
    }

    if (eventoActivo){
        return <Main center>
                <span>El evento se encuentra activo y no requiere un link para testing. <br />Puede ser visualizado directamente en los eventos publicados</span>
            </Main>
    }

    return <><div className='m-auto mb-4 w-64 lg:w-full xl:w-full' >
        {nuevoToken && <div> 
            <Alert severity="info" variant='outlined'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Copiar texto={nuevoToken?.token} />      
                    <p>{nuevoToken?.token}</p>
                </div>
            </Alert>
            <h3>Duración del token: {nuevoToken?.expiresIn}</h3>
        </div>}   
    </div>
    <Button className='w-full' onClick={generarLink}>Generar un nuevo link temporal de test</Button>
    </>
}

const Copiar = ({texto})=>{
    return  <IconButton title='Copiar' onClick={() => {navigator.clipboard.writeText(texto)}} color="primary" aria-label="upload picture" component="label">
    <ContentCopyIcon />
</IconButton>
}