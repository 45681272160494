import React, {useContext,useEffect,useState} from 'react'
import {LinearProgress, CircularProgress, Box,TextField,IconButton,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel, Typography} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CardGiftcard } from '@mui/icons-material';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
//import {useEventos} from '../hooks/useEventos'
import Excel from '../../../componentes/Excel'
import { spawn } from 'child_process';
import {Resumen} from './Resumen'
import {ResumenCompleto} from './ResumenCompleto'
import {Ingreso} from './Ingreso'
import { eventoContext } from '../contexto/EventoContext'
import {hacerfocoEnPrimerInput} from '../../../Helpers/utilidades-globales'

import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, Input, Spinner } from '@material-tailwind/react';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const columnas =   [
{label:'Nro',value:'id_evento_inscr'},
{label:'Nombre',value:'nombre'},
{label:'Documento',value:'nro_documento'},
{label:'Edad',value:'edad'},
{label:'Sexo',value:'sexo'},
{label:'Item pagado',value:'detalle'},
{label:'Provincia',value:'provincia'},
{label:'Email',value:'email'},
{label:'Tipo',value:'categoria'},
{label:'Rango',value:'rango'},
{label:'Región',value:'region'},
{label:'Fecha alta',value:'f_alta'},
{label:'Estado pago',value:'cod_interno'},
{label:'Número mercadopago',value:'mp_id'},
{label:'Importe',value:'importe'},
{label:'Código inscripción',value:'codigo_web'},
{label:'Fecha ingreso',value:'f_ingreso'},
{label:'Hora ingreso',value:'h_ingreso'},
{label:'Usuario ingreso',value:'usuario_ingreso'},
{label:'Cupón',value:'nro_cupon'},
{label:'Recibo UAD',value:'nro_comprobante'},

]

export const Listado = ()=>{

    const [inscriptoSeleccionado,setInscriptoSeleccionado]=useState(null)
   // const {inscriptos,cargando,refrescar,codInscripto} = useEventos()
    const {inscriptos,cargando,refrescar,codInscripto,mensaje,handleInputCodInscripto,id} = useContext(eventoContext)
    const [registrosMostrados,setRegistrosMostrados] = useState(30)
    const [nro_documento,setNro_documento] = useState('')
    const [datoBusqueda,setDatoBusqueda] = useState(null)
    const [verIngresados,setVerIngresados]=useState(false)
    const [verResumen,setVerResumen]=useState(false)
    const { esCelular } = useContextoGlobal();

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    useEffect(()=>{
        //refrescar(9) // llama a cargarinscriptos
        refrescar(id) // llama a cargarinscriptos
    },[])

    useEffect(()=>{
        setDatoBusqueda(null)
    },[nro_documento,inscriptos])

    const handleKeyPress = (e)=>{
      if(e.key === 'Enter'){
          setDatoBusqueda(nro_documento)
      }
   }
   
   const procesarCodigoWeb = (codigo)=>{
      if(codigo){
          handleInputCodInscripto(codigo.trim())
          setDatoBusqueda(null)
          setInscriptoSeleccionado(null)
          setNro_documento('')
          setTimeout(() => {
            hacerfocoEnPrimerInput('ub-codinscripto')
          }, 10);
       }

   }

    if(cargando){
       return <Box sx={{display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        width:'100px',
                        marginLeft:'auto',
                        marginRight:'auto',
                        minHeight:'200px' }}> 
            <div className='flex flex-col justify-center items-center mt-20'>
              <Spinner className="h-12 w-12" />
              <span className="cargando font-semibold text-[15px]">Cargando...</span>
            </div>
      </Box>
    }

    const TABLE_HEAD = ["Inscripto","Provincia","Status Pago","Código Alta","Fecha Insc","Item","Tipo","Categoría","Rango","Región"];
   
    return <Card className={esCelular ? 'p-2 mt-4' : 'p-10 flex flex-col items-center mt-4'}> 
              <div className={esCelular? 'grid grid-rows-1 space-y-2' : 'flex space-x-4'}>
                <Button onClick={()=>refrescar(id)} color='primary' variant='gradient'>Refrescar</Button>
                <Button onClick={()=>setVerIngresados(true)} color='primary' variant='gradient'>Ver ingresados</Button>
                <Button onClick={()=>setVerResumen(true)} color='primary' variant='gradient'>Ver resumen</Button>
              </div>
              <div className={esCelular? 'space-y-2 mt-2' : 'flex space-x-4 mt-4'}>
                  <div title="Registrados" className="a-psts-c5">
                                              <p className="text-small w-80 text-center" >Inscriptos</p>
                                              <p className="fw-700">{inscriptos.inscriptos.length}</p>
                  </div> 
                  <div title="Registrados" className="a-psts-c5">
                                              <p className="text-small w-80 text-center" >Ingresados</p>
                                              <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.presente==1).length}</p>
                  </div>
              </div>

              <Ingreso/>

              {mensaje!='' && <h1>{mensaje}</h1>}
    
              {/*<Resumen inscriptos = {inscriptos}/>*/}
    
              {inscriptos.inscriptos.length > 0 && <Box sx={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                          <Excel  datos={inscriptos.inscriptos} 
                          descripcion='Inscriptos'
                          columnas={columnas}
                          titulo='Inscriptos Convención' />

                            <div className='w-full lg:w-96 xl:w-96'>
                                <Input  
                                    value={nro_documento} 
                                    onKeyPress={handleKeyPress} 
                                    onInput={()=>handleInputCodInscripto('')} 
                                    onChange={(e)=>setNro_documento(e.target.value)} 
                                    id="ub-codinscripto"
                                    label="DNI o Descripción"
                                    color='black'
                                    icon={<MagnifyingGlassIcon className="h-5 w-5 cursor-pointer" onClick={()=>setDatoBusqueda(nro_documento)}/>} 
                                />
                            </div>
                          {datoBusqueda && <p>Resultados encontrados: {inscriptos.inscriptos.filter((item,index)=> datoBusqueda?.trim().length > 3 && (item.nro_documento==datoBusqueda || item.nombre.includes(datoBusqueda?.toUpperCase()))).length}</p>}           
              </Box>}

        {inscriptos.inscriptos.filter((item,index)=> datoBusqueda?.trim().length > 3 && (item.nro_documento==datoBusqueda || item.nombre.includes(datoBusqueda?.toUpperCase()))).length > 0 &&  <>
           <Card className="h-full w-full overflow-scroll-none">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {inscriptos.inscriptos.filter((item, index) => datoBusqueda?.trim().length > 3 && (item.nro_documento == datoBusqueda || item.nombre.includes(datoBusqueda?.toUpperCase())))
                  .map((item, index) => {
                    const isLast = index === inscriptos.inscriptos.length - 1;
                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                    return (
                      <><tr key={`lfe-${item.id}`} className='cursor-pointer hover:bg-blue-gray-100/20'
                        onClick={() => setInscriptoSeleccionado(item.id_evento_inscr)}>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.nombre}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.provincia}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.cod_interno}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.codigo_web}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.f_alta}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.detalle}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.tarif_matr == 1 ? 'MAT' : 'IND'}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.categoria}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.rango}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.region}
                          </Typography>
                        </td>
                      </tr>
                        <tr className='bg-blue-gray-100/70'>
                          <td colSpan="10" className="p-4 border-b border-blue-gray-50">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              Ingresado por: {item.usuario_ingreso} el : {item.f_ingreso} a las {item.h_ingreso}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </Card>
          </>
        }
        <Dialogos open={inscriptoSeleccionado>0} 
                        titulo='Inscripto' 
                        procesarCancelar = {()=>setInscriptoSeleccionado(null)}
                      >
                  <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <DetalleInscripto handleInputCodInscripto={procesarCodigoWeb} inscriptoSeleccionado={inscriptoSeleccionado} inscriptos = {inscriptos}/>
                  </Box>
            </Dialogos>
            <Dialogos open={verIngresados} 
                      titulo='Ingresados' 
                      fullscreen={false}
                      maxWidth='lg'
                      procesarCancelar = {()=>setVerIngresados(false)}
                    >
                  {inscriptos.inscriptos.filter(item=>item.presente).length > 0 && <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                          <h5>Ingresados: {inscriptos.inscriptos.filter(item=>item.presente).length}</h5>
                        <Ingresados ingresados={inscriptos.inscriptos.filter(item=>item.presente)} setInscriptoSeleccionado={setInscriptoSeleccionado}/>
                  </Box>}
            </Dialogos>
            <Dialogos open={verResumen} 
                        titulo='Resumen inscriptos' 
                        fullscreen={false}
                        maxWidth={'xl'}
                        procesarCancelar = {()=>setVerResumen(false)}
                      >
                        <ResumenCompleto id_evento={id}/>
            </Dialogos>
  </Card>
}

const noHayFiltros = (filtros)=>{
  return Object.keys(filtros).length==0
}

const Detalle = ({datos,regiones})=>{
  return  <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
      {datos.map(item=><div style={{border:'solid 1px gray',justifyContent:'center'}}><span style={{margin:'3px', background:'#3f51b5',color:'white',padding:'3px',borderRadius:'5px'}}>
        {item[0]!='' ? regiones ? `Reg-${item[0]}` : item[0] : 'S/R'}
      </span><span>{item[1]}</span></div> )}
  </div>
}

const Detalle2 = ({datos,regiones})=>{

  return   <Box sx={{border:'solidx 1px gray',padding:'0.5rem',margin:'0.5rem'}}>
      {datos.map(item=>
                    <Typography variant='body2'>{item[0]!='' ? regiones ? `Región ${item[0]}: ` : `${item[0]}: ` : 'S/R: '} <b>{item[1]}</b></Typography>
        )}
  </Box>

  return  <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
      {datos.map(item=><div style={{}}><span>
        {item[0]!='' ? regiones ? `Región ${item[0]}` : item[0] : 'S/R'}
      </span><span>{item[1]}</span></div> )}
  </div>
}

const MasDetalles = ({inscripto}) =>{
  return <div>
      <table>
          <tbody>
              {Object.entries(inscripto).map(item=><tr>
                  <td>{item[0]}</td>
                  <td>{item[1]}</td>
              </tr>)}
          </tbody>
      </table>
  </div>
}

const DetalleInscripto = ({inscriptoSeleccionado,inscriptos,handleInputCodInscripto})=>{
  const [inscripto,setInscripto] = useState(null)
  const [verMas,setVerMas] = useState(false)

  useEffect(()=>{
      const inscriptoEncontrado = inscriptos.inscriptos.filter(item=>item.id_evento_inscr==inscriptoSeleccionado)
      setInscripto(inscriptoEncontrado[0])

  },[inscriptoSeleccionado])

  return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{display:'flex', justifyContent:'end',width:'100%'}}>
          <Button onClick={()=>setVerMas(true)}>Ver más datos</Button>
      </Box>
        <table>
            <tr>
                <td>Código QR</td>
                <td><h3 className="cursor-pointer bg-steelblue text-center" onClick={()=>handleInputCodInscripto(inscripto?.codigo_web)}>{inscripto?.codigo_web}</h3></td>
            </tr>
            <tr>
                <td>Nombre</td>
                <td>{inscripto?.nombre}</td>
            </tr>
            <tr>
                <td>DNI</td>
                <td>{inscripto?.nro_documento}</td>
            </tr>
            <tr>
                <td>Edad</td>
                <td>{inscripto?.edad} años</td>
            </tr>
            <tr>
                <td>Sexo</td>
                <td>{inscripto?.sexo=='F' ? 'MUJER' : 'HOMBRE'}</td>
            </tr>
          <tr>
                <td>E-mail</td>
                <td>{inscripto?.email}</td>
            </tr>
          <tr>
                <td>Fecha de inscripción</td>
                <td>{inscripto?.f_alta}</td>
            </tr>
          <tr>
                <td>Número mercadopago</td>
                <td>{inscripto?.mp_id}</td>
          </tr>
          <tr>
                <td>Status</td>
                <td>{inscripto?.cod_interno}</td>
            </tr>
          <tr>
                <td>Cupón</td>
                <td>{inscripto?.nro_cupon}</td>
            </tr>
          <tr>
              <td>Recibo UAD</td>
              <td>{inscripto?.nro_comprobante}</td>
          </tr>
</table>
<Dialogos open={verMas} 
                    titulo={`Más detalles del inscripto`} 
                    procesarCancelar = {()=>setVerMas(false)}
                   >
                   <MasDetalles inscripto={inscripto}/> 
        </Dialogos>
</Box>

}

const Ingresados = ({ingresados,setInscriptoSeleccionado})=>{

  const TABLE_HEAD = ["Nro","Inscripto","Provincia","Código Alta","Item","Categoría","Rango"];

  return   <><Card className="h-full w-full overflow-scroll-none">
    <table className="w-full min-w-max table-auto text-left">
      <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th
              key={head}
              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {ingresados
          .map((item, index) => {
            const isLast = index === ingresados.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <><tr key={`lfe-${item.id}`} className='cursor-pointer hover:bg-blue-gray-100/20'
                onClick={() => setInscriptoSeleccionado(item.id_evento_inscr)}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {index + 1}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item.nombre}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item.provincia}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item.codigo_web}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item.detalle}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item.categoria}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item.rango}
                  </Typography>
                </td>
              </tr>
              <tr className='bg-blue-gray-100/70'>
                  <td colSpan="10" className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      Ingresado por: {item.usuario_ingreso} el : {item.f_ingreso} a las {item.h_ingreso}
                    </Typography>
                  </td>
              </tr>
              </>
            );
          })}
      </tbody>
    </table>
  </Card>
</>
}