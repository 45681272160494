import React, {useContext,useEffect,useState} from 'react'
import { Box } from "@material-ui/core";
import {Listado} from '../componentes/Listado'
import {Cerrar} from './Cerrar';
import {EventoContextProvider} from '../contexto/EventoContext'
import {NuevoLink} from '../componentes/NuevoLink'
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Spinner, Typography,Button } from '@material-tailwind/react';
import {Main,Axios} from '../../../Helpers/importacionesComunes'
import {transformarFechaTramiteSimple} from '../../../Helpers/fechas';

export const GestionEvento = ({id: id_evento,cerrar})=>{
    const [nuevoLink,setNuevoLink] = useState(false)
    const { esCelular } = useContextoGlobal();
    const [huboError,setHuboError]=useState(false)
    const [cargando,setCargando]=useState(false)
    const [evento,setEvento] = useState(null);

    useEffect(()=>{

        const buscarEvento = async ()=>{
            setCargando(true)
            try{
                const {data} = await Axios.get(`api/tablasgenerales/evento/${id_evento}`) 
                setEvento({...data,fecha:transformarFechaTramiteSimple(data.fecha,'DD/MM/YYYY','YYYY-MM-DD')})
                setCargando(false)
            }catch(err){
                setHuboError(true)
                setCargando(false)
            }            
        }


        if(id_evento) buscarEvento()

        },[id_evento])

    if (huboError){
        return <Main center>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <span>Se produjo un error al cargar los datos para esta vista</span>
                    </div>
            </Main>
    }

    if(cargando){
        return <Main> 
                    <div className={`flex flex-col justify-center items-center h-[calc(100vh-10rem)] ${esCelular ? 'ml-40' : ''}`}>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando...</span>
                    </div>
                </Main>
     }

    return <EventoContextProvider id={id_evento}> 
                <div className={esCelular ? 'flex flex-col justify-center ... ml-8 md:ml-52' : 'flex flex-col items-center justify-center'}> 
                <div className='flex flex-col mb-4'>
                        <Typography variant="h4" color="black" className="flex justify-center items-center">
                            {evento?.titulo} ID:{evento?.id_evento}
                        </Typography>  
                        <Cerrar cerrar={cerrar}/>
                </div>
                    <Listado/>
                </div>
        </EventoContextProvider> 
}

