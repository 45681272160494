import React, { useEffect, useState} from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemButton from '@mui/material/ListItemButton';
import {hacerScroll} from '../../../Helpers/utilidades-globales' 
import { Button, Card } from '@material-tailwind/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { IconButton, Tooltip} from '@material-tailwind/react';
import { CheckCircleIcon, XCircleIcon,CalendarDaysIcon,NoSymbolIcon,EyeIcon,LinkIcon,FolderOpenIcon } from '@heroicons/react/24/solid';

export const ListadoCelular= ({datos, verSeleccionado})=>{

const [maximo,setMaximo] = useState(50)

useEffect(()=>{
    setMaximo(50)
},[datos])

useEffect(()=>{
    if(maximo>50){
        hacerScroll(`id-index-${maximo-50}`)
    }
},[maximo])

const mostrarMas = ()=>{
    setMaximo(maximo+50)
}

const formatKey = (key) => {
    return key
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

return <>
        <h4 className='mb-1'>{resumen(datos,maximo)}</h4>
        <Card>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {datos
                .filter((item,index)=>index<=maximo)
                .map((item,index)=>(
                    <>
                        <ListItem key={item.id_obrero}>
                            <ListItemIcon sx={{minWidth:'0'}} id={`id-index-${index}`}>
                                <CircleIcon  />
                            </ListItemIcon>
                            <ListItemButton>
                                <ListItemText
                                    primary={item.nombre}
                                    secondary={
                                        <>
                                            <p className='flex'>ID: {item.id_evento}</p>
                                            <p className='flex'>Titulo: {item.titulo}</p>
                                            <p className='flex'>Fecha: {item.fecha}</p>
                                            <p className='flex'>Activo: {item.activo==1 ? 'Sí' : 'No'}</p>
                                            <p className='flex'>Vigente: {item.vigente==1 ? 'Sí' : 'No'}</p>
                                        </>
                                    }
                                    />
                            </ListItemButton>
                        </ListItem>
                        <div className="flex items-center gap-5">
                            <Tooltip content="Editar la cabecera del evento">
                                <IconButton variant="text" size="sm" onClick={(e) => verSeleccionado(e,item,'cabecera')}>
                                    <PencilIcon className="h-4 w-4 text-gray-900" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip content="Editar los Items del evento">
                                <IconButton variant="text" className={Number(item.items)== 0 ? 'text-red-700' : ''} size="sm" onClick={(e) => verSeleccionado(e,item,'items')}>
                                    { Number(item.items)} items
                                </IconButton>
                            </Tooltip>
                            <Tooltip content="Gestionar ingresos">
                                <IconButton variant="text" size="sm" onClick={(e) => verSeleccionado(e,item,'gestion')}>
                                    <FolderOpenIcon className="h-4 w-4 text-gray-900" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip content="Gestionar links de colaboradores">
                                <IconButton variant="text" size="sm" onClick={(e) => verSeleccionado(e,item,'link')}>
                                    <LinkIcon className="h-4 w-4 text-gray-900" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip content="Crear un link de test">
                                <IconButton variant="text" size="sm" onClick={(e) => verSeleccionado(e,item,'linktest')}>
                                    <EyeIcon className="h-4 w-4 text-gray-900" />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <Divider variant="inset" component="li" className='relative right-4' />
                    </>
                ))}
            </List>
        </Card>
        <div className='grid grid-cols-2 mt-2'>
            <p className='mt-2'>{resumen(datos,maximo)}</p>
            {hayMasDatos(datos, maximo) && <Button variant="text" className="flex items-center gap-2" onClick={mostrarMas}>
                Mostrar mas
                <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-5 w-5"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
                </svg>
            </Button>
            }
        </div>
        </>
}

const hayMasDatos = (datos,maximo) =>{
    if(datos.length>maximo){
        return true
    }
    return false
}

const resumen = (datos,maximo)=>{
return datos.length < maximo ? datos.length == 1 ? '1 resultado': `${datos.length} resultados` : `${maximo} resultados de ${datos.length}`

}

