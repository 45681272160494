import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {_imagenLogo} from '../../imagenesBase64/logo'
import Swal from 'sweetalert2';
import {transformarIso8601,fechaActual} from '../../Helpers/fechas';
import {formatearMontoMoneda} from '../../Helpers/utilidades-globales'

const bkmonto = '';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const allMonths = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

export function imprimir(datos, nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion,parametros){
    try{

        Swal.fire({
            html: 'Buscando...',
            timer: 600,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        }).then(data=>{
            generarDocumento(datos, nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion ? orientacion : 'portrait',parametros)
        })
    }catch(err){

            const mensaje_html = `<p>Hubo un error al generar el documento</p><p>${err}</p>`

            Swal.fire({
                html:mensaje_html,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })
        }
}

function generarDocumento(datos, nombrePDF, tituloPDF, callbackMap, arrayCampos, orientacion = 'portrait', parametros) {
    const allMonths = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  
    const agrupado = {};
  
    datos.forEach(item => {
      const fp = item.fp || 'Sin Forma de Pago';
      const rubro = item.rubro || 'Sin Rubro';
      const año = item.año;
      const mes = item.mes;
  
      if (!agrupado[fp]) agrupado[fp] = {};
      if (!agrupado[fp][rubro]) agrupado[fp][rubro] = {};
      if (!agrupado[fp][rubro][año]) agrupado[fp][rubro][año] = {};
      if (!agrupado[fp][rubro][año][mes]) agrupado[fp][rubro][año][mes] = [];
  
      agrupado[fp][rubro][año][mes].push({
        ...item,
        monto_fp: parseFloat(item.monto_fp || item.monto || 0)
      });
    });
  
    const cuerpo_del_reporte = [];
    let totalGeneral = 0;
  
    for (const fp in agrupado) {
      cuerpo_del_reporte.push({
        text: fp,
        bold: true,
        fontSize: 12,
        margin: [0, 16, 0, 6],
        decoration: 'underline'
      });
  
      let totalFp = 0;
  
      for (const rubro in agrupado[fp]) {
        cuerpo_del_reporte.push({
          text: rubro,
          bold: true,
          fontSize: 10,
          margin: [0, 6, 0, 4],
          decoration: 'underline'
        });
  
        let totalRubro = 0;
  
        for (const año in agrupado[fp][rubro]) {
          for (const mes in agrupado[fp][rubro][año]) {
            const registros = agrupado[fp][rubro][año][mes];
  
            cuerpo_del_reporte.push({
              text: `Año ${año} - Mes ${allMonths[mes - 1]}`,
              bold: true,
              fontSize: 9,
              margin: [0, 4, 0, 2]
            });
  
            let totalMes = 0;
  
            registros.forEach(item => {
              totalMes += item.monto_fp;
              totalRubro += item.monto_fp;
              totalFp += item.monto_fp;
              totalGeneral += item.monto_fp;
  
              cuerpo_del_reporte.push({
                layout: 'noBorders',
                style: 'estilo_tabla',
                table: {
                  widths: ['*', '*', 100],
                  body: [
                    [
                      { text: item.comprobante, bold: true },
                      { text: item.contribuyente, bold: true },
                      { text: formatearMontoMoneda(item.monto_fp), alignment: 'right', bold: true }
                    ],
                    [
                      { text: transformarIso8601(item.fecha), colSpan: 2 }, {}, { text: '' }
                    ],
                    [
                      { text: item.detalle || '', colSpan: 2 }, {}, { text: '' }
                    ],
                    [
                      { text: item.fp_rc || '', colSpan: 2 }, {}, { text: '' }
                    ],
                    [
                      { text: `Titular: ${item.titular || ''}`, colSpan: 2 }, {}, { text: '' }
                    ]
                  ]
                }
              });
  
              cuerpo_del_reporte.push({
                canvas: [{
                  type: 'line',
                  x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5,
                  lineWidth: 0.5,
                  dash: { length: 1, space: 2 },
                  lineColor: 'black'
                }]
              });
            });
  
            // Total del mes
            cuerpo_del_reporte.push({
              columns: [
                { width: '*', text: `TOTAL ${allMonths[mes - 1]} ${año}`, bold: true },
                {
                  width: 120,
                  table: {
                    body: [[{ text: formatearMontoMoneda(totalMes), alignment: 'right', bold: true }]]
                  },
                  layout: 'noBorders'
                }
              ],
              margin: [0, 6, 0, 10]
            });
          }
        }
  
        // Total por rubro
        cuerpo_del_reporte.push({
          columns: [
            { width: '*', text: `TOTAL ${rubro.toUpperCase()}`, bold: true },
            {
              width: 120,
              table: {
                body: [[{ text: formatearMontoMoneda(totalRubro), alignment: 'right', bold: true }]]
              },
              layout: 'noBorders'
            }
          ],
          margin: [0, 6, 0, 4]
        });
  
        // Línea divisoria por rubro
        cuerpo_del_reporte.push({
          canvas: [{
            type: 'line',
            x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5,
            lineWidth: 1,
            lineColor: 'black'
          }],
          margin: [0, 6, 0, 12]
        });
      }
  
      // Total por forma de pago
      cuerpo_del_reporte.push({
        columns: [
          { width: '*', text: `TOTAL ${fp}`, bold: true, fontSize: 11 },
          {
            width: 120,
            table: {
              body: [[{ text: formatearMontoMoneda(totalFp), alignment: 'right', bold: true }]]
            },
            layout: 'noBorders'
          }
        ],
        margin: [0, 6, 0, 4]
      });
  
      // Línea divisoria por forma de pago
      cuerpo_del_reporte.push({
        canvas: [{
          type: 'line',
          x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5,
          lineWidth: 1,
          lineColor: 'black'
        }],
        margin: [0, 6, 0, 12]
      });
    }
  
    // Total general
    cuerpo_del_reporte.push({
      columns: [
        { width: '*', text: 'TOTAL GENERAL', bold: true, fontSize: 12 },
        {
          width: 120,
          table: {
            body: [[{ text: formatearMontoMoneda(totalGeneral), alignment: 'right', bold: true }]]
          },
          layout: 'noBorders'
        }
      ],
      margin: [0, 10, 0, 0]
    });
  
    const docDefinition = {
      pageOrientation: orientacion,
      pageMargins: [25, 110, 25, 50],
      header: function(currentPage, pageCount, pageSize) {
        if (currentPage > 1) {
          return [
            { text: tituloPDF, alignment: 'left', margin: [20, 20, 0, 50] },
            {
              width: pageSize.width,
              canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1, lineColor: 'black' }]
            }
          ];
        } else {
          return [
            {
              margin: [10, 30],
              columns: [
                { image: _imagenLogo, width: 50, alignment: 'left' },
                { text: 'Unión de las Asambleas de Dios - Ingresos integrales - Formas de pago y sus rubros', margin: [20, 0] }
              ]
            },
            { text: tituloPDF, alignment: 'left', margin: [10, 0, 0, 0] },
            {
              width: pageSize.width,
              canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1, lineColor: 'black' }]
            }
          ];
        }
      },
      footer: function(currentPage, pageCount) {
        return {
          margin: [0, 30, 30, 0],
          table: {
            widths: ['*', '*'],
            body: [
              [{ text: fechaActual('DD/MM/YYYY'), alignment: 'right', fontSize: 8 }, { text: 'Página ' + currentPage + ' de ' + pageCount, alignment: 'right', fontSize: 8 }]
            ]
          },
          layout: 'noBorders'
        };
      },
      content: cuerpo_del_reporte,
      styles: {
        estilo_tabla: {
          fontSize: 8,
          characterSpacing: -0.3,
          margin: [0, 2, 0, 0]
        }
      }
    };
  
    if (nombrePDF !== "") {
      pdfMake.createPdf(docDefinition).download(nombrePDF);
    } else {
      pdfMake.createPdf(docDefinition).open();
    }
  }
  

/*function generarDocumento(datos,nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion,parametros) {

    console.log("datos", datos);
    console.log("parametros", parametros);
    const copia_datos = datos.map((item,index,vector)=>{
        if(index==0){
            return {...item,imprimir:item.mes_largo}
        }else if (index > 0 && item.mes!=vector[index-1].mes){
             return {...item,imprimir:true}
        }else{
            return {...item,imprimir:false}
        }
    })
    var cuerpo_del_reporte = 

            copia_datos.map((item,index,vector)=>{
                return [
            parametros.no_agrupar ? '' : imprimirTotalMes(item,index,vector) ,
            parametros.no_agrupar ? '' : imprimirTotalAño(item,index,vector) ,                    
            parametros.no_agrupar ? '' : imprimirTotalMesCabecera(item,index,vector)  ,                  
            {stack: [ // encierro todo el contenido que deseo envolver en un objeto {stack:[]} y le asigno el atributo unbreakable:true para que todo lo que esté encerrado en ese stack no se rompa en páginas diferentes
                   { layout: 'noBorders',
                   style:'estilo_tabla',
                    table:{
                        widths: ['*', '*', 100],
                        body: [
                            [
                                { text: `${item.comprobante}`, bold: true },
                                { text: `${item.contribuyente}`, bold: true },
                                { text: formatearMontoMoneda(item.monto_fp || item.monto || 0), alignment: 'right', bold: true }
                            ],
                            [
                                { text: transformarIso8601(item.fecha), colSpan: 2 }, {},
                                { text: '' }
                            ],
                            [
                                { text: item.detalle, colSpan: 2 }, {},
                                { text: '' }
                            ],
                            [
                                { text: item.fp_rc || item.fp || '', colSpan: 2 }, {},
                                { text: '' }
                            ],
                            [
                                { text: `Titular: ${item.titular || ''}`, colSpan: 2 }, {},
                                { text: '' }
                            ],
                        ]
                    }
                },
                {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 0.5,dash: {length: 1, space: 2},lineColor: 'black' }]},
            ],unbreakable: true},
            index==vector.length-1 && parametros.no_agrupar==false ?  imprimirTotalMesFinal(item,index,vector) : '',
            index==vector.length-1 && parametros.no_agrupar==false ? imprimirTotalAñoFinal(item,index,vector) : '',
            index==vector.length-1 ? imprimirTotalGeneral(item,index,vector) : ''            
            ]})

    var docDefinition = {
        pageOrientation: 'portrait',//orientacion,
        pageMargins: [25, 110, 25, 50], // el margen de 100 para el top es necesario para que la imagen de la cabecera no se solape con el contenido
        header: function(currentPage, pageCount, pageSize) {
            // you can apply any logic and return any valid pdfmake element
        
            if (currentPage>1){
                return [
                    { text: tituloPDF, alignment: 'left' ,margin:[20,20,0,50] },
                    {
                        width: pageSize.width,
                        canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1,lineColor: 'black' }],
                    } 
                  ]
            }else{
                return  [
                    { margin:[10,30],
                        columns:[
                            {
                            image: _imagenLogo,
                            width: 50,
                            alignment: 'left',
                        },
                        {text:'Unión de las Asambleas de Dios - Ingresos integrales',margin:[20,0]}
                        ]   
                    },
                    {text:tituloPDF,alignment: 'left',margin:[10,0,0,0]},
                    {
                        width: pageSize.width,
                        canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1,lineColor: 'black' }],
                    }    
                ]
            }
          },
        footer: function (currentPage, pageCount, pageSize) { 
            return {
                margin: [0, 30, 30, 0], //el margin inferior de la página (pageMargins) tiene que ser tal que sea consistente con el margen superior del footer, si no el footer desaparece
                table: {
                    
                    widths: ['*','*'],
                    body: [
                        [{text:fechaActual('DD/MM/YYYY'),alignment: 'right',fontSize: 8},{ text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'right', fontSize: 8 }]
                    ]
                },
                layout: 'noBorders'
                
                    } 
         },
        content: [
            /*{
                image: _imagenLogo,
                width: 70,
                alignment: 'center',
            },
            {
                text: `Test`, margin: tituloPDF==""? [0, 0] : [2,20], style: 'center'
            },*/
        /*],
        styles: {
            header: {
                alignment: 'right'
            },
            center: {
                alignment: 'center',
                decoration: 'underline'

            },
            estilo_columna:{
                fontSize: 8,
                characterSpacing:-0.3
            },
            estilo_tabla:{
                fontSize: 8,
                characterSpacing:-0.3,
                margin: [0, 2, 0, 0]
            },
            anotherStyle: {
                fontSize: 15,
                bold: true,
            },
            body: {
                lineHeight: 1.5
            },
            tableHeader: {
                bold:true,
                fontSize:12
            }
        },
        images: {
            building: 'data:/Content/img/logo1.jpg'
        }
    };

    var dd = {
        content: [
            'First paragraph',
            'Another paragraph, this time a little bit longer to make sure, this line will be divided into at least two lines'
        ]
        
    }

    if (nombrePDF!=""){
        pdfMake.createPdf(docDefinition).download(nombrePDF);
    }else{
        pdfMake.createPdf(docDefinition).open()
    }
}*/

function fechaDelDia(){
    let fecha;

    const fecha_aux  = new Date()
    const dia = fecha_aux.getDate() < 10 ? `0${fecha_aux.getDate()}` : fecha_aux.getDate();
    let mes = fecha_aux.getMonth() + 1 ;
    mes = mes < 10 ? `0${mes}` : mes;
    fecha = `${dia}/${mes}/${fecha_aux.getUTCFullYear()}`

    return fecha;
}

function imprimirTotalDiario(item,index,vector){
    //esta función analiza si corresponde imprimir un total
    // evalúa si cambia de fecha preguntando si la fecha del registro actual
    // es diferente a la fecha del registro anterior
    // si es diferente calcula el total de todos los registros que tengan
    // la misma fecha del último registro
    // Si es la misma fecha el registro actual que el anterior no imprime total
    // si el vector tiene solo 1 registro imprime el total directamente tomando el monto tal como está en ese único registro
    // si el vector tiene 2 filas o mas evalúa la fecha cuando el indice es mayor a 0
    // si el indice es cero no imprime porque no tiene contra qué comparar
    // No comparamos con el item.fecha sino que antes de comparar la transformamos a un formato DD/MM/YYYY
    // el campo fecha viene en un formato que contiene horas minutos y segundos por eso
    // si no se convierte el formato aunque tenga la misma fecha daría que son diferentes fechas
    // por otro lado el campo monto es un string que tiene formato moneda
    // por eso la totalización se hace sobre el campo monto_numerico

    if(vector.length>1){
        if (index>0){
            if (transformarIso8601(vector[index-1].fecha)==transformarIso8601(item.fecha)){
                return {text:''}
            }else{
                const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(vector[index-1].fecha)).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
//                const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                const total_formateado = formatearMontoMoneda(total)
                const fecha = new Date(vector[index-1].fecha);
                //return {text:`Total fecha ${transformarIso8601(vector[index-1].fecha)}${total_formateado}`}
                return 	{
                    fontSize: 10,
                    bold:true,
                    margin:[0,10,0,10],
                    columns: [
                        {
                            width: 50,
                            text: 'TOTAL'
                        },
                        {
                            width: 60,
                            text: `Año  ${fecha.getFullYear()}`
                        },
                        {
                            width: 60,
                            text: `Mes  ${fecha.getMonth() + 1}`
                        },
                        {
                            width: 60,
                            text: `Día  ${fecha.getDate()}`
                        },
                        {
                            width: 220,
                            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 220, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                        },
                        {
                            width: 80,
                            background:bkmonto,alignment:'right',
                            table: {
                                widths:[80],
                                body: [
                                    [{text:total_formateado,alignment:'right'}],
                                ]
                            }
                        },
                    ]
                }
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
        const fecha = new Date(item.fecha);
        return {stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 60,
                    text: `Mes  ${fecha.getMonth() + 1}`
                },
                {
                    width: 60,
                    text: `Día  ${fecha.getDate()}`
                },
                {
                    width: 220,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 220, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 80,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[80],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true}
    }else{
        return {text:''}
    }

}


function imprimirTotalDiarioFinal(item,index,vector){
        const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(item.fecha)).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
        const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
       // return {text:`Total fecha ${transformarIso8601(vector[index-1].fecha)}${total_formateado}`}
        const fecha = new Date(item.fecha);
        return {stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 60,
                    text: `Mes  ${fecha.getMonth() + 1}`
                },
                {
                    width: 60,
                    text: `Día  ${fecha.getDate()}`
                },
                {
                    width: 240,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 240, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 80,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[80],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true }   
    }





function imprimirTotalCaja(item,index,vector){
    //esta función analiza si corresponde imprimir un total
    // evalúa si cambia de fecha preguntando si la fecha del registro actual
    // es diferente a la fecha del registro anterior
    // si es diferente calcula el total de todos los registros que tengan
    // la misma fecha del último registro
    // Si es la misma fecha el registro actual que el anterior no imprime total
    // si el vector tiene solo 1 registro imprime el total directamente tomando el monto tal como está en ese único registro
    // si el vector tiene 2 filas o mas evalúa la fecha cuando el indice es mayor a 0
    // si el indice es cero no imprime porque no tiene contra qué comparar
    // No comparamos con el item.fecha sino que antes de comparar la transformamos a un formato DD/MM/YYYY
    // el campo fecha viene en un formato que contiene horas minutos y segundos por eso
    // si no se convierte el formato aunque tenga la misma fecha daría que son diferentes fechas
    // por otro lado el campo monto es un string que tiene formato moneda
    // por eso la totalización se hace sobre el campo monto_numerico

    if(vector.length>1){
        if (index>0){
            if (transformarIso8601(vector[index-1].fecha)==transformarIso8601(item.fecha)){
                if (vector[index-1].caja==item.caja){
                    return {text:''}
                }else{
                    const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(vector[index-1].fecha) && i.caja==vector[index-1].caja).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
//                    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    const total_formateado = formatearMontoMoneda(total)
                    const fecha = new Date(vector[index-1].fecha);
//                    return {text:`Total caja ${vector[index-1].caja}${total_formateado}`}
                    return {stack:[{
                        fontSize: 10,
                        bold:true,
                        margin:[0,10,0,10],
                        columns: [
                            {
                                width: 50,
                                text: 'TOTAL'
                            },
                            {
                                width: 60,
                                text: `Año  ${fecha.getFullYear()}`
                            },
                            {
                                width: 60,
                                text: `Mes  ${fecha.getMonth() + 1}`
                            },
                            {
                                width: 60,
                                text: `Día  ${fecha.getDate()}`
                            },
                            {
                                width: 100,
                                text: `${vector[index-1].caja}`
                            },
                            {
                                width: 120,
                                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 120, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                            },
                            {
                                width: 80,
                                background:bkmonto,alignment:'right',
                                table: {
                                    widths:[80],
                                    body: [
                                        [{text:total_formateado,alignment:'right'}],
                                    ]
                                }
                            },
                        ]
                    }],unbreakable:true}              

                
                }
            }else{
                // si hubo cambio de fecha siempre calculo la caja
                    const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(vector[index-1].fecha) && i.caja==vector[index-1].caja).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
//                    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    const total_formateado = formatearMontoMoneda(total)
                    //return {text:`Total caja ${vector[index-1].caja}${total_formateado}`}
                    const fecha = new Date(vector[index-1].fecha);

                    return 	{stack:[{
                        fontSize: 10,
                        bold:true,
                        margin:[0,10,0,10],
                        columns: [
                            {
                                width: 50,
                                text: 'TOTAL'
                            },
                            {
                                width: 60,
                                text: `Año  ${fecha.getFullYear()}`
                            },
                            {
                                width: 60,
                                text: `Mes  ${fecha.getMonth() + 1}`
                            },
                            {
                                width: 60,
                                text: `Día  ${fecha.getDate()}`
                            },
                            {
                                width: 100,
                                text: `${vector[index-1].caja}`
                            },
                            {
                                width: 120,
                                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 120, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                            },
                            {
                                width: 80,
                                background:bkmonto,alignment:'right',
                                table: {
                                    widths:[60],
                                    body: [
                                        [{text:total_formateado,alignment:'right'}],
                                    ]
                                }
                            },
                        ]
                    }],unbreakable:true}
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
        const fecha = new Date(item.fecha);

        return 	{stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 60,
                    text: `Mes  ${fecha.getMonth() + 1}`
                },
                {
                    width: 60,
                    text: `Día  ${fecha.getDate()}`
                },
                {
                    width: 100,
                    text: `${vector[index-1].caja}`
                },
                {
                    width: 120,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 120, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 80,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[80],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true}
    }else{
        return {text:''}
    }

}

function imprimirTotalMes(item,index,vector){
    if(vector.length>1){
        if (index>0){
            if (vector[index-1].mes==item.mes){
                return {text:''}
            }else{
                const total = vector.filter(i=>i.mes==vector[index-1].mes).reduce((ac,valor)=>{return ac = ac + valor.monto_fp},0)
//                const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                const total_formateado = formatearMontoMoneda(total)
                // return {text:`Total mes ${transformarIso8601(vector[index-1].fecha,'MM')} ${total_formateado}`}
                return {stack:[{
                    fontSize: 10,
                    bold:true,
                    margin:[0,10,0,10],
                    columns: [
                        {
                            width: 50,
                            text: 'TOTAL'
                        },
                        {
                            width: 60,
                            text: `Año  ${item.año}`
                        },
                        {
                            width: 60,
                            text: `Mes  ${allMonths[item.mes - 1]}`
                        },
                        {
                            width: 280,
                            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 280, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                        },
                        {
                            width: 80,
                            background:bkmonto,alignment:'right',
                            table: {
                                widths:[80],
                                body: [
                                    [{text:total_formateado,alignment:'right'}],
                                ]
                            }
                        },
                    ]
                }],unbreakable:true}
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
        return {stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${item.año}`
                },
                {
                    width: 60,
                    text: `Mes  ${allMonths[item.mes - 1]}`
                },
                {
                    width: 280,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 280, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 80,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[80],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true}
    }else{
        return {text:''}
    }

}

function imprimirTotalMesCabecera(item,index,vector){

    if(item.imprimir){
        return {stack:[{
            fontSize: 10,
            bold:true,
            columns: [
                {
                    width: 60,
                    text: `Año  ${item.año}`
                },
                {
                    width: 200,
                    text: `Mes  ${allMonths[item.mes - 1]}`
                },
            ]
        },
        {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 0.5,dash: {length: 1, space: 2},lineColor: 'black' }]}
    ],unbreakable:true}}

}

function imprimirTotalAño(item,index,vector){

    if(vector.length>1){
        if (index>0){
            if (vector[index-1].año==item.año){
                return {text:''}
            }else{
                const total = vector.filter(i=>i.año==vector[index-1].año).reduce((ac,valor)=>{return ac = ac + valor.monto_fp},0)
//                const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                const total_formateado = formatearMontoMoneda(total)
                //return {text:`Total año ${transformarIso8601(vector[index-1].fecha,'YYYY')} ${total_formateado}`}

                return {stack:[{
                    fontSize: 10,
                    bold:true,
                    margin:[0,10,0,10],
                    columns: [
                        {
                            width: 50,
                            text: 'TOTAL'
                        },
                        {
                            width: 60,
                            text: `Año  ${item.año}`
                        },
                        {
                            width: 320,
                            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 320, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                        },
                        {
                            width: 80,
                            background:bkmonto,alignment:'right',
                            table: {
                                widths:[80],
                                body: [
                                    [{text:total_formateado,alignment:'right'}],
                                ]
                            }
                        },
                    ]
                }],unbreakable:true}
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
    
        return {stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${item.año}`
                },
                {
                    width: 340,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 340, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 80,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[80],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true}
    }else{
        return {text:''}
    }
}

function imprimirTotalCajaFinal(item,index,vector){
    const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(item.fecha) && i.caja==item.caja).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
//    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const total_formateado = formatearMontoMoneda(total)
    // return {text:`Total caja ${vector[index-1].caja}${total_formateado}`}

    const fecha = new Date(item.fecha);
                        return 	{
                            fontSize: 10,
                            bold:true,
                            margin:[0,10,0,10],
                            columns: [
                                {
                                    width: 50,
                                    text: 'TOTAL'
                                },
                                {
                                    width: 60,
                                    text: `Año  ${fecha.getFullYear()}`
                                },
                                {
                                    width: 60,
                                    text: `Mes  ${fecha.getMonth() + 1}`
                                },
                                {
                                    width: 60,
                                    text: `Día  ${fecha.getDate()}`
                                },
                                {
                                    width: 100,
                                    text: `${vector[index-1].caja}`
                                },
                                {
                                    width: 140,
                                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 140, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                                },
                                {
                                    width: 80,
                                    background:bkmonto,alignment:'right',
                                    table: {
                                        widths:[80],
                                        body: [
                                            [{text:total_formateado,alignment:'right'}],
                                        ]
                                    }
                                },
                            ]
                        }

}

function imprimirTotalMesFinal(item,index,vector){
    const total = vector.filter(i=>i.mes==item.mes).reduce((ac,valor)=>{return ac = ac + valor.monto_fp},0)
//    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const total_formateado = formatearMontoMoneda(total)
    //return {text:`Total mes ${transformarIso8601(item.fecha,'MM')} ${total_formateado}`}
    
     return 	{
         fontSize: 10,
         bold:true,
         margin:[0,10,0,10],
         columns: [
             {
                 width: 50,
                 text: 'TOTAL'
             },
             {
                 width: 60,
                 text: `Año  ${item.año}`
             },
             {
                 width: 60,
                 text: `Mes  ${allMonths[item.mes - 1]}`
             },
             {
                 width: 280,
                 canvas: [{ type: 'line', x1: 0, y1: 5, x2: 280, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
             {
                 width: 80,
                 background:bkmonto,alignment:'right',
                 table: {
                    widths:[80],
                    body: [
                        [{text:total_formateado,alignment:'right'}],
                    ]
                }
             },
         ]
     }
}

function imprimirTotalAñoFinal(item,index,vector){
    const total = vector.filter(i=>i.año==item.año).reduce((ac,valor)=>{return ac = ac + valor.monto_fp},0)
   // const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const total_formateado = formatearMontoMoneda(total)
    //return {text:`Total año ${transformarIso8601(item.fecha,'YYYY')} ${total_formateado}`}
    
    return {stack:[{
        fontSize: 10,
        bold:true,
        margin:[0,10,0,10],
        columns: [
            {
                width: 50,
                text: 'TOTAL'
            },
            {
                width: 60,
                text: `Año  ${item.año}`
            },
            {
                width: 320,
                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 320, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
            },
            {
                width: 80,
                background:bkmonto,alignment:'right',
                table: {
                    widths:[80],
                    body: [
                        [{text:total_formateado,alignment:'right'}],
                    ]
                }
            },
        ]
    }],unbreakable:true}
}

function imprimirTotalGeneral(item,index,vector){
    const total = vector.reduce((ac,valor)=>{return ac = ac + valor.monto_fp},0)
   // const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const total_formateado = formatearMontoMoneda(total)
    
   // return {text:`Total: ${total_formateado}`}

    return 	{
        fontSize: 10,
        bold:true,
        margin:[0,10,0,10],
        columns: [
            {
                width: 50,
                text: 'TOTAL'
            },
            {
                width: 380,
                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 380, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
            },
           /* {
                width: 100,
                text: ``
            },
            {
                width: 100,
                text: ``
            },
            {
                width: 100,
                text: ``
            },*/
            {
                width: 80,
                background:bkmonto,alignment:'right',
                table: {
                    widths:[80],
                    body: [
                        [{text:total_formateado,alignment:'right'}],
                    ]
                }
            },
        ]
    }
}

