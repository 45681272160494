import React, { useState, useEffect } from 'react';
import Main from '../../../componentes/Main';
import Axios from 'axios';
import { Grid } from "@material-ui/core";
import { Spinner, Textarea , Card, Typography, Input, IconButton, Tooltip, DialogFooter, Button, DialogBody, DialogHeader, Dialog,Checkbox } from '@material-tailwind/react';
import { IdentificationIcon, MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import { CheckCircleIcon, XCircleIcon,CalendarDaysIcon,NoSymbolIcon,EyeIcon,LinkIcon,FolderOpenIcon } from '@heroicons/react/24/solid';
import {Box} from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import EventoForm from './EventoForm';
import { NuevoLink } from './NuevoLink';
import { NuevoLinkTest } from './NuevoLinkTest';
import { GestionEvento } from './GestionEvento';
import EventosValor from './EventosValor';
import {Dialogos} from '../../../componentes/MUI/Dialogos';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { ListadoCelular } from './ListadoCelular';

import DOMPurify from 'dompurify';

export default function EventosList({usuario}) {

    const [eventosList, setEventosList] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [idEventoSeleccionado, setIdEventoSeleccionado]=useState(null)
    const [contadorOperaciones,setContadorOperaciones]=useState(0);
    const [ejecutarAlta, setEjecutarAlta]=useState(false);
    const { esCelular } = useContextoGlobal();

    useEffect(() => {
        obtenerArchivos()
    }, [contadorOperaciones])

    useEffect(() => {
        if (nameSearch.trim() === '') {
            setEventosList(eventosList);
        } else {
            const listaFiltrada = eventosList.filter(item =>
                item.titulo.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setEventosList(listaFiltrada.length > 0 ? listaFiltrada : eventosList);
        }
    }, [nameSearch, eventosList]);

    const finalizarAltaOcopia= (valor)=>{

        setIdEventoSeleccionado(null)
        setEjecutarAlta(false)
 
        if (valor){ // viene de un alta
 
             setContadorOperaciones(contadorOperaciones+1)
 
         }
     }

    const handleOpen = () => {setIdEventoSeleccionado(null);setEjecutarAlta(false)}

    const obtenerArchivos = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/eventos`)])
            setEventosList(resultList[0].data)
            setBuscandoDatos(false);
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const limpiarBusqueda = async () => {
        obtenerArchivos();
        setNameSearch('')
    }

    const seleccionar=(e,item,tipo)=>{


        if(!item){
            setIdEventoSeleccionado(null)
            return
        }

        if(usuario.sede_admin && usuario.rol_sede < 50){
                alert('Su perfil de usuario no tiene habilitada la función seleccionada')
                return
        }

        setIdEventoSeleccionado({id_evento:item.id_evento,titulo:item.titulo,tipo})

}

    if (buscandoDatos) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando archivos...</span>
            </div>
        </Main>
    }

    if(idEventoSeleccionado?.tipo=='items'){
        return <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
                <EventosValor usuario={usuario} id_evento={idEventoSeleccionado?.id_evento} cerrar={finalizarAltaOcopia} />      
            </Card>
    }

    return <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
        {eventosList?.length==0 && <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px', textAlign: 'center' }} >
            <Grid item>
                <Typography variant="h5">
                    No se encontraron archivos
                </Typography>
                <div className="flex xl:!justify-center">
                        <Crear setEjecutarAlta={setEjecutarAlta}/>
                </div>
            </Grid>
            
        </Grid>}
        {eventosList?.length>0 && <>
        <Typography variant="h4" className="flex ">
            Eventos
        </Typography>
        <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
            <div className='w-full lg:w-96 xl:w-96'>
                <Input
                    type="text"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    label="Buscar título"
                    value={nameSearch}
                    onChange={(e) => { setNameSearch(e.target.value) }}
                    color='black'
                />
            </div>
            <div className='mt-8 w-full'>
               <div className="flex xl:!justify-end">
                    {nameSearch &&  <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200' onClick={limpiarBusqueda}>Limpiar Busqueda</Button>}
                </div>
                <div className="flex xl:!justify-end">
                    <Crear setEjecutarAlta={setEjecutarAlta}/>
                </div>
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none hidden lg:flex"
                >
                    Se han encontrado {eventosList.length} eventos
                </Typography>

            {esCelular ? 
                <ListadoCelular datos={eventosList} verSeleccionado={seleccionar} />
                    :
                <table className="w-full min-w-max table-auto text-left mt-2">
                    <Thead/>
                    <Tbody
                        eventosList={eventosList}
                        seleccionar={seleccionar}
                    />
                </table>
            }

            </div>
        </Card>
        </>}
        {idEventoSeleccionado?.tipo=='cabecera' && <DialogCustom
            handleOpen={handleOpen}
            titulo={`Modifique un evento (Id:${idEventoSeleccionado?.id_evento})`}
        >
            <EventoForm id_evento={idEventoSeleccionado?.id_evento} finalizarAltaOcopia={finalizarAltaOcopia}/>
        </DialogCustom>}
        {idEventoSeleccionado?.tipo=='link' && <DialogCustom
            handleOpen={handleOpen}
            titulo={`Gestione los links de colaboradores del evento: ${idEventoSeleccionado?.titulo}`}
        >
                <NuevoLink id_evento={idEventoSeleccionado?.id_evento}/> 
        </DialogCustom>}
        {idEventoSeleccionado?.tipo=='linktest' && <DialogCustom
            handleOpen={handleOpen}
            titulo={`Genere un link para visualizar el evento: ${idEventoSeleccionado?.titulo}`}
        >
                 <NuevoLinkTest id_evento={idEventoSeleccionado?.id_evento}/> 
        </DialogCustom>}
         <Dialogos open={idEventoSeleccionado?.tipo=='gestion'}      
                                    fullscreen={true}
                                    procesarCancelar={()=>setIdEventoSeleccionado(null)}
                                   >
                    <GestionEvento id={idEventoSeleccionado?.id_evento} cerrar={()=>setIdEventoSeleccionado(null)} />      
                </Dialogos> 
        {ejecutarAlta && <DialogCustom
            handleOpen={handleOpen}
            titulo="Cree un nuevo evento"
        >
            <EventoForm finalizarAltaOcopia={finalizarAltaOcopia}/>
        </DialogCustom>}
    </div>
}


const DialogCustom = ({handleOpen,titulo,children})=>{
return <Dialog open={true} className="max-h-[90vh] overflow-y-scroll" handler={handleOpen}>
    <DialogHeader>{titulo}</DialogHeader>
    <DialogBody className="xl:p-10" >
        {children}
    </DialogBody>
</Dialog>
}

const Crear = ({setEjecutarAlta})=>{
    return <div className="flex xl:!justify-end">
    <Button className="flex mb-2 lg:mb-0 lg:ml-2 items-center gap-3 bg-green-500" onClick={()=>setEjecutarAlta(true)}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-4 w-4"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
            />
        </svg>
        Crear
    </Button>
</div>
}

const Tbody = ({eventosList,seleccionar})=>{
    return                     <tbody>
    {eventosList.map((item, index) => {
        const isLast = index === eventosList.length - 1;
        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

        return (
            <tr key={index}>
                 <td className={classes}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {item.id_evento}
                    </Typography>
                </td>
                <td className={classes}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {item.titulo}
                    </Typography>
                </td>
                <td className={classes}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {item.fecha}
                    </Typography>
                </td>
                <td className={`${classes} felx space-x-2 w-7`}>
                    {item.activo==1 && <CheckCircleIcon title='Activo' width={24} className="text-blue-200 ml-auto mr-auto"/>}
                    {item.activo==0 && <XCircleIcon  title='Inactivo' width={24} className="text-red-500 ml-auto mr-auto"/>}
                </td>
                <td className={`${classes} felx space-x-2 w-7`}>
                    {item.vigente==1 && <CalendarDaysIcon title='Vigente' width={24} className="text-green-800 ml-auto mr-auto"/>}
                    {item.vigente==0 && <NoSymbolIcon  title='No vigente' width={24} className="text-red-100 ml-auto mr-auto"/>}
                </td>
                <td className="p-4 flex justify-start border-b border-blue-gray-50">
                    <div className="flex items-center gap-5">
                        <Tooltip content="Editar la cabecera del evento">
                            <IconButton variant="text" size="sm" onClick={(e) => seleccionar(e,item,'cabecera')}>
                                <PencilIcon className="h-4 w-4 text-gray-900" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip content="Editar los Items del evento">
                            <IconButton variant="text" className={Number(item.items)== 0 ? 'text-red-700' : ''} size="sm" onClick={(e) => seleccionar(e,item,'items')}>
                                { Number(item.items)} items
                            </IconButton>
                        </Tooltip>
                        <Tooltip content="Gestionar ingresos">
                            <IconButton variant="text" size="sm" onClick={(e) => seleccionar(e,item,'gestion')}>
                                <FolderOpenIcon className="h-4 w-4 text-gray-900" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip content="Gestionar links de colaboradores">
                            <IconButton variant="text" size="sm" onClick={(e) => seleccionar(e,item,'link')}>
                                <LinkIcon className="h-4 w-4 text-gray-900" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip content="Crear un link de test">
                            <IconButton variant="text" size="sm" onClick={(e) => seleccionar(e,item,'linktest')}>
                                <EyeIcon className="h-4 w-4 text-gray-900" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </td>
            </tr>
        );
    })}
</tbody>
}

const Thead = ()=>{
    return <thead>
    <tr>
        <th
            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
            >
                ID
            </Typography>
        </th>
        <th
            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
            >
                Título
            </Typography>
        </th>
        <th
            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
            >
                Fecha
            </Typography>
        </th>
        <th
            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
            >
                Activo
            </Typography>
        </th>
        <th
            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
            >
                Vigente
            </Typography>
        </th>
        <th
            className="border-b flex justify-start border-blue-gray-100 bg-blue-gray-50 p-4"
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
            >
                Acciones
            </Typography>
        </th>
    </tr>
</thead>
}