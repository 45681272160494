import React, { useState, useEffect } from 'react';
import Main from '../../../componentes/Main';
import Axios from 'axios';
import { Grid } from "@material-ui/core";
import { Spinner, Textarea , Card, Typography, Input, IconButton, Tooltip, DialogFooter, Button, DialogBody, DialogHeader, Dialog,Checkbox } from '@material-tailwind/react';
import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import {Box} from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';

import DOMPurify from 'dompurify';

export default function PreguntasFrecuentes() {

    const [preguntasList, setPreguntasList] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [cabecera, setCabecera] = useState('')
    const [preguntaId, setPreguntaId] = useState(0)
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [preguntas, setPreguntas] = useState([])
    const [cambios, setCambios] = useState([])
    const [edicionActiva,setEdicionActiva] = useState(false)

    useEffect(() => {
        obtenerArchivos()
    }, [])

    useEffect(() => {
        if (nameSearch.trim() === '') {
            setPreguntasList(preguntasList);
        } else {
            const listaFiltrada = preguntasList.filter(item =>
                item.nombre.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setPreguntasList(listaFiltrada.length > 0 ? listaFiltrada : preguntasList);
        }
    }, [nameSearch, preguntasList]);


    const handleOpen = () => setOpen(!open);

    const obtenerArchivos = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/eventos/preguntasfrecuenteslist`)])
            
            setPreguntasList(resultList[0].data)
            setBuscandoDatos(false);
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const verPreguntaSeleccionada = async (e) => {
        try {
            const tanda = await Promise.all([Axios.get(`/api/tablasgenerales/eventos/preguntasfrecuentes/${e.id_pregunta_frecuente}`),
                Axios.get(`/api/tablasgenerales/eventos/preguntasfrecuentescab/${e.id_pregunta_frecuente}`)
            ])
            setCabecera(tanda[1].data)
            setPreguntas(tanda[0].data)
            setPreguntaId(tanda[1].data.id_pregunta_frecuente);
            handleOpen();
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    } 

    const editarPregunta = async () => {

        if(!cabecera?.nombre){
            alert('Falta ingresar el nombre')
            return
        }

        if(!cabecera?.descripcion){
            alert('Falta ingresar la descripción')
            return
        }

        if(cabecera?.activo==undefined){
            alert('Falta definir el estado (activo)')
            return
        }

        if(edicionActiva){
            alert('Falta agregar la nueva pregunta (Agregue o cancele)')
            return
        }

        const datos = {
            cabecera:{
                nombre: cabecera?.nombre,
                descripcion:cabecera?.descripcion,
                activo:cabecera?.activo,
            },
            detalle:[...cambios]
        }

        try {
            await Promise.all([Axios.put(`/api/tablasgenerales/preguntasfrecuentes/${preguntaId}`, datos)])
            Swal.fire({
                icon: 'alert',
                title: `<p>Los datos han sido actualizados con éxito</p>`,
            })
            handleOpen();
            obtenerArchivos();
        } catch (error) {
            console.log(error)
        }
    }


    const limpiarBusqueda = async () => {
        obtenerArchivos();
        setNameSearch('')
    }


    if (buscandoDatos) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando archivos...</span>
            </div>
        </Main>
    }

    if (preguntasList.length == 0) {
        return <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px', textAlign: 'center' }} >
            <Grid item>
                <Typography variant="h5">
                    No se encontraron archivos
                </Typography>
            </Grid>
        </Grid>
    }

    return <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
        <Typography variant="h4" className="flex ">
            Preguntas frecuentes
        </Typography>
        <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
            <div className='w-full lg:w-96 xl:w-96'>
                <Input
                    type="text"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    label="Buscar nombre"
                    value={nameSearch}
                    onChange={(e) => { setNameSearch(e.target.value) }}
                    color='black'
                />
            </div>
            {preguntasList.length > 0 && <div className='mt-8 w-full'>
               <div className="flex xl:!justify-end">
                    {nameSearch &&  <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200' onClick={limpiarBusqueda}>Limpiar Busqueda</Button>}
                </div>
                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none"
                                >
                                    Se han encontrado {preguntasList.length} grupos de Preguntas frecuentes
                                </Typography>
                <table className="w-full min-w-max table-auto text-left mt-2">
                    <thead>
                        <tr>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Nombre
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Descripción
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Activo
                                </Typography>
                            </th>
                            <th
                                className="border-b flex justify-end border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Acciones
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {preguntasList.map((item, index) => {
                            const isLast = index === preguntasList.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                                <tr key={index}>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.descripcion}
                                        </Typography>
                                    </td>
                                    <td className={`${classes} felx space-x-2 w-7`}>
                                        {item.activo==1 && <CheckCircleIcon title='Activo' width={24} className="text-blue-200 ml-auto mr-auto"/>}
                                        {item.activo==0 && <XCircleIcon  title='Inactivo' width={24} className="text-red-500 ml-auto mr-auto"/>}
                                    </td>
                                    <td className="p-4 flex justify-end border-b border-blue-gray-50">
                                        <div className="flex items-center gap-2">
                                            <Tooltip content="Ver Detalles">
                                                <IconButton variant="text" size="sm" onClick={() => verPreguntaSeleccionada(item)}>
                                                    <PencilIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>}
        </Card>
        <Dialog  className="max-h-[90vh] overflow-y-scroll" open={open} handler={handleOpen}>
            
            <DialogHeader>Preguntas frecuentes</DialogHeader>
            <DialogBody className="xl:p-10" >
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    > 
                        Nombre
                    </Typography>
                    <Input
                        onChange={(e) => {
                            setCabecera({...cabecera,nombre:e.target.value})
                        }}
                        value={cabecera?.nombre}
                        type='text'
                        maxLength={20}
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Descripción
                    </Typography>
                    <Input
                        onChange={(e) => {
                            setCabecera({...cabecera,descripcion:e.target.value})
                        }}
                        value={cabecera?.descripcion}
                        type='text'
                        maxLength={100}
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Checkbox
                        label="Activo"
                        ripple={false}
                        title='Controla si aparece en la plataforma de eventos'
                        checked={cabecera?.activo == 1} 
                        onClick={(e)=>setCabecera({...cabecera,activo:cabecera.activo==1 ? 0 : 1})}
                        className='w-5 h-5'
                    />
                </div>

                <ListPreguntasFrecuentes preguntas={preguntas} setCambios={setCambios} setEdicionActiva={setEdicionActiva}/>

            </DialogBody>
            <DialogFooter className="space-x-2">
            {!edicionActiva && <>
                    <Button variant="gradient" color="blue" onClick={editarPregunta}>
                        Guardar
                    </Button>
                    <Button variant="text" color="blue-gray" onClick={handleOpen}>
                        Cancelar
                    </Button>
                </>}
                {edicionActiva && <Typography
                        variant="small"
                        color="red"
                    > 
                        Hay una edición activa de una nueva pregunta (Agregue o cancele)
                </Typography>}
            </DialogFooter>
        </Dialog>
    </div>
}


const ListPreguntasFrecuentes = ({preguntas,setCambios,setEdicionActiva})=>{
    const [copia,setCopia] = useState(preguntas)
    const [agregar,setAgregar] = useState(false)
    const [notify,setNotify] = useState(false)

    useEffect(()=>{
        setCambios(copia)
    },[copia])

    useEffect(()=>{
        if(agregar){
            setEdicionActiva(true)
        }else{
            setEdicionActiva(false)
        }
    },[agregar])

    const onchange = (cambio,operacion)=>{
        let nuevoset = [];
        switch(operacion){
            case 'borrar' : {
                nuevoset = [...copia].filter((item=>item.id_pregunta_frecuente_det!=cambio.id_pregunta_frecuente_det))
                break
            }
            case 'editar' : {
                nuevoset = [...copia].map((item,index)=>{
                    if(item.id_pregunta_frecuente_det==cambio?.id_pregunta_frecuente_det){
                        return {...item,pregunta:cambio.pregunta,respuesta:cambio.respuesta}
                    }else{
                        return item
                    }
                })
                break
            }
            case 'agregar' : {
                nuevoset = [...copia,cambio]
            }            
        }
        if(operacion){
            console.log(JSON.stringify(cambio))
            notificar()
        }
    setCopia([...nuevoset])

    }

    const onnew = (cambio)=>{
        if (!cambio.pregunta?.length){
            alert('Falta la nueva pregunta')
            return
        }
        if (!cambio.respuesta?.length){
            alert('Falta la nueva respuesta')
            return
        }
        setCopia([...copia,cambio])
        notificar()
        setAgregar(false)
    }

    const notificar =()=>{
        setNotify(true)
        setTimeout(() => {
            setNotify(false)
        }, 4000);
    }

    return  <Box sx={{padding:'16px'}}> 
       <Typography
                        variant="small"
                        color="red"
                        className="mb-10"
                   > 
                        Las preguntas y respuestas pueden contener texto plano y código HTML
        </Typography>
        {copia.map((item,index)=>{
            return  <SeccionPregunta
            item={item}
            onchange = {onchange}
        />
        })}

        {notify && <Typography
                        variant="h4"
                        color="red"
                        className="mb-10"
                    > 
                        No olvide guardar los cambios con el botón GUARDAR!
                </Typography>}  

        {!agregar && <Button className="flex ml-2 items-center gap-3 bg-green-500" onClick={()=>setAgregar(true)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                            />
                        </svg>
                        Crear nueva pregunta
        </Button>}

        {agregar && <NuevaPregunta
            onnew={onnew}
            cancelar={()=>setAgregar(false)}
        />}
</Box>
}

const SeccionPregunta = ({item,onchange})=>{
    const [preguntaLocal,setPreguntaLocal] = useState(item.pregunta);
    const [respuestaLocal,setRespuestaLocal] = useState(item.respuesta);
    const [inicializado,setInicializado] = useState(false)

    useEffect(()=>{
        setInicializado(true)
    },[])

    useEffect(()=>{
        if(inicializado){
            onchange({id_pregunta_frecuente_det:item.id_pregunta_frecuente_det,pregunta:preguntaLocal,respuesta:respuestaLocal},'editar')
        }
    },[preguntaLocal,respuestaLocal])

    return <div className='mb-10'>
        <div className='mt-6 bg-blue-gray-100' >
          <Textarea
                    label={`Pregunta ${item?.numero_pregunta || 'nueva'}`}
                    value={DOMPurify.sanitize(preguntaLocal)}
                    rows={1}
                    onChange={(e)=>setPreguntaLocal(e.target.value)}
            />
        </div>
        <div className='mt-5' >
                         <Textarea label="Respuesta" 
                         onChange={(e)=>setRespuestaLocal(e.target.value)}
                         rows={1}
                         value={DOMPurify.sanitize(respuestaLocal)}
                         />

        </div>
            <div className='flex items-center'>
                Eliminar pregunta {item.numero_pregunta || 'nueva'}
                <IconButton variant="text" size="sm" onClick={(e)=>onchange({id_pregunta_frecuente_det:item.id_pregunta_frecuente_det,pregunta:preguntaLocal,respuesta:respuestaLocal},'borrar')}>
                        <TrashIcon className="h-4 w-4 text-gray-900" /> 
                </IconButton>
            </div>
    </div>
}

const NuevaPregunta = ({onnew,cancelar})=>{
    const [preguntaLocal,setPreguntaLocal] = useState(null);
    const [respuestaLocal,setRespuestaLocal] = useState(null);

     return <div className='mb-10'>
        <div className='mt-6 bg-blue-gray-100' >
          <Textarea
                    label={`Nueva pregunta`}
                    value={DOMPurify.sanitize(preguntaLocal)}
                    rows={1}
                    autoFocus={true}
                    onChange={(e)=>setPreguntaLocal(e.target.value)}
            />
        </div>
        <div className='mt-5' >
                         <Textarea label="Respuesta" 
                         onChange={(e)=>setRespuestaLocal(e.target.value)}
                         rows={1}
                         value={DOMPurify.sanitize(respuestaLocal)}
                         />

        </div>
             <Button variant="gradient" color="green" onClick={(e)=>onnew({id_pregunta_frecuente_det:uuidv4(),pregunta:preguntaLocal,respuesta:respuestaLocal},'borrar')}>
                <p className='blink'>Agregar nueva pregunta</p>
            </Button>
            <Button variant="text" color="blue-gray" onClick={cancelar}>
                Cancelar
            </Button>
    </div>
}
