import React,{useState,useEffect} from 'react'
import Axios from 'axios'
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import Swal from 'sweetalert2';

export const useEventos = (id)=>{
    const [inscriptos,setInscriptos] = useState([])
    const [cargando,setCargando]=useState(true)
    const [filtros,setFiltros] = useState({})
    const [mensaje,setMensaje] = useState(null)
    const {conexionWebSocket,usuario,esCelular} = useContextoGlobal();
    const [codInscripto,setCodInscripto]=useState(null)
    const [inscriptoSeleccionado,setInscriptoSeleccionado]=useState(null)

    useEffect(()=>{
        conexionWebSocket.on('mensaje_nuevo_ingreso_evento',data=>{
            // if(data.id_evento==9){
            //     setMensaje(data.mensaje)
            //     setTimeout(() => {
            //         setMensaje('')
            //     }, 800);
            //     cargarInscriptos(9,true)
            // }
            if(data.id_evento==id){
                setMensaje(data.mensaje)
                setTimeout(() => {
                    setMensaje('')
                }, 800);
                cargarInscriptos(id,true)
            }
        })
    },[])

    /*
    // Elimino de aquí la carga de inscriptos porque sucede que se carga 2 veces cuando
    // un componente padre y un hijo llaman al mismo contexto. Voy a llamarlo directamente en el componente padre (Listado es el padre Resumen es el hijo)
    useEffect(()=>{
        cargarInscriptos(7)
    },[])
    */

    const handleChangeCodInscripto = (e)=>{
        setCodInscripto(e.target.value.trim())
    }

    const handleChangeIngresarInscripto = (e)=>{

        const vector_aux = inscriptoSeleccionado.map(item=>{
            return item.id_evento_inscr == e.target.name ? {...item,ingresar:e.target.checked} : {...item}
        })

        setInscriptoSeleccionado(vector_aux)

    }

    const handleInputCodInscripto = (codigo)=>{
        setCodInscripto(codigo)
    }

    const ingresarInscripto = async ()=>{
        
        const copia_de_inscriptos_a_ingresar = inscriptoSeleccionado.filter(item=>
            // hago esta copia para asegurar que no se está queriendo ingresar a alguien que ya fue ingresado
            // especialmente en el caso en que se haya ingresado 1 de los inscriptos y luego se quiera ingresar a otro e diferentes momentos
            // por ejemplo un matrimonio que vengan en 2 momentos diferentes con el mismo número de inscripción
            !item.presente==1 // solo incluyo los que presente sea NULL o 0  
        )

        const cant_ingresos_solicitados = copia_de_inscriptos_a_ingresar.filter(item=>item.ingresar==true).length 
        const cant_incriptos = inscriptoSeleccionado.length 
        const es_inscripcion_multiple = cant_incriptos > 1

        const es_ingreso_parcial = cant_ingresos_solicitados < cant_incriptos 

        if (cant_ingresos_solicitados==0){
            alert('No seleccionó ningún inscripto para ingresar')
            return
        }


        const nombres_ingresos = copia_de_inscriptos_a_ingresar.filter(item=>item.ingresar==true).reduce((ac,item,index)=>{
            return `${ac} ${index>0 ? ' y ' : ''} ${item.nombre}`
        },'')

        const textoHtml = `<div>
            ${es_ingreso_parcial ? '<h3>ATENCIÓN</h3><h4>ESTÁ INGRESANDO MENOS PERSONAS DE LAS INSCRIPTAS</h4>' : '' }
            <p>¿Confirma el ingreso de ${nombres_ingresos}?</p>
        </div>`

        const respuesta = await Swal.fire({
            html:textoHtml,
            showCancelButton:true,
            confirmButtonText:'Si, ingresar',
            cancelButtonText:'Cancelar'
        })

        if (!respuesta.value){
            return
        }

        try{
            
            const inscriptos_a_ingresar = copia_de_inscriptos_a_ingresar.filter(item=>item.ingresar).map(item=>item.id_evento_inscr)

            const objetoAgrabar = { 
                inscriptos: inscriptos_a_ingresar,
                mp_id: inscriptoSeleccionado[0].mp_id,
                nro_cupon: inscriptoSeleccionado[0].nro_cupon,
                id_usuario: usuario.id_usuario             
            }

            //const url = `/api/tablasgenerales/evento/ingreso/inscripto/9`
            const url = `/api/tablasgenerales/evento/ingreso/inscripto/${id}`
            const {data} = await Axios.post(url,objetoAgrabar);

            Swal.fire({
                icon: 'success',
                text: data.mensaje,
                showConfirmButton: false,
                timer: 2500
            })

            cancelarIngreso()
            //cargarInscriptos(9,true)
            cargarInscriptos(id,true)
        }catch(err){
            Swal.fire({
                icon: 'error',
                text: `${err.response.data.message}`
              })
        }
    }


    const cargarInscriptos =  async (id_evento,sinLoading)=>{
        
        if(!sinLoading){
            setCargando(true)
        }

        try{
            const {data} = await Axios.get(`/api/tablasgenerales/eventos/inscriptos/${id_evento}`)

            setInscriptos(data)
            setCargando(false)
        }catch(err){
           alert(err.response.data.message)
           setCargando(false)
        }
    }

    const buscarInscripto = ()=>{


        if (!codInscripto || codInscripto==''){
            alert('No ingresó un código de inscripción')
            return
        }

        const texto_ingresado = codInscripto.trim();

        let id_evento_inscr_aux =  ''
        
        if(texto_ingresado.length>6){
            // puede ser un codigo_web completo como 4934-11126 o un DNI   
            // se hace esta validacion porque siempre generaba un id_evento_inscr_aux incluso cuando
            // se ingresaba un DNI y podía traer inscriptos que no corresponden al dni porque se
            // aplica el OR de búsqueda por id_evento_inscr ( ahora si viene un DNI id_evento_inscr_aux es '')
            if(texto_ingresado.includes('-')){
                id_evento_inscr_aux = texto_ingresado.substring(0,4)
            }                             
        }else{
            id_evento_inscr_aux = texto_ingresado.substring(0,4)
        }

        const inscripto = inscriptos.inscriptos.filter(item=>item.nro_documento==Number(texto_ingresado) || item.codigo_web.trim()==texto_ingresado || item.id_evento_inscr==id_evento_inscr_aux)

        if(inscripto.length>0){
           const multiple_inscripcion = inscripto.length>1
           
           setInscriptoSeleccionado(inscripto.map(item=>{return{...item,ingresar:multiple_inscripcion ? false: true}})) 
           // agrego un boolean que se usará para indicar qué inscripto se va a ingresar en el caso
           // que sean varios (matrimonio x ejemplo, ya que puede ingresar solo 1 aunque se inscribieron 2)
           // si es una inscripción individual se marca en true ya que no hace falta analizar esta situación que se da en la insc multiple 
        }else{
            alert('No se encontró el código de inscripción ingresado')
        }
        
    }

    const cancelarIngreso = ()=>{
        setInscriptoSeleccionado(null)
        setCodInscripto('')
    }

    return {cargarInscriptos,
            inscriptos: inscriptos,//inscriptos.inscriptos.filter(item=>filtrar(item,filtros)),
            cargando,
            refrescar:cargarInscriptos,
            filtros,codInscripto,
            handleChangeCodInscripto,
            ingresarInscripto,
            buscarInscripto,
            inscriptoSeleccionado,
            cancelarIngreso,
            handleChangeIngresarInscripto,mensaje,handleInputCodInscripto
        }
}


const filtrar = (itemdelListado,filtros)=>{
    // esta función actúa en el metodo filter de un array.
    // Por ejemplo el array de inscriptos a la convención 
    // cuando devuelve true se incluye el item (inscripto) que se está evaluando
    // si devuelve false no se incluye
    // voy a comparar un item contra un objeto

    // el item tiene este formato 
    /*
    {   nombre,
        nro_documento,
        provincia,
        edad,
        email,
        id_evento_inscr,
        sexo, 
        cod_interno,
        mp_id,
        codigo_web,
        f_alta,
        detalle,
        categoria,
        region,
        rango,
        tarif_matr} 
    */                        
    // ... marqué los campos que se evaluan si están presentes en el objeto de filtros

    // el filtro tiene este formato = {clase:'Gira',id:5,anio:2022,mesString:'Enero'} 
    //... los campos de este objeto se agregan si no existe, 
    //se pisa si existe y se borra dependiendo de si el usuario eligió el filtro o no, 
    //cuando lo elige lo agregamos, cuando lo quita lo borramos del objeto filtro
    const arrayObjeto = Object.entries(filtros) 
    // primero transformo el objeto filtro en un array tipo [['clase','Gira'],['id',5]...]
    // es decir arrayOjbeto tiene en cada posicion un vector[0]=clave y vector[1]=valor
    
    let resultado = true

    // aprovechamos que se puede referenciar el campo de un objeto como un vector objeto['campo']

    // por cada item del vector de filtros evalúo si el valor del campo del item es diferente al valor del filtro
    // por ejemplo si el objeto filtro tiene {anio:2005,mesString:'Enero'}
    // se transforma a vector y queda [['anio':2005],['mesString':'Enero']]
    //                                    
    arrayObjeto.forEach(itemdelvectorFiltro=>{
        if(itemdelListado[itemdelvectorFiltro[0]]){ // evalúo si es igual o diferente solo si el campo existe, esto es porque se usa la misma función de filtrado para diferentes informes y puede haber primary keys que en algunos listados estén y en otros no, es importante este primer IF, por ejemplo id_tipo_salida no va a existir en un listado de ingresos por eso no habíra que evaluarlo aquí
            if(itemdelListado[itemdelvectorFiltro[0]]!=itemdelvectorFiltro[1]){
                    //item['anio']!=2005 ...1er loop    donde item['anio'] es como decir itemdelListado.anio!=2005
                    // item['mesString']!='Enero' ...2do loop              es como decir itemdelListado.mesString!='Enero'
                    // Si en algún ciclo del loop se cumple que es distinto entonces ya se considera falso el resultado y no se va a incluir el item del listao
                resultado = false
            }
        }
    })

   return resultado
}

