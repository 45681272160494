import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box,IconButton,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {useEventos} from '../hooks/useEventos'
import Axios from 'axios'
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@material-tailwind/react';


export const NuevoLink = ({id_evento})=>{
    const [mensaje,setMensaje] = useState(null)
    const [linkActual,setLinkActual] = useState(null)

    useEffect(()=>{
        obtenerLinkActual()
    },[])

    const actualizarLink = async (opcion)=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/evento/nuevolink/${opcion}/${id_evento}`)
            setMensaje(data.nuevoLink ? data.nuevoLink : 'El link se ha desactivado')   
            setLinkActual(data.nuevoLink ? data.nuevoLink : 'El link se ha desactivado')
        }catch(err){
            setMensaje(err)
        }

    }

    const obtenerLinkActual = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/evento/colaborador/link/${id_evento}`)
            if(data.link){
                setLinkActual(data.link)
            }else{
                setLinkActual(null)
            }
        }catch(err){
            setMensaje(err)
        }

    }

    //sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}

    return <><div className='m-auto mb-4 w-64 lg:w-full xl:w-full' >
        {linkActual && <Alert severity="info" variant='outlined'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!linkActual.includes('desactivado') && <Copiar texto={linkActual} />}      
                <p>{linkActual}</p>
            </div>
        </Alert>}

    </div>
    <Button className='w-full' onClick={() => actualizarLink('NUEVO')}>Generar un nuevo link</Button>
    <Button className='mt-3 w-full' onClick={() => actualizarLink('DESACTIVAR')}>Desactivar todos los links</Button>
    </>
}

const Copiar = ({texto})=>{
    return  <IconButton title='Copiar' onClick={() => {navigator.clipboard.writeText(texto)}} color="primary" aria-label="upload picture" component="label">
    <ContentCopyIcon />
</IconButton>
}