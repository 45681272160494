import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box,Checkbox,FormControlLabel,Button,IconButton ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { eventoContext } from '../contexto/EventoContext'
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import SearchIcon from '@mui/icons-material/Search';
import { Input, Typography } from '@material-tailwind/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

export const Ingreso = ()=>{
    const {handleChangeCodInscripto,
            codInscripto,
            inscriptoSeleccionado,
            ingresarInscripto,
            buscarInscripto,
            cancelarIngreso,
            handleChangeIngresarInscripto} = useContext(eventoContext)


    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            buscarInscripto()
        }
    }   

    return <>
            <div className='w-full lg:w-96 xl:w-96 mt-4'>
                <Input  
                    autoFocus 
                    value={codInscripto} 
                    onKeyPress={handleKeyPress} 
                    onChange={handleChangeCodInscripto} 
                    id="ub-codinscripto" 
                    label="Ingrese DNI o QR"
                    color='black'
                    icon={<MagnifyingGlassIcon className="h-5 w-5 cursor-pointer" onClick={()=>buscarInscripto()}/>} 
                />
            </div>
            {/*<Button style={{marginTop:'1rem',marginBottom:'1rem'}} onClick={()=>buscarInscripto()} color='primary' variant='contained'>Buscar</Button>*/}
            <Dialogos open={inscriptoSeleccionado} 
                        titulo='Ingreso de un inscripto' 
                        procesarCancelar = {cancelarIngreso}
                        procesarResultado = {ingresarInscripto}
                        textoAceptar = 'CONTINUAR'
                        >
                        <DatosInscripto inscriptoSeleccionado={inscriptoSeleccionado} handleChangeIngresarInscripto={handleChangeIngresarInscripto}/>
            </Dialogos>
  </>   
}

const DatosInscripto = ({inscriptoSeleccionado,handleChangeIngresarInscripto})=>{

    if(!inscriptoSeleccionado)return null

    return <>
    <Box sx={{textAlign:'center',margin:'0.5rem'}} title={JSON.stringify(inscriptoSeleccionado)}>
        <Typography  variant="h5">{inscriptoSeleccionado[0].detalle} ({inscriptoSeleccionado[0].codigo_web.trim()})</Typography>
        <Typography variant="h5">Número de pago: {inscriptoSeleccionado[0].mp_id} ({inscriptoSeleccionado[0].cod_interno})</Typography>
    </Box>
    <table>
        <tbody>
            {inscriptoSeleccionado.map(item=>{
                return <tr>
                    <td>
                        <Typography color='blue' variant="subtitle1">{item.nombre} ({item.categoria})</Typography>
                        <Typography variant="subtitle1">DNI: {item.nro_documento} / {item.edad} años / {item.sexo=='M' ? 'HOMBRE' : 'MUJER'}</Typography>
                        <Typography variant="subtitle1">EMAIL: {item.email}</Typography>
                        <Typography variant="subtitle1">{item.provincia}</Typography>
                    </td>
                    <td>
                        {!item.presente ? 
                        <div className='ml-4' style={{padding:'0.5rem'}}><FormControlLabel title='INGRESAR' control={<Checkbox color="primary" name={item.id_evento_inscr} checked={item.ingresar} onChange={handleChangeIngresarInscripto} />} label='Ingresar'/></div> 
                        : 
                        <div className='bg-blue-gray-200 ml-4' style={{padding:'0.5rem'}}><p style={{color:'white'}}>INGRESADO</p><p>{item.usuario_ingreso}</p><p>{item.f_ingreso}</p><p>{item.h_ingreso}</p></div>}
                    </td>
                </tr>
            })}
        </tbody>
    </table>    
    </>
}

