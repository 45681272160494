import React, {useState, useEffect} from 'react';
import {Main,Axios,Swal,} from '../../../Helpers/importacionesComunes'
import { Button, Card, Checkbox, Input, Spinner, Typography } from '@material-tailwind/react';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Textarea } from '@material-tailwind/react';
import {Cerrar} from './Cerrar';
import {formatearMontoMoneda} from '../../../Helpers/utilidades-globales';

const regex_solo_numeros = /^[0-9]+([.])?([0-9][0-9]?)?$/;

export default function EventoValorForm({id_evento_valor,finalizarAltaOcopia,id_evento,detalle}){

    const [huboError,setHuboError]=useState(false)
    const [grabandoDatos,setGrabandoDatos]=useState(false)
    const [cargando,setCargando]=useState(false)
    const {esCelular} = useContextoGlobal();
    const [eventoValor,setEventoValor] = useState({
			 detalle: '',
             valor_mp:0,
             info_adicional: '',
			 templateejs: '',
             beca_id: '',
			 activo: false,
             mercadopago: true,
			 beca_activa: false,
             validar_dni_externos:false,
			// habilitar_inscripciones:false,
		  	 texto1: '',
			 texto2: '',
			 texto3: '',
			 texto4: '',
             id_evento
    })
    useEffect(()=>{

        const buscarEventosValor = async ()=>{
            setCargando(true)
            try{
                setHuboError(false)
                const {data} = await Axios.get(`api/tablasgenerales/eventos/eventosvalor/info/${id_evento_valor}`) 
                setEventoValor({...data,valor_mp:formatearMontoMoneda(data?.valor_mp || 0)})
                setCargando(false)
            }catch(err){
                setHuboError(true)
                setCargando(false)
            }            
        }

        if(id_evento_valor){
            buscarEventosValor()
        }

        },[id_evento_valor])


    const handleChangeValorMp = (e)=>{

        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()=='')
        {
            setEventoValor({...eventoValor, valor_mp:e.target.value.trim()=='' ? '' : e.target.value})
        }

    }

    const iniciarGrabar = (values)=>{
        let texto;
        let textoConfirmacion;
    
        if(!eventoValor?.detalle){
            alert('Falta ingresar una descripción en el campo detalle')
            return
        }

        if(!eventoValor?.valor_mp){
            alert('Falta ingresar el valor')
            return
        }

        if(eventoValor?.beca_activa==1 && !eventoValor?.beca_id){
            alert('Falta ingresar el link de la beca')
            return
        }

        if (id_evento_valor){
            texto = `¿Confirma la modificación del evento valor ${eventoValor.detalle}(${id_evento_valor})?`
            textoConfirmacion = 'Si, modificar el evento valor'
        }else{
            texto = `¿Confirma la creación del nuevo evento valor?`
            textoConfirmacion = 'Si, crear el evento valor'
        }
    
        Swal.fire({
            text:texto,
            showCancelButton:true,
            confirButtonText:textoConfirmacion,
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    grabarDatos(values)
                    .catch(err=>{
                        alert(err)
                    })
                }else{
                    console.log("Se canceló la modificación o creación de la asamblea")
                }
            }
        )
    }

    const grabarDatos = async (values)=>{
        let resultado;

        const objetoAgrabar = eventoValor;
    
        setGrabandoDatos(true)
    
        try{
            if (id_evento_valor){
                resultado= await Axios.put(`/api/tablasgenerales/eventovalor/${id_evento_valor}`,objetoAgrabar)
                finalizarAltaOcopia(id_evento_valor)
            }else{
                resultado= await Axios.post(`/api/tablasgenerales/eventovalor`,objetoAgrabar)
                finalizarAltaOcopia(resultado.data.id_evento_valor)
            }
        }catch(err){    
    
            let mensaje_html_error;
    
            console.log('err.response.status',err.response.status)
    
            if(err.response.data.message){
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del evento valor</p><p>${err.response.data.message}</p>`
            }else{
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del evento valor </p><p>${err.response.data.message}</p>`
            }
    
            Swal.fire({
                html:mensaje_html_error,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })   
        
            setGrabandoDatos(false)
        }
    }

    if (huboError){
        return <Main center>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <span>Se produjo un error al cargar los datos para esta vista</span>
                    </div>
            </Main>
    }

    if(cargando){
        return <Main> 
                    <div className={`flex flex-col justify-center items-center h-[calc(100vh-10rem)] ${esCelular ? 'ml-40' : ''}`}>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando...</span>
                    </div>
                </Main>
     }

     return <>
     {/* <Cerrar cerrar={()=>finalizarAltaOcopia(null)}/> */}
     {/* <Typography
        variant="h3"
        color="blue-gray"
        className="mb-1 font-medium "
    >
        {detalle}
    </Typography>                    
    <Typography
        variant="h4"
        color="blue-gray"
        className="mb-1 font-medium "
    >
        {id_evento_valor ? `Modifique un item (Id:${id_evento_valor})` : `Cree un nuevo item`}
    </Typography> */}
    <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Detalle:<span className='c-obl'></span>
        </Typography>
        <Input
            onChange={(e)=>{
                setEventoValor({...eventoValor,detalle:e.target.value})
            }}
            value={eventoValor.detalle || ''} 
            type='text' 
            color='black'
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
            autoFocus={id_evento_valor ? false : true}
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
    </div>
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Valor en mercadopago:<span className='c-obl'></span>
        </Typography>
        <Input
            onChange={(e)=>handleChangeValorMp(e)}
            value={eventoValor.valor_mp || ''} 
            type='text' 
            color='black'
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
    </div>
    <div className='flex'>   
    <Checkbox
        label="Activo"
        title='Controla si el item aparece en la plataforma de eventos'
        ripple={false}
        checked={eventoValor.activo == 1} 
        onClick={(e)=>setEventoValor({...eventoValor,activo:eventoValor.activo==1 ? 0 : 1})}
        className='w-5 h-5'
    />
    <Checkbox
        label="Permitir becados"
        ripple={false}
        title="Habilita o deshabilita becas para éste item"
        checked={eventoValor.beca_activa == 1} 
        onClick={(e)=>setEventoValor({...eventoValor,beca_activa:eventoValor.beca_activa==1 ? 0 : 1})}
        className='w-5 h-5'
    />
    <Checkbox
        label="Validar DNI externos"
        ripple={false}
        checked={eventoValor.validar_dni_externos == 1} 
        title='Controla si para éste item se valida que el DNI externo se encuentre autorizado por un ministro uad'
        onClick={(e)=>{
            if(e.target.checked){
                alert('Recuerde que las autorizaciones de DNI se hacen en la aplicación de ministros y para tal fin debe habilitarse en dicha aplicación un formulario para cada evento en particular. Verifique que haya un formulario para éste evento')
            }
            setEventoValor({...eventoValor,validar_dni_externos:eventoValor.validar_dni_externos==1 ? 0 : 1})
        }}
        className='w-5 h-5'
    />
    

    </div>
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Información adicional:
        </Typography>
        <Input
            onChange={(e)=>{
                setEventoValor({...eventoValor,info_adicional:e.target.value})
            }}
            value={eventoValor.info_adicional || ''} 
            type='text' 
            color='black'
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
    </div>
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Link de la beca (Por ejemplo: 123456-abc):
        </Typography>
        <Input
            onChange={(e)=>{
                setEventoValor({...eventoValor,beca_id:e.target.value.trim()})
            }}
            value={eventoValor.beca_id || ''} 
            type='text' 
            color='black'
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
    </div>
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Plantilla HTML (Mail/Mensaje no standard enviado al inscribirse):
        </Typography>
        <Input
            onChange={(e)=>{
                setEventoValor({...eventoValor,templateejs:e.target.value.trim()})
            }}
            value={eventoValor.templateejs || ''} 
            type='text' 
            color='black'
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
    </div>

    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Texto1:
        </Typography>
        <Textarea 
            value={eventoValor.texto1 || ''} 
            onChange={(e)=>{
                setEventoValor({...eventoValor,texto1:e.target.value})
            }} 
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
        />
    </div>  
    
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Texto2:
        </Typography>
                <Textarea 
                        value={eventoValor.texto2 || ''} 
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,texto2:e.target.value})
                        }} 
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    />
    </div>  
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Texto3:
        </Typography>
        <Textarea 
            value={eventoValor.texto3 || ''} 
            onChange={(e)=>{
                setEventoValor({...eventoValor,texto3:e.target.value})
            }} 
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
        />                    
    </div>  
    <div className="flex flex-col w-full mt-4">
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
        >
            Texto4:
        </Typography>
        <Textarea 
            value={eventoValor.texto4 || ''} 
            onChange={(e)=>{
                setEventoValor({...eventoValor,texto4:e.target.value})
            }} 
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
        />                    
    </div> 
    <div className='items-center flex justify-center space-x-2 mt-4'>
        <Button onClick={iniciarGrabar} type="submit">
            Grabar
        </Button>
        <Button color='red' onClick={()=>finalizarAltaOcopia(null)}>
            cancelar
        </Button>
    </div>
</>

    return <Card className='p-2 lg:p-10 xl:p-10 flex flex-col !justify-center items-center mt-4'>
                 <Cerrar cerrar={()=>finalizarAltaOcopia(null)}/>
                 <Typography
                    variant="h3"
                    color="blue-gray"
                    className="mb-1 font-medium "
                >
                    {detalle}
                </Typography>                    
                <Typography
                    variant="h4"
                    color="blue-gray"
                    className="mb-1 font-medium "
                >
                    {id_evento_valor ? `Modifique un item (Id:${id_evento_valor})` : `Cree un nuevo item`}
                </Typography>
                <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Detalle:<span className='c-obl'></span>
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,detalle:e.target.value})
                        }}
                        value={eventoValor.detalle || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        autoFocus={id_evento_valor ? false : true}
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Valor en mercadopago:<span className='c-obl'></span>
                    </Typography>
                    <Input
                        onChange={(e)=>handleChangeValorMp(e)}
                        value={eventoValor.valor_mp || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className='flex'>   
                <Checkbox
                    label="Activo"
                    title='Controla si el item aparece en la plataforma de eventos'
                    ripple={false}
                    checked={eventoValor.activo == 1} 
                    onClick={(e)=>setEventoValor({...eventoValor,activo:eventoValor.activo==1 ? 0 : 1})}
                    className='w-5 h-5'
                />
                <Checkbox
                    label="Permitir becados"
                    ripple={false}
                    checked={eventoValor.beca_activa == 1} 
                    onClick={(e)=>setEventoValor({...eventoValor,beca_activa:eventoValor.beca_activa==1 ? 0 : 1})}
                    className='w-5 h-5'
                />
                <Checkbox
                    label="Validar DNI externos"
                    ripple={false}
                    checked={eventoValor.validar_dni_externos == 1} 
                    onClick={(e)=>setEventoValor({...eventoValor,validar_dni_externos:eventoValor.validar_dni_externos==1 ? 0 : 1})}
                    className='w-5 h-5'
                />

                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Información adicional:
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,info_adicional:e.target.value})
                        }}
                        value={eventoValor.info_adicional || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Link de la beca:
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,beca_id:e.target.value.trim()})
                        }}
                        value={eventoValor.beca_id || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Plantilla HTML:
                    </Typography>
                    <Input
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,templateejs:e.target.value.trim()})
                        }}
                        value={eventoValor.templateejs || ''} 
                        type='text' 
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
    
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto1:
                    </Typography>
                    <Textarea 
                        value={eventoValor.texto1 || ''} 
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,texto1:e.target.value})
                        }} 
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    />
                </div>  
                
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto2:
                    </Typography>
                            <Textarea 
                                    value={eventoValor.texto2 || ''} 
                                    onChange={(e)=>{
                                        setEventoValor({...eventoValor,texto2:e.target.value})
                                    }} 
                                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                />
                </div>  
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto3:
                    </Typography>
                    <Textarea 
                        value={eventoValor.texto3 || ''} 
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,texto3:e.target.value})
                        }} 
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    />                    
                </div>  
                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Texto4:
                    </Typography>
                    <Textarea 
                        value={eventoValor.texto4 || ''} 
                        onChange={(e)=>{
                            setEventoValor({...eventoValor,texto4:e.target.value})
                        }} 
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    />                    
                </div> 
                <div className='items-center flex justify-center space-x-2 mt-4'>
                    <Button onClick={iniciarGrabar} type="submit">
                        Grabar
                    </Button>
                    <Button color='red' onClick={()=>finalizarAltaOcopia(null)}>
                        cancelar
                    </Button>
                </div>
    </Card>

}
