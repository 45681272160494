import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";

function ChipInput({ chips, setChips }) {
  const [text, setText] = useState("");
  const [validationError, setValidationError] = useState("");

  function removeChip(chipToRemove) {
    // filtering out the chip that the user wants to remove
    const updatedChips = chips.filter((chip) => chip !== chipToRemove);
    setChips(updatedChips);
  }

  function handlePressEnter(e) {
    // don't submit the form if the user presses 'Enter'
    if (e.key === "Enter") e.preventDefault();
    // return if the user pressed a key that is not 'Enter', or the user hasn't typed anything
    if (e.key !== "Enter" || !text) return;
    // need to show error if the user tries to add the same input more than once
    if (chips.includes(text)) {
      return setValidationError("No puedes agregar el mismo valor nuevamente");
    }

    if (!esEmailValido(text)) {
      return setValidationError("Por favor, ingrese un correo válido.");
    }
    
    // adding the input value to chips array
    setChips((prevState) => [...prevState, e.target.value]);
    // clearing the input box
    setText("");
    // clearing error message
    setValidationError("");
  }
  
  function handleBlurAction(e) {
    if (!e.target.value) return;

    if (chips.includes(e.target.value)) {
      return setValidationError("No puedes agregar el mismo valor nuevamente");
    }

    if (!esEmailValido(text)) {
      return setValidationError("Por favor, ingrese un correo válido.");
    }

    // adding the input value to chips array
    setChips((prevState) => [...prevState, e.target.value]);
    // clearing the input box
    setText("");
    // clearing error message
    setValidationError("");
  }

  function esEmailValido(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  return (
    <div className="flex flex-col w-full min-w-[200px]">
      <div className="flex flex-row w-full bg-white border-[1px] border-[#b3b2b2] rounded-md px-2 pt-[3px] min-h-10 flex-wrap">
        <ul className="flex list-none flex-wrap min-h-[50px] max-h-[200px] overflow-y-auto">
          {chips.map((chip) => (
            <li key={chip} className="flex bg-[#cfe1ff] items-center px-2 h-8 mr-[5px] rounded-2xl">
              <span className="text-[#013380] text-[14px] font-normal">{chip}</span>
              <TiDelete onClick={() => removeChip(chip)} tabIndex="0" />
            </li>
          ))}
        </ul>
        <input
          type="text"
          id="tags"
          className="flex w-[155px] text-[14px] bg-white border-none outline-none h-8"
          placeholder="Ingrese un correo"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handlePressEnter}
          onBlur={handleBlurAction}
        />
      </div>
      {validationError && <p className="mt-[1rem] text-[#e01]">{validationError}</p>}
    </div>
  );
}

export default ChipInput;
